import { CircularProgress } from "@mui/material";
import {
  Box,
  Ellipsis,
  LayoutDashboard,
  Network,
  RefreshCcw,
  ShoppingBasket,
  ShoppingCart,
  Truck,
  User,
} from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { COLORS } from "../../assets/styles/global.js";
import BarChart from "../../components/CustomComponents/Charts/BarChart.tsx";
import PieChart from "../../components/CustomComponents/Charts/PieChart.tsx";
import CustomCircularProgress from "../../components/CustomComponents/Progress/CircularProgress.tsx";
import { AppContext } from "../../context/AppContext.js";
import { CommonComponent } from "../../service/CommonComponent.js";
import { loggedInUser } from "../../service/get-user.js";

type Props = {};

const req = new CommonComponent();

const NewAdminDashboard = (props: Props) => {
  const { setSnackAlert } = useContext(AppContext);
  const [cards, setCards] = useState<any>([]);
  const [chartData, setChartData] = useState<any>({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
        borderColor: "black",
        borderWidth: 0.2,
        fill: false,
        pointRadius: 0,
        lineTension: 0.4,
      },
    ],
  });

  const refreshChartData = () => {
    setChartData({
      labels: [],
      datasets: [
        {
          label: "",
          data: [],
          backgroundColor: [],
          borderColor: "black",
          borderWidth: 0.2,
          fill: false,
          pointRadius: 0,
          lineTension: 0.4,
        },
      ],
    });

    setTimeout(() => {
      fetchData();
    }, 500);
  };

  useEffect(() => {
    let sub = true;
    if (sub) {
      fetchData();
    }
    return () => {
      sub = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    try {
      const { returnData, returnCode } = await req.sendRequestToServer(
        "DashboardManagement",
        "getDashboardAdminStats",
        JSON.stringify({
          auth: loggedInUser(),
        })
      );

      if (returnCode === 200 && returnData) {
        setCards(returnData);

        const labels = [
          "Total Orders",
          "Total Mapping Requests",
          "Total Products",
          "Total Packages",
          "Total SchoolPay Packages",
          "Vendors",
          "Admin Users",
          "Other Users",
          "Product Packages",
        ];
        const colors = [
          "#42A5F5",
          "#FFA726",
          "#fc0421",
          "#66BB6A",
          "#2676ff",
          "#8e44ad",
          "#e67e22",
          "#2ecc71",
          "#ff5733", // New color for package
        ];

        setChartData({
          labels,
          datasets: [
            {
              data: returnData.map((item: any) => Object.values(item)[0]),
              backgroundColor: colors,
              borderColor: COLORS.secondary,
              borderWidth: 0.2,
              fill: false,
              pointRadius: 0,
              lineTension: 0.4,
              label: "Dashboard Stats",
            },
          ],
        });
      } else {
        throw new Error("Invalid response code or empty data");
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setSnackAlert({
        open: true,
        message: "Failed to fetch data. Please try again later.",
        severity: "error",
      });
    }
  };

  const getCardHeading = (card: any) => {
    if (!card) return "-";
    return Object.keys(card)
      .map((key) => key.replace(/_/g, " ").toUpperCase()) // Replace all underscores
      .join(" ");
  };

  const getCardIcons = (card: any) => {
    if (!card) return "-";
    const key = Object.keys(card)[0];
    const iconProps = { color: COLORS.primary };
    switch (key) {
      case "total_orders":
        return <Truck {...iconProps} />;
      case "total_mapping_requests":
        return <Network {...iconProps} />;
      case "total_products":
        return <ShoppingCart {...iconProps} />;
      case "total_packages":
        return <ShoppingBasket {...iconProps} />;
      case "vendors":
        return <User {...iconProps} />;
      case "admin_users":
        return <User {...iconProps} />;
      case "other_users":
        return <User {...iconProps} />;
      case "product_package":
        return <Box {...iconProps} />;
      default:
        return <Ellipsis {...iconProps} />;
    }
  };

  const getCardStats = (card: any) => {
    if (!card) return "-";
    return Object.values(card)[0];
  };

  return (
    <div className="my-16 min-h-screen w-full mx-auto overflow-x-hidden">
      <div className="w-full flex flex-col space-y-5 px-6">
        <div className="w-full flex items-center">
          <LayoutDashboard size={32} color="#ffa200" />
          <h3 className="text-3xl text-[--secondary_color] font-semibold ml-2">
            Dashboard
          </h3>
        </div>
        <div className="flex flex-col md:flex-row md:gap-3 sm:gap-5 w-full justify-between">
          {cards.length > 0 ? (
            <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols- sm:grid-cols-1 md:gap-2 gap-4 w-full md:items-center md:justify-center">
              {cards.map((card: any, index: number) => (
                <div
                  key={index}
                  className="w-full h-auto border rounded-2xl shadow-sm hover:shadow-none cursor-pointer bg-white px-2 flex flex-col justify-evenly"
                >
                  <div className="flex justify-between px-2 py-2 items-center">
                    <div>{getCardIcons(card)}</div>
                    <Ellipsis />
                  </div>
                  <div className="flex justify-between px-2 items-center">
                    <h3 className="font-bold text-2xl text-[--secondary_color]">
                      {getCardStats(card)}
                    </h3>
                    <CustomCircularProgress
                      percentage={25}
                      size={30}
                      strokeWidth={5}
                      color={COLORS.primary}
                    />
                  </div>
                  <h3 className="px-2 py-2 font-light text-[--secondary_color] text-xs">
                    {getCardHeading(card)}
                  </h3>
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full flex items-center justify-center text-2xl font-bold text-[--secondary_color]">
              <CircularProgress color="inherit" />
            </div>
          )}
          <div className="bg-white shadow-sm rounded-xl flex flex-col items-center justify-center text-2xl font-bold text-[--secondary_color] sm:w-full md:w-1/3 p-4 sm:p-3 h-auto">
            <div className="flex space-x-4 items-center">
              <h3 className="mb-2">Card Data</h3>
              <RefreshCcw
                size={18}
                color={COLORS.primary}
                onClick={refreshChartData}
                className="cursor-pointer"
              />
            </div>
            <PieChart title="Data" chartData={chartData} />
          </div>
        </div>
        <BarChart title="Data" chartData={chartData} />
      </div>
    </div>
  );
};

export default NewAdminDashboard;
