import { AnimatePresence, motion } from "framer-motion";
import {
  AlertTriangle,
  Bell,
  CreditCard,
  Info,
  Package,
  ShoppingCart,
  UserPlus,
  X,
    RefreshCcw
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { Button } from "../components/CustomComponents/Buttons/Button.tsx";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../components/CustomComponents/Cards/Card.tsx";
import { Badge } from "../components/CustomComponents/Layouts/Badge.tsx";
import { CommonComponent } from "../service/CommonComponent";
import { getCookie } from "../service/get-user";

type AlertType =
  | "order"
  | "payment"
  | "inventory"
  | "user"
  | "system"
  | "STOCK";
type AlertPriority = "low" | "medium" | "high";

interface Alert {
  id: string;
  alertType: AlertType;
  priority: AlertPriority;
  created_on: Date;
  status: string;
}

const alertTypeConfig: Record<
  string,
  { icon: any; color: string; bgColor: string }
> = {
  order: {
    icon: ShoppingCart,
    color: "text-blue-500",
    bgColor: "bg-blue-50",
  },
  payment: {
    icon: CreditCard,
    color: "text-green-500",
    bgColor: "bg-green-50",
  },
  inventory: {
    icon: Package,
    color: "text-orange-500",
    bgColor: "bg-orange-50",
  },
  user: {
    icon: UserPlus,
    color: "text-purple-500",
    bgColor: "bg-purple-50",
  },
  system: {
    icon: AlertTriangle,
    color: "text-red-500",
    bgColor: "bg-red-50",
  },
  STOCK: {
    icon: Package, // Change if needed
    color: "text-yellow-500",
    bgColor: "bg-yellow-50",
  },
};

const req = new CommonComponent();

export default function AlertCenter() {
  const [isOpen, setIsOpen] = useState(false);
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  // const { toast } = useToast();

  // const { isConnected, messages, sendMessage, subscribe } = useWebSockets();

  // useEffect(() => {
  //   subscribe("order_updates");
  //   subscribe("promotions");
  // }, [subscribe]);

  useEffect(() => {
    let sub = true;

    if (sub) {
      const fetchAlerts = async () => {
        try {
          const { returnCode, returnData } = await req.sendRequestToServer(
            "AlertManagement",
            "fetchAllAlertsOnSpecifiedUser",
            JSON.stringify({
              auth: getCookie("loggedInUser"),
            })
          );

          if (returnCode) {
            console.log("Active Alerts:", returnData);
            setAlerts(returnData.filter((alert) => alert.status === "ACTIVE"));
            setUnreadCount(
              returnData.filter((alert) => alert.status === "ACTIVE").length
            );
          }
        } catch (e) {
          console.log(e.message);
        }
      };

      fetchAlerts();
    }
    return () => {
      sub = false;
    };
  }, []);

  const handleRefresh = async () => {
    try {
      const res = await req.sendRequestToServer(
          "AlertManagement",
          "fetchAllAlertsOnSpecifiedUser",
          JSON.stringify({
            auth: getCookie("loggedInUser"),
          })
      );
      const { returnCode, returnData } =res

      if (returnCode) {
        console.log("Active Alerts:", res);
        setAlerts(returnData.filter((alert) => alert.status === "ACTIVE"));
        setUnreadCount(
            returnData.filter((alert) => alert.status === "ACTIVE").length
        );
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  // const handleNewAlert = (alert: Alert) => {
  //   console.log("New Alert Received:", alert);
  //   setAlerts((prev) => [alert, ...prev]);

  //   // Show toast for high-priority alerts
  //   if (alert.priority === "high") {
  //     toast({
  //       title: "New High Priority Alert",
  //       description: "Check your notifications.",
  //       variant: alert.alertType === "STOCK" ? "destructive" : "default",
  //     });
  //   }
  // };

  const markAsRead = (id: string) => {
    setAlerts((prev) =>
      prev.map((alert) => (alert.id === id ? { ...alert, read: true } : alert))
    );
  };

  const removeAlert = (id: string) => {
    setAlerts((prev) => prev.filter((alert) => alert.id !== id));
  };

  const markAllAsRead = () => {
    setAlerts((prev) => prev.map((alert) => ({ ...alert, read: true })));
  };

  return (
    <div className="relative">
      <Button
        variant="ghost"
        size="icon"
        className="relative"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Bell className="h-5 w-5" />
        {unreadCount > 0 && (
          <Badge
            variant="destructive"
            className="absolute -top-1 -right-1 h-5 w-5 flex items-center justify-center p-0"
          >
            {unreadCount}
          </Badge>
        )}
      </Button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            transition={{ duration: 0.2 }}
            className="absolute right-0 mt-2 w-96 z-50"
          >
            <Card className="bg-white">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle>Notifications</CardTitle>
                {unreadCount > 0 && (
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={markAllAsRead}
                    className="text-xs"
                  >
                    Mark all as read
                  </Button>
                )}
              </CardHeader>
              <CardContent className="max-h-[70vh] overflow-auto">
                {alerts.length === 0 ? (
                  <div className="flex flex-col items-center justify-center py-8 text-muted-foreground">
                    <Info className="h-12 w-12 mb-2" />
                    <p>No notifications yet</p>
                    <div role={'button'} onClick={handleRefresh} className={'hover:cursor-pointer w-10 h-8 bg-gray-200 flex items-center justify-center rounded-lg focus:opacity-[.8]'}>
                      <div>
                      <RefreshCcw />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="space-y-4">
                    {alerts.map((alert: Alert, index) => {
                      console.log("Rendering Alert:", alert);
                      const alertConfig = alertTypeConfig[alert.alertType] || {
                        icon: Info,
                        color: "text-gray-500",
                        bgColor: "bg-gray-50",
                      };
                      const AlertIcon = alertConfig.icon;

                      return (
                        <motion.div
                          key={index}
                          layout
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className={`relative p-4 rounded-lg transition-colors ${alertConfig.bgColor}`}
                        >
                          <div className="flex gap-4">
                            <div className={`mt-1 ${alertConfig.color}`}>
                              <AlertIcon className="h-5 w-5" />
                            </div>
                            <div className="flex-1">
                              <div className="flex items-start justify-between">
                                <div>
                                  <p className="font-medium text-sm">{alert.title}</p>
                                  <p className="text-sm text-muted-foreground">
                                    {alert.message}
                                  </p>
                                </div>
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="h-6 w-6"
                                  onClick={() => removeAlert(alert.id)}
                                >
                                  <X className="h-4 w-4" />
                                </Button>
                              </div>
                              <div className="flex items-center gap-2 mt-2">
                                <p className="text-xs text-muted-foreground">
                                  {new Date(
                                    alert.created_on
                                  ).toLocaleTimeString()}
                                </p>
                                <Button
                                  variant="ghost"
                                  size="sm"
                                  className="h-6 text-xs"
                                  onClick={() => markAsRead(alert.id)}
                                >
                                  Mark as read
                                </Button>
                              </div>
                            </div>
                          </div>
                        </motion.div>
                      );
                    })}
                  </div>
                )}
              </CardContent>
            </Card>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
