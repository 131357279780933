import React, { useState, useEffect, useMemo } from "react";
import {
  Search,
  School,
  Building,
  CheckCircle,
  AlertCircle,
  ArrowRight,
  X,
} from "lucide-react";
import { CommonComponent } from "../../service";
import { getCookie } from "../../service/get-user";

const req = new CommonComponent();
const auth = getCookie("loggedInUser");

function AdminMappingManagement() {
  const [step, setStep] = useState(1);
  const [vendorCode, setVendorCode] = useState("");
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [Schools, setSchoolData] = React.useState<any>([]);

  const [vendorSearchQuery, setVendorSearchQuery] = useState("");
  const [vendorMessage, setVendorMessage] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSchool, setSelectedSchool] = useState<School | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState("");
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);

  useEffect(() => {
    const vendor = vendors.find((v) => v.vendor_code === vendorCode);
    setSelectedVendor(vendor || null);
    if (vendor) {
      setError("");
      setVendorMessage(`Vendor "${vendor.user_name}" selected successfully!`);
      // Clear message after 3 seconds
      setTimeout(() => {
        setVendorMessage("");
      }, 3000);
    } else if (vendorCode) {
      setVendorMessage("");
    }
  }, [vendorCode, vendors]);

  useEffect(() => {
    getAllActiveVendors();
    getAllEnrolledSchools();
  }, []);

  // Filtered vendors based on search query
  const filteredVendors = useMemo(
    () =>
      vendors.filter((vendor) =>
        vendor.vendor_code
          .toLowerCase()
          .includes(vendorSearchQuery.toLowerCase())
      ),
    [vendorSearchQuery, vendors]
  );

  // Filtered schools based on search query
  const filteredSchools = Schools.filter(
    (school) =>
      school.school_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      school.physical_addr.toLowerCase().includes(searchQuery.toLowerCase()) ||
      school.school_type.toLowerCase().includes(searchQuery.toLowerCase()) ||
      school.school_code.toString().includes(searchQuery.toLowerCase())
  );

  // Validate vendor code
  const isValidVendorCode = () => {
    return vendors.some((vendor) => vendor.vendor_code === vendorCode);
  };

  // Get vendor name by code
  const getVendorName = () => {
    const vendor = vendors.find((v) => v.vendor_code === vendorCode);
    return vendor ? vendor.user_name : "Unknown Vendor";
  };

  // Handle next step
  const handleNextStep = () => {
    if (step === 1) {
      if (isValidVendorCode()) {
        setError("");
        setStep(2);
      } else {
        setError("Please enter a valid vendor code");
      }
    } else if (step === 2) {
      if (selectedSchool) {
        setError("");
        setStep(3);
      } else {
        setError("Please select a school");
      }
    } else if (step === 3) {
      handleSubmit();
    }
  };

  // Handle school selection
  const handleSelectSchool = (school: School) => {
    setSelectedSchool(school);
    setSearchQuery(school.school_name);
  };

  // Clear vendor selection
  const clearVendorSelection = () => {
    setVendorCode("");
    setSelectedVendor(null);
    setVendorMessage("");
  };

  console.log("Submit", selectedSchool, "School: ", selectedVendor);

  const getAllEnrolledSchools = async () => {
    console.log(true);

    const postObject = {
      search: {},
    };
    try {
      const response: Promise<any> = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "getEnrolledSchools",
        JSON.stringify(postObject)
      );

      const { returnCode, returnData, returnMessage }: any = response;

      if (returnCode === 200) {
        setSchoolData(returnData);
      } else {
        setError(returnMessage);
      }
    } catch (e) {
      console.log(false);
    } finally {
      console.log(false);
    }
  };

  const getAllActiveVendors = async () => {
    const postObject = {
      auth,
    };

    try {
      const response: Promise<any> = await req.sendRequestToServer(
        "ClientManagement",
        "getClientWithoutSession",
        JSON.stringify(postObject)
      );

      const { returnCode, returnData }: any = response;

      if (returnCode === 200) {
        setVendors(returnData.rows);
      } else {
      }
    } catch (e) {
      console.log(false);
    } finally {
      console.log(false);
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    setIsSubmitting(true);
    let postObject = {
      auth,
      vendorId: selectedVendor?.id,
      schoolIdArray: [selectedSchool?.id],
    };

    try {
      const response = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "mapVendorToSchoolWithoutSession",
        JSON.stringify(postObject)
      );

      const { returnCode, returnMessage } = response;
      if (returnCode === 200) {
        setIsSuccess(true);
        setVendorMessage("Vendor mapping saved successfully!");

        // Clear message after 3 seconds and reset form
        setTimeout(() => {
          setStep(1);
          setVendorCode("");
          setSearchQuery("");
          setSelectedSchool(null);
          setSelectedVendor(null);
          setIsSuccess(false);
          setVendorMessage("");
        }, 3000);
      } else {
        setError(returnMessage);
        setIsSubmitting(false);

        setTimeout(() => {
          setStep(1);
          setVendorCode("");
          setSearchQuery("");
          setSelectedSchool(null);
          setSelectedVendor(null);
          setIsSuccess(false);
          setError("");
        }, 3000);
      }
    } catch (error) {
      setError("Error: " + error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Progress percentage
  const progressPercentage = step === 1 ? 33 : step === 2 ? 66 : 100;

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex items-center justify-center p-4">
      <div className="w-full max-w-6xl bg-white rounded-xl shadow-xl overflow-hidden">
        {/* Header */}
        <div className="bg-[--primary-color] p-6 text-white">
          <h1 className="text-2xl font-bold">Vendor to School Mapping</h1>
          <p className="text-indigo-100 mt-1">
            Connect vendors with educational institutions
          </p>

          {/* Progress bar */}
          <div className="mt-6 relative">
            <div className="h-2 bg-gray-300 rounded-full">
              <div
                className="h-2 bg-white rounded-full transition-all duration-500 ease-in-out"
                style={{ width: `${progressPercentage}%` }}
              ></div>
            </div>
            <div className="flex justify-between mt-2 text-sm">
              <div
                className={`${
                  step >= 1 ? "text-white font-extrabold" : "text-gray-300"
                }`}
              >
                Vendor
              </div>
              <div
                className={`${
                  step >= 2 ? "text-white font-extrabold" : "text-gray-300"
                }`}
              >
                School
              </div>
              <div
                className={`${
                  step >= 3 ? "text-white font-extrabold" : "text-gray-300"
                }`}
              >
                Confirm
              </div>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="p-8">
          {/* Step 1: Vendor Selection */}
          {step === 1 && (
            <div className="space-y-6">
              <div className="flex items-center space-x-3">
                <div className="bg-indigo-100 p-3 rounded-full">
                  <Building className="h-6 w-6 text-[--primary-color]" />
                </div>
                <h2 className="text-xl font-semibold text-gray-800">
                  Select Vendor
                </h2>
              </div>

              <p className="text-gray-600">
                Enter the vendor code to begin the mapping process.
              </p>

              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="vendorCode"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Vendor Code
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="vendorCode"
                      placeholder="e.g. PVD-00000"
                      className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition"
                      value={vendorCode}
                      onChange={(e) => {
                        setVendorCode(e.target.value);
                        setVendorSearchQuery(e.target.value); // Ensure search is updated
                      }}
                    />

                    {vendorCode && (
                      <button
                        onClick={clearVendorSelection}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                      >
                        <X className="h-5 w-5" />
                      </button>
                    )}
                  </div>
                  {error && (
                    <p className="mt-2 text-sm text-red-600">{error}</p>
                  )}
                  {vendorMessage && (
                    <p className="mt-2 text-sm text-green-600">
                      {vendorMessage}
                    </p>
                  )}
                </div>

                {selectedVendor && (
                  <div className="bg-green-50 p-4 rounded-lg border border-green-100">
                    <h3 className="font-medium text-green-800 mb-2">
                      Selected Vendor:
                    </h3>
                    <div className="flex items-start">
                      <div className="bg-white p-2 rounded-full shadow-sm">
                        <Building className="h-5 w-5 text-green-600" />
                      </div>
                      <div className="ml-3 flex-1">
                        <p className="text-green-800 font-medium">
                          {selectedVendor.user_name}
                        </p>
                        <div className="grid grid-cols-2 gap-2 mt-2 text-sm text-green-700">
                          <div>
                            <span className="text-green-600 font-medium">
                              Email:
                            </span>{" "}
                            {selectedVendor.email_address}
                          </div>
                          <div>
                            <span className="text-green-600 font-medium">
                              Phone:
                            </span>{" "}
                            {selectedVendor.phone_no}
                          </div>
                          <div>
                            <span className="text-green-600 font-medium">
                              Status:
                            </span>{" "}
                            {selectedVendor.status}
                          </div>
                          <div>
                            <span className="text-green-600 font-medium">
                              Created:
                            </span>{" "}
                            {selectedVendor.created_on}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="bg-indigo-50 p-4 rounded-lg">
                  <h3 className="font-medium text-indigo-800 mb-2">
                    Available Vendors:
                  </h3>

                  <div className="max-h-60 overflow-y-auto">
                    <ul className="space-y-1 text-sm text-gray-700">
                      {filteredVendors.length > 0 ? (
                        filteredVendors.map((vendor) => (
                          <li
                            key={vendor.id}
                            className={`flex items-center space-x-2 p-2 rounded cursor-pointer hover:bg-indigo-100 transition ${
                              vendorCode === vendor.vendor_code
                                ? "bg-indigo-100 border border-indigo-200"
                                : ""
                            }`}
                            onClick={() => {
                              setSelectedVendor(vendor);
                              setVendorCode(vendor.vendor_code);
                            }}
                          >
                            <span className="font-mono bg-white px-2 py-1 rounded border border-indigo-100">
                              {vendor.vendor_code}
                            </span>
                            <span>-</span>
                            <span>{vendor.user_name}</span>

                            <span>-</span>
                            <span>{vendor.email_address}</span>

                            {vendorCode === vendor.vendor_code && (
                              <CheckCircle className="h-4 w-4 text-[--primary-color] ml-auto" />
                            )}
                          </li>
                        ))
                      ) : (
                        <li className="p-2 text-center text-gray-500">
                          No vendors available
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Step 2: School Selection */}
          {step === 2 && (
            <div className="space-y-6">
              <div className="flex items-center space-x-3">
                <div className="bg-indigo-100 p-3 rounded-full">
                  <School className="h-6 w-6 text-[--primary-color]" />
                </div>
                <h2 className="text-xl font-semibold text-gray-800">
                  Select School
                </h2>
              </div>

              <p className="text-gray-600">
                Search for and select the school you want to map to vendor:{" "}
                <span className="font-medium">{getVendorName()}</span> (
                {vendorCode})
              </p>

              <div className="space-y-4">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Search className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="text"
                    placeholder="Search by school name, location, or type..."
                    className="w-full pl-10 pr-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>

                {searchQuery && (
                  <div className="bg-white border border-gray-200 rounded-lg shadow-lg max-h-64 overflow-y-auto">
                    {filteredSchools.length > 0 ? (
                      <ul className="divide-y divide-gray-100">
                        {filteredSchools.map((school) => (
                          <li
                            key={school.id}
                            className={`p-4 hover:bg-indigo-50 cursor-pointer transition ${
                              selectedSchool?.id === school.id
                                ? "bg-indigo-50"
                                : ""
                            }`}
                            onClick={() => handleSelectSchool(school)}
                          >
                            <div className="flex items-center justify-between">
                              <div>
                                <div className="flex items-center">
                                  <h4 className="font-medium text-gray-900">
                                    {school.school_name}
                                  </h4>
                                  <span
                                    className={`ml-2 px-2 py-0.5 text-xs rounded-full ${
                                      school.status === "Active"
                                        ? "bg-green-100 text-green-800"
                                        : "bg-red-100 text-red-800"
                                    }`}
                                  >
                                    {school.status}
                                  </span>
                                </div>
                                <p className="text-sm text-gray-600">
                                  {school.school_type}
                                </p>
                                <p className="text-xs text-gray-500">
                                  {school.physical_addr} • Code:{" "}
                                  {school.school_code}
                                </p>
                              </div>
                              {selectedSchool?.id === school.id && (
                                <CheckCircle className="h-5 w-5 text-[--primary-color]" />
                              )}
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="p-4 text-center text-gray-500">
                        No schools found matching your search criteria
                      </div>
                    )}
                  </div>
                )}

                {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
              </div>
            </div>
          )}

          {/* Step 3: Confirmation */}
          {step === 3 && (
            <div className="space-y-6">
              <div className="flex items-center space-x-3">
                <div className="bg-indigo-100 p-3 rounded-full">
                  <CheckCircle className="h-6 w-6 text-[--primary-color]" />
                </div>
                <h2 className="text-xl font-semibold text-gray-800">
                  Confirm Mapping
                </h2>
              </div>

              <p className="text-gray-600">
                Please review the vendor-school mapping details before saving.
              </p>

              <div className="bg-indigo-50 p-6 rounded-lg space-y-4">
                <div className="flex items-start">
                  <div className="bg-white p-3 rounded-full shadow-sm">
                    <Building className="h-6 w-6 text-[--primary-color]" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-sm font-medium text-gray-500">
                      Vendor
                    </h3>
                    <p className="text-lg font-semibold text-gray-900">
                      {selectedVendor?.user_name}
                    </p>
                    <div className="grid grid-cols-2 gap-x-4 gap-y-1 mt-1">
                      <p className="text-sm text-gray-600">
                        Code: {vendorCode}
                      </p>
                      <p className="text-sm text-gray-600">
                        Status: {selectedVendor?.status}
                      </p>
                      <p className="text-sm text-gray-600">
                        Email: {selectedVendor?.email_address}
                      </p>
                      <p className="text-sm text-gray-600">
                        Phone: {selectedVendor?.phone_no}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex justify-center py-2">
                  <ArrowRight className="h-6 w-6 text-indigo-400" />
                </div>

                <div className="flex items-start">
                  <div className="bg-white p-3 rounded-full shadow-sm">
                    <School className="h-6 w-6 text-[--primary-color]" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-sm font-medium text-gray-500">
                      School
                    </h3>
                    <div className="flex items-center">
                      <p className="text-lg font-semibold text-gray-900">
                        {selectedSchool?.school_name}
                      </p>
                      <span
                        className={`ml-2 px-2 py-0.5 text-xs rounded-full ${
                          selectedSchool?.status === "Active"
                            ? "bg-green-100 text-green-800"
                            : "bg-red-100 text-red-800"
                        }`}
                      >
                        {selectedSchool?.status}
                      </span>
                    </div>
                    <div className="grid grid-cols-2 gap-x-4 gap-y-1 mt-1">
                      <p className="text-sm text-gray-600">
                        Type: {selectedSchool?.school_type}
                      </p>
                      <p className="text-sm text-gray-600">
                        Code: {selectedSchool?.school_code}
                      </p>
                      <p className="text-sm text-gray-600">
                        Location: {selectedSchool?.physical_addr}
                      </p>
                      <p className="text-sm text-gray-600">
                        Enrolled: {selectedSchool?.enrolled_on}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <AlertCircle className="h-5 w-5 text-yellow-400" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      This action will create a new mapping between the vendor
                      and school. Existing mappings for this vendor will remain
                      unchanged.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Success message */}
          {isSuccess && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-90 z-10">
              <div className="text-center p-6 bg-white rounded-lg shadow-lg">
                <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100">
                  <CheckCircle className="h-8 w-8 text-green-600" />
                </div>
                <h3 className="mt-4 text-lg font-medium text-gray-900">
                  Mapping Successful!
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  The vendor has been successfully mapped to the school.
                </p>
              </div>
            </div>
          )}
          {error && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-90 z-10">
              <div className="text-center p-6 bg-white rounded-lg shadow-lg">
                <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-red-100">
                  <CheckCircle className="h-8 w-8 text-red-600" />
                </div>
                <h3 className="mt-4 text-lg font-medium text-gray-900">
                  Mapping Faied!
                </h3>
                <p className="mt-2 text-sm text-red-500">{error}</p>
              </div>
            </div>
          )}
        </div>

        {/* Footer */}
        <div className="px-8 py-6 bg-gray-50 border-t border-gray-200 flex justify-between">
          <button
            type="button"
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => step > 1 && setStep(step - 1)}
            disabled={step === 1 || isSubmitting}
          >
            {step > 1 ? "Back" : "Cancel"}
          </button>

          <button
            type="button"
            className={`px-6 py-2 rounded-md text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
              isSubmitting
                ? "bg-orange-300 cursor-not-allowed"
                : "bg-[--primary-color] hover:bg-[--secondary_color]"
            }`}
            onClick={handleNextStep}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <span className="flex items-center">
                <svg
                  className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Processing...
              </span>
            ) : step === 3 ? (
              "Save Mapping"
            ) : (
              "Continue"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

// Type definitions
interface School {
  school_code: number;
  physical_addr: string;
  enrolled_on: string;
  school_type: string;
  school_name: string;
  id: number;
  status: string;
}

interface Vendor {
  phone_no: string;
  email_address: string;
  user_type: string;
  created_on: string;
  user_name: string;
  status: string;
  id: number;
  vendor_code: string;
}

export default AdminMappingManagement;
