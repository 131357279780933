import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { X } from "lucide-react";
import React from "react";
import { COLORS } from "../../../assets/styles/global";
import ButtonBase from "../Buttons/ButtonBase.tsx";

interface CustomDialogProps {
  open: boolean;
  onClose: () => void;
  type: "alert" | "success" | "info" | "confirm"; // Different dialog types
  title: string;
  content: any;
  onConfirm?: () => void; // Optional confirm action for confirm dialog
}

const FormDialog = ({
  open,
  onClose,
  type,
  title,
  content,
  onConfirm,
}: CustomDialogProps) => {
  //   const getDialogIcon = () => {
  //     switch (type) {
  //       case "alert":
  //         return <WarningIcon color="error" />;
  //       case "success":
  //         return <CheckCircleIcon color="success" />;
  //       case "info":
  //         return <Info color={COLORS.primary} />;
  //       default:
  //         return null;
  //     }
  //   };

  return (
    <Dialog open={open} onClose={onClose}>
      <div className="bg-[--primary-color-light border-b">
        <DialogTitle>
          <div className="flex items-center w-full justify-between">
            <Typography variant="h6" component="span" style={{ marginLeft: 8 }}>
              {title}
            </Typography>
            {/* {getDialogIcon()} Conditionally rendered icon */}
            <X
              color={COLORS.black}
              style={{ cursor: "pointer" }}
              onClick={onClose}
            />
          </div>
        </DialogTitle>
      </div>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {type === "confirm" ? (
          <div className="flex justify-between items-end">
            <div className="w-[50%]">
              <ButtonBase
                mode="outlined"
                theme="secondary"
                title="Cancel"
                onClick={onClose}
                className="text-black"
              />
            </div>
            <div className="w-[50%]">
              {" "}
              <ButtonBase
                title="Confirm"
                theme="primary"
                onClick={() => {
                  if (onConfirm) onConfirm();
                }}
              />
            </div>
          </div>
        ) : (
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
