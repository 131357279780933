import React from "react";

type Props = {
  onClick?: (item?: any) => void;
  title: string;
  className?: string;
  mode?: "filled" | "outlined" | "skeleton";
  theme?: "primary" | "secondary";
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
};

const ButtonBase = ({
  onClick,
  title,
  className = "",
  type = "button",
  mode = "skeleton",
  theme = "primary",
  disabled = false,
}: Props) => {
  const themeClasses =
    theme === "primary"
      ? "bg-[--primary-color] text-[--secondary_color] hover:bg-[--secondary_color] hover:text-white"
      : "bg-[--secondary_color] text-[--primary-color] hover:bg-[--primary-color]";

  const modeClasses =
    mode === "filled"
      ? ""
      : mode === "outlined"
      ? "bg-transparent border-2 border-[--primary-color] hover:opacity-70 text-black hover:bg-[--primary-color]"
      : "";

  const disabledClasses = disabled
    ? "cursor-not-allowed opacity-50"
    : `${themeClasses} ${modeClasses}`;

  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={`w-full py-2 px-3 rounded-sm transition duration-300 ease-out focus:border-slate-900 ${className} ${disabledClasses}`}
    >
      {title}
    </button>
  );
};

export default ButtonBase;
