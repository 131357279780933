import { Button, Tooltip } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { Skeleton } from "@mui/material";
import { ArrowLeft, Dot, X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { COLORS } from "../../../assets/styles/global.js";
import { skeletondata } from "../../../data/genericdata.ts";
import CustomAlert from "../Alerts/CustomAlert.tsx";
import SnackBarAlert from "../Alerts/SnackBarAlert.tsx";

type MappingProps = {
  data: any[];
  columns: { header: string; accessor: string }[];
  rout: { id: string; name: string };
  submit?: { label: string; onClick: (e: any) => void } | any;
  title: string;
  description: string;
  loading: boolean;
  listData: any[];
  onSelectItem: (item: ListDataTypes) => void;
  selectedItem: ListDataTypes[] | any[];
  onDeselectItem: () => void;
  onSelectTableItem?: (item: any) => void;
  multiSelect?: (item: any) => void | any;
  errorRowId?: number | string;
};

export type ListDataTypes = {
  title: string;
  description: string;
};

const CustomVendorMaping = ({
  data,
  columns,
  rout,
  submit,
  title,
  description,
  loading = true,
  listData,
  onSelectItem,
  selectedItem,
  onDeselectItem,
  onSelectTableItem,
  multiSelect,
  errorRowId,
}: MappingProps) => {
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [success, setSuccess] = useState({
    open: false,
    message: "",
  });
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isItemSelected, setIsItemSelected] = useState(false);
  useEffect(() => {
    if (selectedProducts.length === data.length) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  }, [selectedProducts, data]);

  const handleSelectAllChange = () => {
    if (selectAllChecked) {
      // Deselect all items
      setSelectedProducts([]);
      if (typeof multiSelect === "function") {
        multiSelect([]); // Pass an empty array to deselect all
      }
    } else {
      // Select all items
      setSelectedProducts(data);
      if (typeof multiSelect === "function") {
        multiSelect(data.map((item) => item.id)); // Pass all item IDs
      }
    }
    setSelectAllChecked(!selectAllChecked);
  };

  const handlePreviewClick = (e: Event) => {
    e.preventDefault();
    setIsOverlayVisible(true);
  };

  const handleOverlayClose = () => {
    setIsOverlayVisible(false);
  };

  const handleRemoveProduct = (productId: string) => {
    setSelectedProducts((prevSelected) => {
      const updatedSelectedProducts = prevSelected.filter(
        (p) => p.id !== productId
      );

      // Call multiSelect with the updated list of IDs if the function exists
      if (typeof multiSelect === "function") {
        multiSelect(updatedSelectedProducts.map((p) => p.id)); // Pass the updated list of IDs
      }

      return updatedSelectedProducts;
    });

    // Close the overlay if the last product is removed
    if (selectedProducts.length === 1) {
      setIsOverlayVisible(false);
    }
  };

  const handleSelectItem = (item: ListDataTypes) => {
    onDeselectItem();

    setTimeout(() => {
      setIsItemSelected(true);

      onSelectItem(item);
    }, 500);
  };

  const handleDeselectItem = () => {
    setIsItemSelected(false);
    onDeselectItem();
  };

  const handleSelectTableItem = (row: any) => {
    setSelectedProducts((prevSelectedProducts) => {
      const updatedSelectedProducts = prevSelectedProducts.some(
        (p) => p.id === row.id
      )
        ? prevSelectedProducts.filter((p) => p.id !== row.id)
        : [...prevSelectedProducts, row];

      if (typeof multiSelect === "function") {
        multiSelect(updatedSelectedProducts.map((p) => p.id));
      }

      return updatedSelectedProducts;
    });

    if (typeof onSelectTableItem === "function") {
      onSelectTableItem(row);
    }
  };

  return (
    <div className="flex w-full space-x-6">
      {/* Left Section: Smaller Containers */}
      <div className="flex flex-col space-y-5 w-[25%] transition ease-in-out duration-300">
        <div className="bg-white p-6 rounded-lg shadow-md h-fit">
          <p className="text-xl font-bold text-gray-900">
            {title}: {rout.name}
          </p>
          <p className="text-gray-600 mt-4">{description}</p>
          {alert.open && (
            <div className="inpu_and_label_c">
              <CustomAlert
                message={alert.message}
                open={alert.open}
                onClose={() => setAlert({ ...alert, open: false })}
                severity={alert.severity}
              />
              {!isOverlayVisible && (
                <Button
                  onClick={(e: any) => handlePreviewClick(e)}
                  className="bg-[--secondary_color]"
                >
                  Review school
                </Button>
              )}
            </div>
          )}
        </div>

        <div className="flex flex-col items-start justify-start space-y-5 transition ease-in-out duration-300">
          {selectedItem.length > 0 && isItemSelected && (
            <div
              className="flex w-full shadow-none border rounded-md py-3 px-4 bg-white justify-evenly items-center"
              key={"1"}
            >
              <div className="w-[10%]">
                <Dot />
              </div>

              <div className="w-[85%]">
                <h3>{selectedItem[0].title || "N/A"}</h3>
              </div>

              <div
                title="Remove Item"
                role="button"
                className="w-[30] h-[30] p-1 rounded-[50%] cursor-pointer hover:bg-[--translucent-black]"
                onClick={handleDeselectItem}
              >
                <X />
              </div>
            </div>
          )}

          <div className="h-[60vh] overflow-scroll flex flex-col justify-between py-3 px-1 bg-white rounded-md shadow-md w-full">
            <div className="w-[110%] overflow-x-hidden">
              <div className="w-[80%] mx-auto border-b py-2 sticky top-0 bg-white">
                <h3 className="capitalize text-center">List of Items to map</h3>
              </div>
              {loading ? (
                <>
                  {skeletondata.map((_, index) => (
                    <div
                      className="flex items-center justify-evenly space-y-5 mt-2 hover:bg-[--school-pay-secondary-color-light]"
                      key={index}
                      role="button"
                    >
                      <div className="px-2 ">
                        <Skeleton
                          animation="wave"
                          variant="circular"
                          width={40}
                          height={40}
                        />
                      </div>
                      <div className="w-full flex flex-col pb-2  justify-center">
                        <Skeleton
                          animation="wave"
                          height={10}
                          width="60%"
                          style={{ marginBottom: 6 }}
                        />
                        <Skeleton
                          animation="wave"
                          height={10}
                          width="80%"
                          style={{ marginBottom: 6 }}
                        />
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {listData.map((item: ListDataTypes, index) => {
                    return (
                      <div
                        className={`flex items-center justify-evenly space-y-5 mt-2 hover:bg-[#e8f6f8]`}
                        key={index}
                        role="button"
                        onClick={() => handleSelectItem(item)}
                      >
                        <div className="px-2 ">
                          <Skeleton
                            animation="wave"
                            variant="circular"
                            width={40}
                            height={40}
                          />
                        </div>
                        <div
                          className="w-full flex flex-col pb-3  justify-center"
                          key={index.toString()}
                        >
                          <h3 className="font-semibold">{item.title}</h3>
                          <h3>{item.description}</h3>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Right Section: Larger Container */}
      <div className="w-[75%]">
        {isItemSelected ? (
          <form className="space-y-2">
            {success.open && (
              <SnackBarAlert
                open={success.open}
                severity="success"
                message={success.message}
                onClose={() =>
                  setSuccess({
                    open: false,
                    message: "",
                  })
                }
              />
            )}
            <div className="rounded-lg shadow-md max-h-[510px] overflow-auto">
              <table className="min-w-full">
                <thead className="bg-[--primary-color] sticky top-0">
                  <tr>
                    {!isOverlayVisible && (
                      <>
                        <th className="w-1/12 text-left py-1 px-4 font-medium text-gray-700">
                          <Checkbox
                            size="small"
                            checked={selectAllChecked}
                            onChange={handleSelectAllChange}
                            style={{ color: COLORS.white }}
                            title="SELECT ALL"
                          />
                        </th>
                        {columns.map((column, index) => (
                          <th
                            key={index}
                            className="text-left py-1 px-4 text-white lg:whitespace-nowrap text-xs font-bold"
                          >
                            {column.header}
                          </th>
                        ))}
                      </>
                    )}
                  </tr>
                </thead>

                <tbody className="text-gray-600 text-sm">
                  {!isOverlayVisible ? (
                    data.map((row, index) => {
                      const isSelected = selectedProducts.some(
                        (p) => p.id === row.id
                      );
                      const isError = row.id === errorRowId;

                      return (
                        <tr
                          key={row.id}
                          className={`capitalize text-black ${isSelected && "bg-[--school-pay-primary-color]"
                            } ${isError ? "text-red-500 font-bold" : ""} ${index % 2 === 0 ? "bg-white" : "bg-gray-200"
                            }`}
                        >
                          <td className="px-4 capitalize">
                            <Checkbox
                              size="small"
                              style={{ color: COLORS.primary }}
                              checked={isSelected}
                              onChange={() => handleSelectTableItem(row)}
                            />
                          </td>
                          {columns.map((column, index) => (
                            <td
                              key={index}
                              className={`text-black py-1 px-4 text-xs lg:whitespace-nowrap capitalize   ${isError ? "text-red-500 font-bold" : ""
                                }`}
                            >
                              {row[column.accessor]}
                            </td>
                          ))}
                        </tr>
                      );
                    })
                  ) : (
                    <>
                      <tr>
                        <td colSpan={columns.length + 1} className=" w-full">
                          <div className="flex">
                            <div className="bg-white  rounded-lg w-full">
                              <table className="min-w-full">
                                <thead>
                                    <tr className="bg-[--secondary_color]">
                                    {columns.map((column, index) => (
                                      <th
                                        key={index}
                                        className="text-left py-1 px-2 font-bold text-white"
                                      >
                                        {column.header}
                                      </th>
                                    ))}
                                    <th className="text-right py-1 px-2 font-bold text-white">
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {selectedProducts.map((product) => {
                                    const isError = product.id === errorRowId; // Check if this product is the error row
                                    return (
                                      <tr
                                        key={product.id}
                                        className={`w-full bg-slate-100 px-2 rounded-md ${isError ? "text-red-500" : ""
                                          }`} // Apply red text color if it's an error row
                                      >
                                        {columns.map((column, index) => (
                                          <td
                                            key={index}
                                            className={`py-1 px-1 ${isError
                                              ? "text-red-500"
                                              : "text-gray-700"
                                              }`} // Apply red text color to each cell if it's an error row
                                          >
                                            {product[column.accessor]}
                                          </td>
                                        ))}
                                        <td className="py-1 px-2 text-right rounded-md">
                                          <Tooltip title="Delete">
                                            <IconButton
                                              aria-label="delete"
                                              color="secondary"
                                              onClick={() =>
                                                handleRemoveProduct(product.id)
                                              }
                                            >
                                              <DeleteIcon fontSize="small" />
                                            </IconButton>
                                          </Tooltip>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <div className="flex justify-end px-2 py-4 bg-white">
                        <div
                          role="button"
                          onClick={() => handleOverlayClose()}
                          className="flex items-center justify-evenly px-3 py-1 rounded-sm hover:bg-[#43c7db2a] space-x-2"
                        >
                          <ArrowLeft size={15} className="text-[--secondary_color]" />
                          <h3 className="text-sm capitalize font-light hover:text-[--secondary_color] hover:font-semibold">
                            Return to table list
                          </h3>
                        </div>
                      </div>
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {selectedProducts.length > 0 && (
              <div className="flex justify-end space-x-4 mt-4 px-2">
                {!isOverlayVisible ? (
                  <button
                    onClick={(e: any) => handlePreviewClick(e)}
                    className="bg-[--primary-color] text-xs rounded-sm px-4 py-1 text-white shadow-md border border-white"
                  >
                    Preview
                  </button>
                ) : (
                  <button
                    onClick={submit?.onClick}
                    className="bg-[--secondary_color] text-xs rounded-sm px-4 py-1 text-white shadow-md border border-black"
                  >
                    {submit?.label ? submit.label : "Submit"}
                  </button>
                )}
              </div>
            )}
          </form>
        ) : (
          <div className="h-[27%] items-center flex justify-center bg-white w-full py-1 rounded-md shadow-md">
            <h3 className="text-2xl font-semibold text-center capitalize">
              Please Select an item in order to proceed
            </h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomVendorMaping;
