import {
  Building,
  Building2,
  Coins,
  Eye,
  Files,
  HandCoins,
  House,
  List,
  LogOut,
  PackageCheck,
  PartyPopper,
  Pointer,
  School,
  ShoppingCart,
  Signature,
  SlidersVertical,
  SquareUser,
  Star,
  Truck,
  Users,
} from "lucide-react";
import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AlertCenter from "../../alerts-and-notifications/AlertCenter.tsx";
import ProfileContainer from "../../components/CustomComponents/Profiles/ProfileContainer.tsx";
import { Toaster } from "../../components/CustomComponents/Toasts/Toaster.tsx";
import { ServerResponse } from "../../components/Shop/Customers/CustomerProfile.tsx";
import { AppContext } from "../../context/AppContext.js";
import { SignOut } from "../../redux/actions/loginStateAction.ts";
import { routings } from "../../routes/routings.ts";
import { CommonComponent } from "../../service/CommonComponent.js";
import { getCookie, logOut } from "../../service/get-user.js";
import SideBar, { SideBarItem } from "./SideBar.tsx";

const request = new CommonComponent();
const MainView = ({ children }: any) => {
  const [open, setOpen] = useState<string | null>(null);
  const [req, setreq] = useState<boolean>(false);
  const [topNav, setTopNav] = useState("");
  const [surName, setSurName] = useState("");
  const [otherName, setOtherName] = useState("");

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const permission = useRef<any[]>(null);

  const loggedInUser = getCookie("loggedInUser");
  const userRole = loggedInUser?.userRole;

  const { setModal, sideMenusLoaded, setSideMenusLoaded }: any =
    useContext(AppContext);

  useLayoutEffect(() => {
    // const loggedInUser = getCookie("loggedInUser");

    if (!loggedInUser) {
      logOut();
      navigation("/login");
      dispatch(SignOut());
    } else {
      const {
        user: { totalLoginCount, lastName, firstName },
      } = loggedInUser;

      setOtherName(lastName);
      setSurName(firstName);

      if (totalLoginCount === 1) {
        setTopNav("Welcome to Pangisa Mr. " + lastName);
      } else {
        setTopNav("Welcome back to Pangisa Mr. " + lastName);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllPermissionsOnUser = async () => {
    const req = new CommonComponent();

    try {
      const postObject = {
        auth: getCookie("loggedInUser"),
        userId: getCookie("loggedInUser").user.id,
      };

      const { returnData } = await request.sendRequestToServer(
        "AuthManagement",
        "getAllPermissionsOnSpecifiedUser",
        JSON.stringify(postObject)
      );

      if (returnData) {
        permission.current = returnData;

        setreq(true);
        setSideMenusLoaded(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useLayoutEffect(() => {
    let sub = true;

    if (
      sub &&
      loggedInUser &&
      !window.location.toString().includes("complete-registration")
      // sideMenusLoaded
    ) {
      getAllPermissionsOnUser();
    }

    return () => {
      sub = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser, sideMenusLoaded, window.location]);

  const checkRequiredPermission = (perm: string): boolean | undefined => {
    const userPermissions = permission.current?.map(
      (permissionObject: any) => permissionObject.permission
    );

    return userPermissions?.includes(perm);
  };

  const schoolpayShopping: any = {
    label: "Schoolpay Shopping",
    icon: <ShoppingCart />,
    items: [],
  };
  const liquateCash: any = {
    label: "Cash Liquidation",
    icon: <Coins />,
    items: [],
  };
  const userMenu: any = {
    label: "User Management",
    icon: <Users />,
    items: [],
  };
  const globalConfigMenu: any = {
    label: "Global Configurations",
    icon: <SlidersVertical />,
    items: [],
  };
  const farmersMenu: any = {
    label: "Vendors",
    icon: <HandCoins />,
    items: [],
  };
  const suppliersMenu: any = {
    label: "Users",
    icon: <Users />,
    items: [],
  };
  const cooperateEntities: any = {
    label: "Cooperate Entities",
    icon: <Building />,
    items: [],
  };
  const clientMenu: any = {
    label: "My Businesses",
    icon: <Building2 />,
    items: [],
  };
  const productsMenu: any = {
    label: "Product Management",
    icon: <ShoppingCart />,
    items: [],
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const orderMenu: any = {
    label: "Orders",
    icon: <Truck />,
    items: [],
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const productConfigurations = {
    label: "Product Configurations",
    icon: <PackageCheck />,
    items: [],
  };
  const productReviews: any = {
    label: "Customer Reviews",
    icon: <Star />,
    items: [],
  };
  const reportsMenu: any = {
    label: "Reports",
    icon: <Files />,
    items: [],
  };
  const deliveryAgentsMenu: any = {
    label: "Agents",
    icon: <SquareUser />,
    items: [],
  };
  const approvalsMenu: any = {
    label: "Pending Approvals",
    icon: <SquareUser />,
    items: [],
  };
  const commissionMenu: any = {
    label: "Commission Settings",
    icon: <Coins />,
    items: [],
  };

  const TermsAndConditionMenu: any = {
    label: "Terms & Conditions",
    icon: <Signature />,
    items: [],
  };

  const VendorSchoolMenu: any = {
    label: "SchoolPay Shopping",
    icon: <School />,
    items: [],
  };
  if (userRole === "Seed Admin" || userRole === "Pangisa Admin") {
    TermsAndConditionMenu.items.push({
      label: "Terms & Conditions",
      to: routings.terms_conditions,
    });
  }

  if (userRole === "Business Admin") {
    VendorSchoolMenu.items.push({
      label: "Mapping Management",
      to: routings.vendor_mapping_management,
    });
    VendorSchoolMenu.items.push({
      label: "Mapping Management",
      to: routings.vendor_mapping_requests,
    });
    VendorSchoolMenu.items.push({
      label: "Mapping Reports",
      to: routings.vendor_mapping_report,
    });
  }

  if (checkRequiredPermission("can_manage_delivery_agents")) {
    deliveryAgentsMenu.items.push({
      label: "Delivery Agents",
      to: "/delivery-agents",
    });
  }

  if (checkRequiredPermission("can_liquate_cash")) {
    liquateCash.items.push({
      label: "Withdraw Cash",
      to: "/withdraw-cash",
    });
  }
  if (checkRequiredPermission("can_manage_permissions")) {
    userMenu.items.push({ label: "User Roles", to: "/user-roles" });
  }

  // if (checkRequiredPermission("can_manage_schoolpay_shopping")) {
  //   schoolpayShopping.items.push({
  //     label: "Enroll A School",
  //     to: "/enroll-school",
  //   });
  // schoolpayShopping.items.push({
  //   label: "Bulk Enrollment",
  //   to: "/bulk_enroll_schools",
  // });
  // schoolpayShopping.items.push({
  //   label: "Commission Tier",
  //   to: "/commission_tier",
  // });
  // schoolpayShopping.items.push({
  //   label: "Enrolled Schools",
  //   to: "/enrolled-schools",
  // });

  // schoolpayShopping.items.push({
  //   label: "Mappings",
  //   to: routings.admin_mapping_management,
  // });
  // schoolpayShopping.items.push({
  //   label: "Mappings Requests",
  //   to: routings.mapping_requests,
  // });
  // schoolpayShopping.items.push({
  //   label: "Mappings Reports",
  //   to: routings.mapping_reports,
  // });

  // schoolpayShopping.items.push({
  //   label: "Commission Tiers",
  //   to: "/view-commission-tiers",
  // });
  // }

  if (checkRequiredPermission("can_manage_users")) {
    userMenu.items.push({ label: "Users", to: "/users" });
  }

  if (checkRequiredPermission("can_manage_businesses")) {
    userMenu.items.push({
      label: "Complete Your Profile",
      to: "/complete-registration",
    });

    clientMenu.items.push({
      label: "View Businesses",
      icon: "pi pi-fw pi-dollar",
      to: "/client-businesses",
    });
  }

  if (checkRequiredPermission("can_manage_farmers")) {
    farmersMenu.items.push({ label: "Add Vendor", to: "/add-farmer" });
    farmersMenu.items.push({ label: "View Vendors", to: "/farmers" });
  }

  if (checkRequiredPermission("can_manage_farmers")) {
    suppliersMenu.items.push({
      label: "Add Supplier",
      to: "/add-supplier",
    });
    suppliersMenu.items.push({
      label: "View Suppliers",
      to: "/suppliers",
    });
  }

  if (checkRequiredPermission("can_manage_farmers")) {
    cooperateEntities.items.push({
      label: "Add Entity",
      to: "/add-entity",
    });
    cooperateEntities.items.push({
      label: "View Entities",
      to: "/entities",
    });
  }

  if (checkRequiredPermission("can_manage_products")) {
    if (userRole !== "Pangisa Admin") {
      productsMenu.items.push({
        label: "Products",
        to: "/products",
      });
    }
    productsMenu.items.push({
      label: "Product Categories",
      to: "/product-categories",
    });
    productsMenu.items.push({
      label: "Sub Categories",
      to: routings.sub_categories,
    });
    productsMenu.items.push({
      label: "Product Brands",
      to: "/product-brands",
    });
    productsMenu.items.push({
      label: "Product Tags",
      to: "/product-tags",
    });

    if (userRole !== "Pangisa Admin") {
      productsMenu.items.push({
        label: "Packages",
        to: "/packages",
      });
    }
    productsMenu.items.push({
      label: "Packages",
      to: "/packages",
    });
  }

  userMenu.items.push({ label: "View Your Profile", to: "/my-profile" });
  if (checkRequiredPermission("can_manage_global_configurations")) {
    globalConfigMenu.items.push({
      label: "Industry Sub-Sector",
      to: "/industry-sub-sectors",
      icon: <Eye />,
    });
    globalConfigMenu.items.push({
      label: "Promotion Packages",
      to: "/promotion-packages",
      icon: <Eye />,
    });
    globalConfigMenu.items.push({
      label: "Industry Sub-Sector Type",
      to: "/industry-sub-sector-types",
      icon: <Eye />,
    });
    globalConfigMenu.items.push({
      label: "Business Association",
      to: "/business-body",
      icon: <Eye />,
    });
    globalConfigMenu.items.push({
      label: "Production Unit",
      to: "/production-units",
      icon: <Eye />,
    });
    globalConfigMenu.items.push({
      label: "Bank",
      to: "/bank-view",
      icon: <Eye />,
    });
    globalConfigMenu.items.push({
      label: "Location",
      to: "/view-location",
      icon: <Eye />,
    });

    globalConfigMenu.items.push({
      label: "Id Types",
      to: "/view-id-types",
      icon: <Eye />,
    });
    globalConfigMenu.items.push({
      label: "Payment Channels",
      to: "/view-payment-channel",
      icon: <Eye />,
    });
    globalConfigMenu.items.push({
      label: "Professions",
      to: "/view-profession",
      icon: <Eye />,
    });
    globalConfigMenu.items.push({
      label: "Registration Authorities",
      to: "/view-registration-body",
      icon: <Eye />,
    });
    globalConfigMenu.items.push({
      label: "Title",
      to: "/view-title",
      icon: <Eye />,
    });
  }
  // if (checkRequiredPermission("can_view_vendor_reports")) {
  //   reportsMenu.items.push({
  //     label: "Vendor Report",
  //     to: "/vendor-report",
  //   });
  // }
  // if (checkRequiredPermission("can_view_customer_reports")) {
  //   reportsMenu.items.push({
  //     label: "Coupons Report",
  //     to: "/coupons-report",
  //   });
  //   reportsMenu.items.push({
  //     label: "Customer Report",
  //     to: "/customer-report",
  //   });
  //   reportsMenu.items.push({
  //     label: "Payment Report",
  //     to: "/payments-report",
  //   });
  //   reportsMenu.items.push({
  //     label: "User Activity Report",
  //     to: "/useractivity-report",
  //   });
  //   reportsMenu.items.push({
  //     label: "Wishlist Report",
  //     to: "/wishlist-report",
  //   });
  // }

  // if (checkRequiredPermission("can_view_customer_reports")) {
  //   reportsMenu.items.push({
  //     label: "Product Report",
  //     to: "/product-report",
  //   });
  // }

  // if (checkRequiredPermission("can_view_customer_reports")) {
  //   reportsMenu.items.push({
  //     label: "Order Report",
  //     to: "/order-report",
  //   });
  // }

  // if (checkRequiredPermission("can_view_customer_reports")) {
  //   reportsMenu.items.push({
  //     label: "Sales Report",
  //     to: "/sales-report",
  //   });
  // }

  if (checkRequiredPermission("can_manage_orders")) {
    orderMenu.items.push({
      label: "Customer Orders",
      to: "/my-orders",
      icon: <Eye />,
    });

    // productReviews.items.push({
    //   label: "Product Reviews & Ratings",
    //   to: "product-reviews",
    // });
  }

  if (checkRequiredPermission("can_manage_commission")) {
    commissionMenu.items.push({
      label: "Commissions",
      to: "/view-commissions",
    });
    commissionMenu.items.push({
      label: "System Modules",
      to: "/view-system-modules",
    });
    commissionMenu.items.push({
      label: "Affiliates",
      to: "/partners",
    });
  }

  const tempMenuList: any = [];

  if (req) {
    // Populate menu item
    if (clientMenu.items.length > 0) {
      tempMenuList.push(clientMenu);
    }
    if (VendorSchoolMenu.items.length > 0) {
      tempMenuList.push(VendorSchoolMenu);
    }
    if (productsMenu.items.length > 0) {
      tempMenuList.push(productsMenu);
    }

    if (schoolpayShopping.items.length > 0) {
      tempMenuList.push(schoolpayShopping);
    }

    if (liquateCash.items.length > 0) {
      tempMenuList.push(liquateCash);
    }

    if (userMenu.items.length > 0) {
      tempMenuList.push(userMenu);
    }

    if (farmersMenu.items.length > 0) {
      tempMenuList.push(farmersMenu);
    }
    if (suppliersMenu.items.length > 0) {
      tempMenuList.push(suppliersMenu);
    }
    if (cooperateEntities.items.length > 0) {
      tempMenuList.push(cooperateEntities);
    }
    if (orderMenu.items.length > 0) {
      tempMenuList.push(orderMenu);
    }
    if (deliveryAgentsMenu.items.length > 0) {
      tempMenuList.push(deliveryAgentsMenu);
    }
    if (globalConfigMenu.items.length > 0) {
      tempMenuList.push(globalConfigMenu);
    }

    if (productReviews.items.length > 0) {
      tempMenuList.push(productReviews);
    }
    if (reportsMenu.items.length > 0) {
      tempMenuList.push(reportsMenu);
    }
    if (commissionMenu.items.length > 0) {
      tempMenuList.push(commissionMenu);
    }
    if (TermsAndConditionMenu.items.length > 0) {
      tempMenuList.push(TermsAndConditionMenu);
    }
  }

  if (checkRequiredPermission("can_approve_system_users")) {
    deliveryAgentsMenu.items.push({
      label: "Delivery Agents",
      to: "/delivery-agents",
    });
  }

  const approvalPermission = (): boolean => {
    if (checkRequiredPermission("can_approve_system_users")) {
      approvalsMenu.items.push({
        label: "Pending Approvals",
        to: "/pending-approvals",
      });

      return true;
    }

    return false;
  };

  const handleClick = (name: string) => {
    setOpen((prev) => (prev === name ? null : name));
  };

  const handleLogOut = () => {
    logOut();
    dispatch(SignOut());
    navigation("/login");
  };

  const [profilePic, setProfilePic] = useState<string | null>(null);

  useLayoutEffect(() => {
    let sub = true;

    if (sub) {
      const getUserProfile = async () => {
        const postObject = {
          auth: getCookie("loggedInUser"),
        };

        try {
          const data: ServerResponse = await request.sendRequestToServer(
            "CustomerManagement",
            "getCustomerData",
            JSON.stringify(postObject),
            true,
            null
          );

          if (data.returnCode === 200 && data.returnData) {
            setProfilePic(data.returnData.profilePicture || null);
          }
        } catch (error) {
          console.error("Error fetching customer info", error);
        }
      };

      getUserProfile();
    }

    return () => {
      sub = true;
    };
  }, []);

  return (
    <>
      <main className="w-screen h-screen flex flex-col bg-green-50">
        {/* Top Navigation Bar */}
        <div className="w-full h-12 bg-white border-b fixed flex px-4 py-3 justify-between z-50">
          <div className="flex items-center space-x-4 w-full md:w-[40%]">
            <h3 className="font-light text-base sm:text-lg md:text-lg lg:text-xl truncate">
              {topNav}
            </h3>
            <PartyPopper size={20} color="black" />
          </div>

          <div className="flex items-center space-x-4">
            <AlertCenter />
            <ProfileContainer
              img={profilePic || "https://via.placeholder.com/150"}
              firstName={surName}
              lastName={otherName}
              onClick={() => navigation("/myaccount")}
            />
          </div>
        </div>

        {/* Awaiting Approval Notification */}
        {loggedInUser?.user?.status === "Awaiting Approval" && (
          <div className="mt-10 bg-yellow-200 shadow-none border-b border-b-gray-200 flex items-center justify-center w-full px-2">
            <p className="sm:text-xs text-sm text-gray-500 text-center">
              Your account has not been approved yet.{" "}
              <span
                role="button"
                onClick={() => setModal(true)}
                className="text-base sm:text-sm hover:opacity-[.7] text-black font-bold"
              >
                Learn More
              </span>
            </p>
          </div>
        )}

        {/* Content Area */}
        <div className="flex flex-grow w-full h-full mt-10">
          {/* Sidebar */}
          <div className="flex-shrink-0 h-full overflow-auto bg-white">
            <SideBar>
              <SideBarItem
                icon={<House />}
                text="Dashboard"
                active={!open}
                navlink={
                  loggedInUser?.user?.userRole === 5 ||
                  loggedInUser?.user?.userRole === 4 ||
                  loggedInUser?.user.userRole === 1
                    ? routings.admin_dashboard
                    : routings.vendor_dashboard
                }
                alert
              />
              {tempMenuList.map((item: any, index) => (
                <SideBarItem
                  key={index}
                  icon={item.icon ? item.icon : <Pointer />}
                  text={item.label}
                  onClick={() => handleClick(item.label)}
                  open={open === item.label}
                >
                  {item.items?.map((subItem: any, subIndex: number) => (
                    <SideBarItem
                      key={subIndex}
                      icon={<List />}
                      text={subItem.label}
                      navlink={subItem.to && subItem.to}
                    />
                  ))}
                </SideBarItem>
              ))}

              {approvalPermission() && (
                <div>
                  <SideBarItem
                    icon={<Signature />}
                    text="Pending Approvals"
                    active={open === "Pending Approvals"}
                    onClick={() => handleClick("Pending Approvals")}
                    navlink="/pending-approvals"
                  />
                </div>
              )}

              <div className="py-5">
                <SideBarItem
                  text="Logout"
                  icon={<LogOut />}
                  onClick={handleLogOut}
                />
              </div>
            </SideBar>
          </div>

          {/* Main Content */}
          <div className="flex-grow h-full overflow-auto px-2 bg-[#f2f2f2]">
            {/* Your content goes here */}
            {children}
          </div>
        </div>

        <Toaster />
      </main>
    </>
  );
};

export default MainView;
