import React, { useContext, useEffect, useState } from "react";
import {
  School,
  GraduationCap,
  Package,
  ClipboardCheck,
  DollarSign,
  CheckCircle,
  X,
  MessageSquare,
  ArrowLeft,
  MapPin,
  Calendar,
  Tag,
  FileText,
  Shield,
} from "lucide-react";
import defaultImage from "../../../src/assets/img/packageimage.jpg";
import { AppContext } from "../../context/AppContext";
import { CommonComponent } from "../../service";
import { getCookie } from "../../service/get-user";
import { useNavigate, useParams } from "react-router-dom";
import { routings } from "../../routes/routings.ts";

const InfoCard = ({ icon, label, value, className = "" }) => (
  <div
    className={`bg-white rounded-xl p-4 shadow-sm border border-indigo-100 transition-all duration-300 hover:shadow-md hover:border-indigo-300 ${className}`}
  >
    <div className="flex items-start gap-3">
      <div className="p-2 rounded-lg bg-indigo-50 text-[--school-pay-primary-color]">
        {icon}
      </div>
      <div>
        <p className="text-sm font-medium text-gray-500">{label}</p>
        <p className="text-lg font-semibold text-gray-900">{value}</p>
      </div>
    </div>
  </div>
);

const FinancialCard = ({ icon, label, value, highlight = false }) => (
  <div
    className={`relative overflow-hidden rounded-xl p-5 shadow-sm transition-all duration-300 hover:shadow-md ${
      highlight
        ? "bg-gradient-to-br from-[--school-pay-primary-color] to-[--primary-color] text-white"
        : "bg-white border border-indigo-100"
    }`}
  >
    {highlight && (
      <div className="absolute top-0 right-0 bg-yellow-400 text-[--school-pay-primary-color] text-xs font-bold px-2 py-1 rounded-bl-lg">
        TOTAL
      </div>
    )}
    <div className="flex items-center gap-3 mb-2">
      <div
        className={`p-2 rounded-lg ${
          highlight
            ? "bg-white/20 text-white"
            : "bg-indigo-100 text-[--school-pay-primary-color]"
        }`}
      >
        {icon}
      </div>
      <p
        className={`text-sm font-medium ${
          highlight ? "text-white/90" : "text-gray-500"
        }`}
      >
        {label}
      </p>
    </div>
    <p
      className={`text-2xl font-bold ${
        highlight ? "text-white" : "text-[--primary-color]"
      }`}
    >
      UGX {value}
    </p>
  </div>
);

const StatusBadge = ({ status }) => {
  const getStatusStyles = () => {
    switch (status) {
      case "Active":
        return "bg-green-100 text-green-800 border-green-300";
      case "Inactive":
        return "bg-red-100 text-red-800 border-red-300";
      case "Pending Approval":
        return "bg-yellow-100 text-yellow-800 border-yellow-300";
      default:
        return "bg-gray-100 text-gray-800 border-gray-300";
    }
  };

  return (
    <span
      className={`px-3 py-1 rounded-full text-sm font-medium border ${getStatusStyles()}`}
    >
      {status}
    </span>
  );
};

const ApprovalModal = ({ isOpen, onClose, onApprove }) => {
  const [comment, setComment] = useState("");

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4"
      role="dialog"
    >
      <div className="bg-white rounded-2xl shadow-2xl w-full max-w-lg overflow-hidden animate-fadeIn">
        {/* Modal Header */}
        <div className="bg-gradient-to-r from-[--school-pay-primary-color] to-indigo-800 px-6 py-4 flex justify-between items-center">
          <h3 className="text-xl font-semibold text-white flex items-center gap-2">
            <MessageSquare className="h-5 w-5" />
            Approval Confirmation
          </h3>
          <button
            onClick={onClose}
            className="text-white/80 hover:text-white transition-colors"
            aria-label="Close modal"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        {/* Modal Content */}
        <div className="p-6">
          <div className="mb-6">
            <label
              htmlFor="comment"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Approval reason for the mapping
            </label>
            <textarea
              id="comment"
              rows={4}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter your approval comment here..."
              className="w-full px-4 py-3 border border-gray-300 rounded-xl focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-shadow resize-none"
              autoFocus
            />
            <p className="mt-2 text-sm text-gray-500">
              Your comment will be recorded with this approval action.
            </p>
          </div>

          {/* Modal Actions */}
          <div className="flex justify-end gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                if (comment.trim()) {
                  onApprove(comment);
                  onClose();
                }
              }}
              disabled={!comment.trim()}
              className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
            >
              <CheckCircle className="h-5 w-5" />
              Confirm Approval
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const auth = getCookie("loggedInUser");
const req = new CommonComponent();

const SchoolpackageInfor = () => {
  const [MapData, setMapData] = useState<any>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { setLoading, setSnackAlert }: any = useContext(AppContext);
  const { schoolpackageId } = useParams();
  const navigation = useNavigate();

  const handleApprovePackageMapping = async (comment: string) => {
    setLoading(true);

    let postObject = {
      auth,
      ids: [schoolpackageId],
      comment,
    };

    try {
      const res = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "approvePackageSchoolMapping",
        JSON.stringify(postObject)
      );

      const { returnCode, returnMessage } = res;

      if (returnCode === 200) {
        setSnackAlert({
          open: true,
          severity: "success",
          message: returnMessage,
        });
        setTimeout(() => {
          navigation("/school-mapping-requests/" + MapData.school_code);
        }, 3000);
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: "Failed to approve school mapping",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to approve school mapping",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSchoolsToPackageMapping();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSchoolsToPackageMapping = async () => {
    let postObject = {
      search: {
        mappingId: schoolpackageId,
      },
    };
    setLoading(true);

    try {
      const res = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "getmapPackageToSchoolClasses",
        JSON.stringify(postObject)
      );

      if (res.returnCode === 200) {
        setLoading(false);
        setMapData(res.returnData[0]);
        return;
      } else {
        console.error("Failed to fetch schools to be approved");
        setLoading(false);
        setSnackAlert({
          open: true,
          severity: "error",
          message: "Failed to fetch schools to be approved",
        });
        return;
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to fetch schools to be approved",
      });
      return;
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50">
      <div className="h-screen overflow-y-auto">
        <div className="max-w-screen-xl mx-auto p-4 sm:p-6">
          {/* Back button */}
          <button
            onClick={() =>
              navigation("/school-mapping-requests/" + MapData.school_code)
            }
            className="mb-4 flex items-center gap-2 text-[--primary-color] hover:text-[--school-pay-primary-color] transition-colors"
          >
            <ArrowLeft className="h-4 w-4" />
            <span>Back to School Mapping Requests</span>
          </button>

          <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
            {/* Enhanced Header with Image */}
            <div className="relative h-64 sm:h-80">
              <img
                src="https://images.unsplash.com/photo-1523050854058-8df90110c9f1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
                alt={MapData.school_name}
                className="w-full h-full object-cover"
                loading="lazy"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-black/30"></div>

              <div className="absolute inset-0 px-6 py-6 flex flex-col justify-between">
                <div className="flex items-start justify-between">
                  <div className="bg-white/10 backdrop-blur-md rounded-lg px-4 py-2 inline-flex items-center gap-2">
                    <School className="h-6 w-6 sm:h-7 sm:w-7 text-white" />
                    <h1 className="text-xl sm:text-2xl font-bold text-white">
                      School Package Mapping
                    </h1>
                  </div>
                  <div className="flex items-center gap-2">
                    <span className="text-white font-medium">
                      School Status:
                    </span>
                    <StatusBadge status={MapData.school_status} />
                  </div>
                </div>

                <div className="bg-black/40 backdrop-blur-sm rounded-xl p-5 mt-4 border border-white/10">
                  <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
                    <div>
                      <p className="text-2xl sm:text-3xl font-bold text-white mb-2">
                        {MapData.school_name}
                      </p>
                      <div className="flex flex-wrap items-center gap-3">
                        <span className="px-3 py-1 bg-white/20 rounded-full text-sm text-white flex items-center gap-1">
                          <Tag className="h-3 w-3" />
                          {MapData.school_type}
                        </span>
                        <span className="px-3 py-1 bg-white/20 rounded-full text-sm text-white flex items-center gap-1">
                          <GraduationCap className="h-3 w-3" />
                          {MapData.class_name}
                        </span>
                        <span className="px-3 py-1 bg-white/20 rounded-full text-sm text-white flex items-center gap-1">
                          <MapPin className="h-3 w-3" />
                          {MapData.location}
                        </span>
                      </div>
                    </div>
                    <div className="bg-[--school-pay-primary-color] text-white px-4 py-2 rounded-lg flex items-center gap-2">
                      <Shield className="h-5 w-5" />
                      <span>Code: {MapData.school_code}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Content */}
            <div className="p-6 space-y-8">
              {/* Package Details */}
              <section>
                <h2 className="text-xl font-semibold text-gray-800 flex items-center gap-2 mb-6 pb-2 border-b border-gray-200">
                  <Package className="h-5 w-5 text-[--school-pay-primary-color]" />
                  Package Details
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  <InfoCard
                    icon={<FileText className="h-5 w-5" />}
                    label="Package Name"
                    value={MapData.package_name}
                  />
                  <InfoCard
                    icon={<Tag className="h-5 w-5" />}
                    label="Package Type"
                    value={MapData.package_type}
                  />
                  <InfoCard
                    icon={<Calendar className="h-5 w-5" />}
                    label="Created On"
                    value={MapData.created_on}
                  />
                </div>
              </section>

              {/* School Information */}
              <section>
                <h2 className="text-xl font-semibold text-gray-800 flex items-center gap-2 mb-6 pb-2 border-b border-gray-200">
                  <GraduationCap className="h-5 w-5 text-[--school-pay-primary-color]" />
                  School Information
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  <InfoCard
                    icon={<School className="h-5 w-5" />}
                    label="School Name"
                    value={MapData.school_name}
                  />
                  <InfoCard
                    icon={<Tag className="h-5 w-5" />}
                    label="School Type"
                    value={MapData.school_type}
                  />
                  <InfoCard
                    icon={<GraduationCap className="h-5 w-5" />}
                    label="Class Name"
                    value={MapData.class_name}
                  />
                </div>
              </section>

              {/* Financial Information */}
              <section className="bg-gradient-to-r from-indigo-50 to-blue-50 rounded-xl p-6">
                <h2 className="text-xl font-semibold text-gray-800 flex items-center gap-2 mb-6">
                  <DollarSign className="h-5 w-5 text-[--school-pay-primary-color]" />
                  Financial Summary
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                  <FinancialCard
                    icon={<Tag className="h-5 w-5" />}
                    label="Package Price"
                    value={MapData.package_price}
                  />
                  <FinancialCard
                    icon={<DollarSign className="h-5 w-5" />}
                    label="Total Cost"
                    value={MapData.totalcost}
                  />
                  <FinancialCard
                    icon={<DollarSign className="h-5 w-5" />}
                    label="Grand Price"
                    value={MapData.grandprice}
                    highlight={true}
                  />
                </div>
              </section>

              {/* Status Information and Approve Button */}
              <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 pt-4 border-t border-gray-200">
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-2 bg-gray-50 p-3 rounded-xl border border-gray-200">
                    <ClipboardCheck className="h-5 w-5 text-[--school-pay-primary-color]" />
                    <span className="text-sm text-white rounded-r-md bg-[--primary-color] px-3 py-2">
                      Mapping Status:
                    </span>
                    <StatusBadge status={MapData.mapping_status} />
                  </div>
                  <div className="flex items-center gap-2 bg-gray-50 p-3 rounded-xl border border-gray-200">
                    <Calendar className="h-5 w-5 text-[--school-pay-primary-color]" />
                    <span className="text-sm text-gray-500">Approved On:</span>
                    <span className="text-sm font-medium text-gray-700">
                      {MapData.approved_on}
                    </span>
                  </div>
                </div>
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="flex items-center gap-2 px-6 py-3 bg-gradient-to-r from-green-500 to-green-600 text-white rounded-lg hover:from-green-600 hover:to-green-700 transition-colors duration-200 shadow-md hover:shadow-lg"
                >
                  <CheckCircle className="h-5 w-5" />
                  Approve Package
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Approval Modal */}
      <ApprovalModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onApprove={handleApprovePackageMapping}
      />
    </div>
  );
};

export default SchoolpackageInfor;
