import * as React from "react";
import { useDispatch } from "react-redux";
import { addToCart as updateCartContent } from "../redux/actions/cart-actions.ts";
import { CommonComponent } from "../service/CommonComponent.js";
import { getCookie } from "../service/get-user";
import { AppContext } from "./AppContext";

const commonComponent = new CommonComponent();
export const CartContext = React.createContext(null);

export const CartProvider = ({ children }) => {
  const { setSnackAlert } = React.useContext(AppContext);
  const dispatch = useDispatch();
  const [cartItems, setCartItems] = React.useState([]);
  const [wishlistItems, setWishlistItems] = React.useState([]);
  // const [unreadCount, setUnreadCount] = React.useState(0);
  // const [totalPrice, setTotalPrice] = React.useState(0);

  // Cart Methods
  const addToCart = async (item) => {
    try {
      const payload = {
        auth: getCookie("loggedInUser"),
        product_id: item.id,
        quantity: 1,
      };

      if (cartItems.length > 0) {
        const firstItemVendor = cartItems[0].client_id;
        if (firstItemVendor !== item.client_id) {
          setSnackAlert({
            open: true,
            severity: "warning",
            message:
              "You can only add items from a single vendor to your cart.",
          });
          return;
        }
      }

      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "addToCart",
        JSON.stringify(payload)
      );

      if (response.returnCode === 200) {
        dispatch(updateCartContent(response.returnData.cart_item));

        setCartItems((prevItems) => {
          const existingItemIndex = prevItems.findIndex(
            (i) => i.id === item.id
          );
          if (existingItemIndex >= 0) {
            const updatedItems = [...prevItems];
            updatedItems[existingItemIndex].quantity += 1;
            return updatedItems;
          } else {
            setSnackAlert({
              open: true,
              severity: "success",
              message: `${item.product_name} added to cart`,
            });
            return [...prevItems, { ...item, quantity: 1 }];
          }
        });
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: response.returnMessage,
        });
      }
    } catch (error) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to add item to cart",
      });
    }
  };

  const updateQuantity = async (productId, quantity) => {
    debugger;
    try {
      const payload = {
        auth: getCookie("loggedInUser"),
        product_id: productId,
        quantity: quantity,
      };

      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "updateCartQuantity",
        JSON.stringify(payload)
      );

      if (response.returnCode === 200) {
        setCartItems((prevItems) =>
          prevItems.map((item) =>
            item.id === productId
              ? { ...item, quantity: Math.max(quantity, 1) }
              : item
          )
        );

        setSnackAlert({
          open: true,
          severity: "success",
          message: response.returnMessage,
        });
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: response.returnMessage,
        });
      }
    } catch (error) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to update quantity",
      });
    }
  };

  const removeFromCart = async (productId) => {
    try {
      const payload = {
        auth: getCookie("loggedInUser"),
        product_id: productId,
      };

      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "removeFromCart",
        JSON.stringify(payload)
      );

      if (response.returnCode === 200) {
        setCartItems((prevItems) =>
          prevItems.filter((item) => item.product_id !== productId)
        );

        setSnackAlert({
          open: true,
          severity: "success",
          message: response.returnMessage,
        });
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: response.returnMessage,
        });
      }
    } catch (error) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to remove item from cart",
      });
    }
  };

  const clearCart = async () => {
    try {
      const payload = {
        auth: getCookie("loggedInUser"),
      };

      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "clearCart",
        JSON.stringify(payload)
      );

      if (response.returnCode === 200) {
        setCartItems([]);
        setSnackAlert({
          open: true,
          severity: "success",
          message: response.returnMessage,
        });
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: response.returnMessage,
        });
      }
    } catch (error) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to clear cart",
      });
    }
  };

  const fetchCartItems = async () => {
    try {
      const payload = {
        auth: getCookie("loggedInUser"),
      };

      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "getCartItems",
        JSON.stringify(payload)
      );

      if (response.returnCode === 200) {
        setCartItems(response.returnData.items || []);
      } else {
        throw new Error(response.data.returnMessage);
      }
    } catch (error) {
      throw new Error("Error");
    }
  };

  // Wishlist Methods
  const addToWishlist = async (item) => {
    try {
      const payload = {
        auth: getCookie("loggedInUser"),
        product_id: item.id,
      };

      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "addToWishlist",
        JSON.stringify(payload)
      );

      if (response.returnCode === 200) {
        setWishlistItems((prevItems) => {
          const existingItem = prevItems.find((i) => i.id === item.id);
          if (!existingItem) {
            setSnackAlert({
              open: true,
              severity: "success",
              message: `${item.product_name} added to wishlist`,
            });
            return [...prevItems, item];
          }
          return prevItems;
        });
      } else if (response.returnCode === 409) {
        setSnackAlert({
          open: true,
          severity: "warning",
          message: response.returnMessage,
        });
      } else {
        setSnackAlert({
          open: true,
          severity: "warning",
          message: "Failed to add item to wishlist",
        });
      }
    } catch (error) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to add item to wishlist",
      });
    }
  };

  const removeFromWishlist = async (productId) => {
    try {
      const payload = {
        auth: getCookie("loggedInUser"),
        product_id: productId,
      };

      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "removeFromWishlist",
        JSON.stringify(payload)
      );

      if (response.returnCode === 200) {
        setWishlistItems((prevItems) =>
          prevItems.filter((item) => item.id !== productId)
        );

        setSnackAlert({
          open: true,
          severity: "success",
          message: response.returnMessage,
        });
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: response.returnMessage,
        });
      }
    } catch (error) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to remove item from wishlist",
      });
    }
  };

  const fetchWishlistItems = async () => {
    try {
      const payload = {
        auth: getCookie("loggedInUser"),
      };

      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "getWishlistItems",
        JSON.stringify(payload)
      );

      if (response.returnCode === 200) {
        setWishlistItems(response.returnData || []);
      } else {
        throw new Error(response.returnMessage);
      }
    } catch (error) {
      console.error("Error fetching wishlist items:", error);
      //   setSnackAlert({
      //     open: true,
      //     severity: "error",
      //     message: "Failed to fetch wishlist items",
      //   });
    }
  };

  const isInWishlist = (productId) => {
    return wishlistItems.some((item) => item.id === productId);
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        setCartItems,
        addToCart,
        updateQuantity,
        removeFromCart,
        clearCart,
        fetchCartItems,
        wishlistItems,
        setWishlistItems,
        addToWishlist,
        removeFromWishlist,
        fetchWishlistItems,
        isInWishlist,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return React.useContext(CartContext);
};
