import React, { useEffect, useState } from "react";
import {
  Search,
  CheckCircle,
  Phone,
  Mail,
  Calendar,
  User,
  Building2,
  Shield,
  ArrowRight,
} from "lucide-react";
import { AppContext } from "../../context/AppContext";
import { CommonComponent } from "../../service";

const req = new CommonComponent();

type props = {
  schoolCode?: any;
};

export default function SchoolToVendor({ schoolCode }: props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedVendor, setSelectedVendor] = useState<any | null>(null);
  const [mappingStep, setMappingStep] = useState<
    "select" | "review" | "confirm"
  >("select");
  const [vendors, setVendors] = useState<any[]>([]);
  const [school, setSchool] = useState<any>({});

  const filteredVendors = vendors.filter(
    (vendor) =>
      vendor.vendor_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vendor.user_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      vendor.email_address.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const { setLoading, setSnackAlert }: any = React.useContext(AppContext);

  useEffect(() => {
    let sub = true;

    if (sub) {
      getAllActiveVendors();
      getAllEnrolledSchools();
    }

    return () => {
      sub = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log("school: " + school);

  const getAllEnrolledSchools = async () => {
    setLoading(true);

    const postObject = {
      search: { schoolCode },
    };
    try {
      const response: Promise<any> = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "getEnrolledSchools",
        JSON.stringify(postObject)
      );

      const { returnCode, returnData }: any = response;

      if (returnCode === 200) {
        setLoading(false);

        setSchool(returnData[0]);
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: "We're Having Trouble Retrieving Enrolled Schools",
        });

        // change route
        setTimeout(() => {
          // history.push("/enroll_school");
          setSnackAlert({ open: false, severity: "error", message: "" });
        }, 5000);
      }
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getAllActiveVendors = async () => {
    setLoading(true);
    const postObject = {
      search: {},
    };
    try {
      const response: Promise<any> = await req.sendRequestToServer(
        "ClientManagement",
        "getClientWithoutSession",
        JSON.stringify(postObject)
      );

      const { returnCode, returnData }: any = response;

      if (returnCode === 200) {
        setLoading(false);

        setVendors(returnData.rows);
      } else {
        setLoading(false);

        setSnackAlert({
          open: true,
          severity: "error",
          message: "We're Having Trouble Retrieving Enrolled Schools",
        });

        // change route
        setTimeout(() => {
          // history.push("/enroll_school");
          setSnackAlert({ open: false, severity: "error", message: "" });
        }, 5000);
      }
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleVendorSelect = (vendor: any) => {
    setSelectedVendor(vendor);
    setMappingStep("review");
  };

  const handleConfirmMapping = async () => {
    if (!selectedVendor) return;

    const postObject = {
      vendorId: selectedVendor.id,
      schoolIdArray: [school.id],
      initiator: "school",
    };

    try {
      const { returnCode, returnMessage } = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "mapVendorToSchoolWithoutSession",
        JSON.stringify(postObject)
      );

      if (returnCode === 200) {
        setSnackAlert({
          open: true,
          severity: "success",
          message: "Vendor Mapping Successful",
        });

        setMappingStep("confirm");

        setTimeout(() => {
          // history.push("/enroll_school");
          setSnackAlert({ open: false, severity: "success", message: "" });
        }, 5000);
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: returnMessage,
        });

        setTimeout(() => {
          // history.push("/enroll_school");
          setSnackAlert({ open: false, severity: "error", message: "" });
          setMappingStep("select");
        }, 5000);
      }
    } catch (error) {
      console.error("Error mapping vendor:", error);
    }
  };

  const handleReset = () => {
    setSelectedVendor(null);
    setMappingStep("select");
    setSearchTerm("");
  };

  const renderVendorCard = (vendor: any) => (
    <div
      key={vendor.id}
      className={`p-6 rounded-xl transition-all cursor-pointer ${
        selectedVendor?.id === vendor.id
          ? "bg-blue-50 border-2 border-[--school-pay-primary-color]"
          : "bg-white hover:bg-gray-50 border-2 border-transparent"
      }`}
      onClick={() => handleVendorSelect(vendor)}
    >
      <div className="flex items-start justify-between">
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <Building2
              className="text-[--school-pay-primary-color]"
              size={20}
            />
            <span className="font-mono text-sm bg-blue-100 text-[--school-pay-primary-color] px-2 py-1 rounded">
              {vendor.vendor_code}
            </span>
          </div>
          <h3 className="text-lg font-semibold text-gray-800">
            {vendor.user_name}
          </h3>
          <div className="flex items-center gap-4 text-sm text-gray-600">
            <div className="flex items-center gap-1">
              <Mail size={16} />
              {vendor.email_address}
            </div>
            <div className="flex items-center gap-1">
              <Phone size={16} />
              {vendor.phone_no}
            </div>
          </div>
        </div>
        <span
          className={`px-3 py-1 rounded-full text-sm ${
            vendor.status === "Approved"
              ? "bg-green-100 text-green-800"
              : "bg-yellow-100 text-yellow-800"
          }`}
        >
          {vendor.status}
        </span>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 p-8">
      <div className="max-w-7xl mx-auto">
        {/* Progress Steps */}
        <div className="mb-8">
          <div className="flex items-center justify-center gap-4">
            {["select", "review", "confirm"].map((step, index) => (
              <React.Fragment key={step}>
                <div
                  className={`flex items-center gap-2 ${
                    mappingStep === step
                      ? "text-[--school-pay-primary-color]"
                      : "text-gray-400"
                  }`}
                >
                  <div
                    className={`w-8 h-8 rounded-full flex items-center justify-center ${
                      mappingStep === step
                        ? "bg-[--school-pay-primary-color] text-white"
                        : "bg-gray-200 text-gray-600"
                    }`}
                  >
                    {index + 1}
                  </div>
                  <span className="font-medium capitalize">{step}</span>
                </div>
                {index < 2 && (
                  <ArrowRight className="text-gray-400" size={20} />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>

        {mappingStep === "select" && (
          <>
            {/* Search Section */}
            <div className="bg-white rounded-xl shadow-lg p-6 mb-6">
              <div className="relative">
                <Search
                  className="absolute left-4 top-3.5 text-gray-400"
                  size={20}
                />
                <input
                  type="text"
                  className="w-full pl-12 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-[--school-pay-primary-color] focus:border-transparent text-lg"
                  placeholder="Search by vendor code, name, or email..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>

            {/* Vendor List */}
            {/* Vendor List (Scrollable) */}
            <div className="space-y-4 max-h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
              {filteredVendors.map((vendor) => renderVendorCard(vendor))}
            </div>
          </>
        )}

        {mappingStep === "review" && selectedVendor && (
          <div className="bg-white rounded-xl shadow-lg p-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">
              Review Vendor Details
            </h2>
            <div className="grid grid-cols-2 gap-8 mb-8">
              <div className="space-y-4">
                <div className="space-y-2">
                  <label className="text-sm text-gray-500">Vendor Code</label>
                  <div className="flex items-center gap-2">
                    <Building2
                      className="text-[--school-pay-primary-color]"
                      size={20}
                    />
                    <span className="font-mono text-lg">
                      {selectedVendor.vendor_code}
                    </span>
                  </div>
                </div>
                <div className="space-y-2">
                  <label className="text-sm text-gray-500">Name</label>
                  <div className="flex items-center gap-2">
                    <User
                      className="text-[--school-pay-primary-color]"
                      size={20}
                    />
                    <span className="text-lg">{selectedVendor.user_name}</span>
                  </div>
                </div>
                <div className="space-y-2">
                  <label className="text-sm text-gray-500">Email</label>
                  <div className="flex items-center gap-2">
                    <Mail
                      className="text-[--school-pay-primary-color]"
                      size={20}
                    />
                    <span className="text-lg">
                      {selectedVendor.email_address}
                    </span>
                  </div>
                </div>
              </div>
              <div className="space-y-4">
                <div className="space-y-2">
                  <label className="text-sm text-gray-500">Phone</label>
                  <div className="flex items-center gap-2">
                    <Phone
                      className="text-[--school-pay-primary-color]"
                      size={20}
                    />
                    <span className="text-lg">{selectedVendor.phone_no}</span>
                  </div>
                </div>
                <div className="space-y-2">
                  <label className="text-sm text-gray-500">Status</label>
                  <div className="flex items-center gap-2">
                    <Shield
                      className="text-[--school-pay-primary-color]"
                      size={20}
                    />
                    <span
                      className={`px-3 py-1 rounded-full text-sm ${
                        selectedVendor.status === "Approved"
                          ? "bg-green-100 text-green-800"
                          : "bg-yellow-100 text-yellow-800"
                      }`}
                    >
                      {selectedVendor.status}
                    </span>
                  </div>
                </div>
                <div className="space-y-2">
                  <label className="text-sm text-gray-500">Created On</label>
                  <div className="flex items-center gap-2">
                    <Calendar
                      className="text-[--school-pay-primary-color]"
                      size={20}
                    />
                    <span className="text-lg">{selectedVendor.created_on}</span>
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={handleConfirmMapping}
              className="w-full py-3 bg-[--school-pay-primary-color] text-white rounded-xl hover:[--school-pay-primary-color] flex items-center justify-center gap-2 text-lg font-medium"
            >
              <CheckCircle size={20} />
              Confirm Mapping
            </button>
          </div>
        )}

        {mappingStep === "confirm" && selectedVendor && (
          <div className="bg-white rounded-xl shadow-lg p-8 text-center">
            <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-6">
              <CheckCircle className="text-green-600" size={32} />
            </div>
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              Mapping Confirmed!
            </h2>
            <p className="text-gray-600 mb-6">
              Vendor {selectedVendor.user_name} has been successfully mapped.
            </p>
            <button
              onClick={handleReset}
              className="px-6 py-3 bg-[--school-pay-primary-color] text-white rounded-xl hover:[--school-pay-primary-color]"
            >
              Map Another Vendor
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
