import { spinnerService } from "@chevtek/react-spinners";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";

import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { CommonComponent } from "../../../service";
import { getCookie } from "../../../service/get-user";
import MessagesComponent from "../../GenericComponents/MessagesComponent";

class AddSystemUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: true,
      userTitles: [],
      firstName: "",
      lastName: "",
      middleName: "",
      phoneNo: "",
      emailAddress: "",
      userRole: null,
      userRoleId: null,
      password: "",
      roles: "",
      signInVia: "WEB_ADMIN",
      repeatPassword: "",
      termsAndConditions: null,
      userName: "",
      rememberToken: false,
      success: null,
      processing: false,
      id: null,
      userType: "CLIENT",
      REGISTERED_BY: "ADMIN",
      clientId: "",
      clients: "",
      contactType: "VENDOR",
      agent: null,
      agentId: null,
      deliveryAgents: [],
      entity: "",
      entityId: "",
      entities: [],
      client: "",
    };
    this.validator = new SimpleReactValidator({
      validators: {
        password: {
          // name the rule
          message:
            "The :attribute must be eight characters long, contain atleast one number, one lower case and one upper case character :values.",
          rule: (val, params, validator) => {
            return (
              validator.helpers.testRegex(
                val,
                /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w~@#$%^&*+=`|{}:;!.?"()[\]-]{8,25}$/i
              ) && params.indexOf(val) === -1
            );
          },
          messageReplace: (message, params) =>
            message.replace(":values", this.helpers.toSentence(params)), // optional
        },
        confirmpassword: {
          // name the rule
          message: "The passwords provided do not match.",
          rule: (val) => {
            return val === this.state.password;
          },
        },
      },
    });
    this.rendomPasswordGenerator = this.rendomPasswordGenerator.bind(this);
    this.register = this.register.bind(this);
    this.getClients = this.getClients.bind(this);
    this.setClientId = this.setClientId.bind(this);
    this.setAgentId = this.setAgentId.bind(this);
    this.setEntityId = this.setEntityId.bind(this);
    this.userId = this.props.match.params.userId;
    this.commonComponent = new CommonComponent();
  }

  componentDidMount() {
    this.getUserRoles();
    this.getClients();
  }

  getClients() {
    let postObject = {
      auth: getCookie("loggedInUser"),
      search: { sortField: "id" },
    };
    this.setState({ processing: true });
    this.commonComponent
      .sendRequestToServer(
        "ClientManagement",
        "getClients",
        JSON.stringify(postObject),
        true,
        null
      )
      .then((data) => {
        this.setState({ processing: false });
        if (data.returnCode === 200) {
          console.log(JSON.stringify(data.returnData.rows, null, 2));
          let clients = data.returnData.rows;
          this.setState({
            clients: clients,
          });
          console.log(JSON.stringify(clients, null, 2));
        } else {
        }
      });
  }

  async register(event) {
    event.preventDefault();
    if (this.validator.allValid()) {
      this.setState({ processing: true });
      let postObject = {
        auth: getCookie("loggedInUser"),
        requestData: this.state,
      };
      console.log(JSON.stringify(postObject.requestData, null, 2));
      this.setState({
        processing: true,
        password: this.rendomPasswordGenerator(8),
      });
      this.commonComponent
        .sendRequestToServer(
          "AuthManagement",
          "addUser",
          JSON.stringify(postObject),
          true,
          null
        )
        .then((data) => {
          if (data.returnCode === 200) {
            this.setState({ processing: false, success: true });
            setTimeout((val) => {
              this.props.history.push("/users");
            }, 2000);
          } else {
            this.setState({ success: false, processing: false });
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  rendomPasswordGenerator(keyLength) {
    let newPassWord = "",
      characters =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    const charactersLength = characters.length;

    for (let i = 0; i < keyLength; i++) {
      newPassWord += characters.substr(
        Math.floor(Math.random() * charactersLength + 1),
        1
      );
    }

    return newPassWord;
  }

  setClientId(value) {
    this.setState({ client: value, clientId: value.id });
  }

  setAgentId(value) {
    this.setState({ agent: value, agentId: value.id });
  }

  setEntityId(value) {
    this.setState({ entity: value, entityId: value.id });
  }

  getUserRoles = () => {
    this.setState({ processing: true, success: undefined });
    let postObject = {
      auth: getCookie("loggedInUser"),
      search: {},
    };
    this.commonComponent
      .sendRequestToServer(
        "AuthManagement",
        "getUserRole",
        JSON.stringify(postObject),
        true,
        null
      )
      .then((data) => {
        switch (data.returnCode) {
          case 200:
            this.setState({ roles: data.returnData.rows });

            break;
          default:
            this.returnMessage = data.returnMessage;
            this.setState({ success: false, processing: false });
            break;
        }
      });
  };

  //Get delivery agents

  render() {
    let alertMessage;
    switch (this.state.success) {
      case true:
        alertMessage = (
          <MessagesComponent
            messageType={"Success"}
            message="Changes saved successfully"
          />
        );
        break;
      case false:
        alertMessage = (
          <MessagesComponent
            messageType={"Error"}
            message="Failed to save changes"
          />
        );
        break;
      default:
        alertMessage = null;
        break;
    }

    return (
      <div className="p-grid p-fluid -mt-12 -ml-6">
        <div className="p-col-12 p-lg-12">
          <div className="card card-w-title w-full pl-4 flex items-center">
            {alertMessage}
            <h2 className="form-title mt-10 border--2">Add System Users</h2>
            <form onSubmit={this.register} className="w-full ml-[25%] mt-4">
              <div className="p-grid" style={styles.elementMargin}>
                <div className="p-col-4">
                  <Dropdown
                    options={this.state.roles}
                    dataKey="id"
                    optionLabel="role_name"
                    onChange={(e) => this.setState({ userRole: e.value })}
                    tooltip="Select role"
                    style={styles.textbox}
                    value={this.state.userRole}
                    tooltipOptions={{ position: "top" }}
                    placeholder="Select role"
                  />
                  <div className="text-danger">
                    {this.validator.message(
                      "userRole",
                      this.state.userRole,
                      "required"
                    )}
                  </div>
                </div>
                <div className="p-col-4">
                  <Dropdown
                    options={this.state.clients}
                    dataKey="id"
                    optionLabel="name"
                    onChange={(e) => this.setClientId(e.value)}
                    tooltip="Select Client"
                    style={styles.textbox}
                    value={this.state.client}
                    tooltipOptions={{ position: "top" }}
                    placeholder="Select Client"
                  />
                  <div className="text-danger">
                    {this.validator.message(
                      "userRole",
                      this.state.userRole,
                      "required"
                    )}
                  </div>
                </div>
              </div>

              <div className="p-grid" style={styles.elementMargin}>
                <div className="p-col-4">
                  <InputText
                    style={styles.textbox}
                    id="firstName"
                    value={this.state.firstName}
                    onChange={(e) =>
                      this.setState({ firstName: e.target.value })
                    }
                    tooltip="Enter First Name"
                    tooltipOptions={{ position: "top" }}
                    placeholder="Enter First Name"
                  />
                  <div className="text-danger">
                    {this.validator.message(
                      "firstName",
                      this.state.firstName,
                      "alpha|required|min:2"
                    )}
                  </div>
                </div>
                <div className="p-col-4">
                  <InputText
                    style={styles.textbox}
                    id="middleName"
                    value={this.state.middleName}
                    onChange={(e) =>
                      this.setState({ middleName: e.target.value })
                    }
                    tooltip="Enter Middle Name"
                    tooltipOptions={{ position: "top" }}
                    placeholder="Enter Middle Name"
                  />
                  <div className="text-danger">
                    {this.validator.message(
                      "middleName",
                      this.state.middleName,
                      "alpha|min:1"
                    )}
                  </div>
                </div>
              </div>

              <div className="p-grid" style={styles.elementMargin}>
                <div className="p-col-8">
                  <InputText
                    style={styles.textbox}
                    id="lastName"
                    value={this.state.lastName}
                    onChange={(e) =>
                      this.setState({ lastName: e.target.value })
                    }
                    tooltip="Enter Last Name"
                    tooltipOptions={{ position: "top" }}
                    placeholder="Enter Last Name"
                  />
                  <div className="text-danger">
                    {this.validator.message(
                      "lastName",
                      this.state.lastName,
                      "alpha|required|min:2"
                    )}
                  </div>
                </div>
              </div>

              <div className="p-grid" style={styles.elementMargin}>
                <div className="p-col-4">
                  <InputText
                    style={styles.textbox}
                    id="phoneNo"
                    value={this.state.phoneNo}
                    onChange={(e) => this.setState({ phoneNo: e.target.value })}
                    tooltip="Enter Tel"
                    tooltipOptions={{ position: "top" }}
                    placeholder="Enter Tel"
                  />
                  <div className="text-danger">
                    {this.validator.message(
                      "phoneNo",
                      this.state.phoneNo,
                      "required|phone"
                    )}
                  </div>
                </div>
                <div className="p-col-4">
                  <InputText
                    style={styles.textbox}
                    id="emailAddress"
                    value={this.state.emailAddress}
                    onChange={(e) =>
                      this.setState({ emailAddress: e.target.value })
                    }
                    tooltip="Enter Email"
                    tooltipOptions={{ position: "top" }}
                    placeholder="Enter Email"
                  />
                  <div className="text-danger">
                    {this.validator.message(
                      "emailAddress",
                      this.state.emailAddress,
                      "email"
                    )}
                  </div>
                </div>
              </div>
              <div className="p-grid" style={styles.elementMargin}>
                <div className="p-col-4">
                  <Password
                    style={styles.textbox}
                    id="password"
                    feedback={false}
                    value={this.state.password}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                    tooltip="Enter Password"
                    tooltipOptions={{ position: "top" }}
                    placeholder="*********"
                  />
                  <div className="text-danger">
                    {this.validator.message("password", this.state.password, [
                      "min:8",
                      "password",
                    ])}
                  </div>
                </div>
                <div className="p-col-4">
                  <Password
                    style={styles.textbox}
                    id="repeatPassword"
                    feedback={false}
                    value={this.state.repeatPassword}
                    onChange={(e) =>
                      this.setState({ repeatPassword: e.target.value })
                    }
                    tooltip="Re-enter password"
                    tooltipOptions={{ position: "top" }}
                    placeholder="*********"
                  />
                  <div className="text-danger">
                    {this.validator.message(
                      "repeatPassword",
                      this.state.repeatPassword,
                      ["confirmpassword"]
                    )}
                  </div>
                </div>
              </div>
              <div className="p-grid" style={styles.elementMargin}>
                <div className="p-col-8">
                  <Button
                    label="Save"
                    disabled={this.state.processing}
                    type="submit"
                    className="p-button-raised"
                    style={styles.button}
                  />
                  {this.state.processing
                    ? spinnerService.show("loader")
                    : spinnerService.hide("loader")}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const styles = {
  button: {
    height: "40px",
  },
  textbox: {
    height: "40px",
  },
};

export default AddSystemUser;
