import { AlertTriangle, Wifi, WifiOff } from "lucide-react";
import React, { useEffect, useState } from "react";
import {
  ConnectionStatus,
  useInternetStatus,
} from "../hooks/useInternetStatus.ts";

const alertStyles: Record<
  ConnectionStatus,
  { bg: string; icon: React.ReactNode; message: string }
> = {
  offline: {
    bg: "bg-red-500",
    icon: <WifiOff className="h-5 w-5 text-white" />,
    message: "You are offline. Please check your internet connection.",
  },
  slow: {
    bg: "bg-yellow-500",
    icon: <AlertTriangle className="h-5 w-5 text-white" />,
    message: "Your internet connection is slow.",
  },
  online: {
    bg: "bg-green-500",
    icon: <Wifi className="h-5 w-5 text-white" />,
    message: "Your internet connection has been restored.",
  },
};

export function ConnectionAlert() {
  const status = useInternetStatus();
  const [show, setShow] = useState(false);
  const [lastStatus, setLastStatus] = useState<ConnectionStatus>(status);

  useEffect(() => {
    if (status !== lastStatus) {
      setShow(true);
      setLastStatus(status);
    }
  }, [status, lastStatus]);

  // Auto-hide the alert after 5 seconds for online status
  useEffect(() => {
    let sub = true;
    let timer;

    if (sub) {
      timer = setTimeout(() => setShow(false), 5000);
    }

    return () => {
      clearTimeout(timer);
      sub = false;
    };
  }, [show]);

  if (!show) return null;

  const { bg, icon, message } = alertStyles[status];

  return (
    <div
      className={`fixed bottom-4 left-4 ${bg} text-white px-4 py-3 rounded-lg shadow-lg flex items-center space-x-2 transition-all duration-300 ease-in-out`}
    >
      {icon}
      <span>{message}</span>
      <button
        onClick={() => setShow(false)}
        className="ml-4 hover:bg-white/20 rounded-full p-1"
      >
        <span className="sr-only">Close</span>
        <svg
          className="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  );
}
