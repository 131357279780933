import React from "react";

type Props = {
    headers: any[];
    onHeaderClick: (index: number) => any;
    activeIndex: number;
};

const TabbedHeaderVendor = ({
    headers,
    onHeaderClick,
    activeIndex,
}: Props) => {
    return (
        <div className="w-full">
            <div className="flex space-x-2 border-b">
                {headers.map((header, index) => (
                    <div
                        role="button"
                        onClick={onHeaderClick(index)}
                        className={`${activeIndex === index
                            ? "border-b-2 bg-slate-300 border-b-[--secondary_color]"
                            : "border-none"
                            } px-2 py-2 cursor-pointer`}
                        key={index}
                    >
                        <p
                            className={`${activeIndex !== index && "opacity-[0.6]"
                                } font-light text-base capitalize text-black`}
                        >
                            {header}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TabbedHeaderVendor;
