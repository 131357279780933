import url from "../env.json";

export class RemoteHelper {
  // uatServerUrl = "https://uat.seed.co.ug/seed-test/backend/SeedCore/ProcessRequest/";
  // uatServerUrl = "https://uat.seed.co.ug/seedtest/backend/SeedCore/ProcessRequest/";
  // uatServerUrl = "https://uat.seed.co.ug/seedtest/backend/SeedCore/ProcessRequest/";
  // serverUrl = "https://nepserv.co.ug/seedtest/backend/SeedCore/ProcessRequest/";
  // uatServerUrlUpload ="https://nepserv.co.ug/seedtest/backend/SeedCore/ProcessRequestWithUpload/";
  // "PANGISA_LOCAL_URL": "http://localhost:7001/Seed/backend/SeedCore/ProcessRequest/",
  // "PANGISA_UAT_URL": "https://uat.nepserv.co.ug/pangisa-test/backend/SeedCore/ProcessRequest/"

  serzverUrl = "";
  serverUrlUpload = "";

  constructor() {
    this.checkEnv();
  }

  async sendPostToServer(uri, data) {
    const httpHeaders = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: data, // body data type must match "Content-Type" header
    };

    return await fetch(this.serverUrl + uri, httpHeaders)
      .then((response) => response.json())
      .then((jsonResponse) => {
        const { returnCode } = jsonResponse;
        if (returnCode === 7777) {
          this.logDevMode(
            "RemoteHelper: Unauthorized access to " +
              uri +
              " - " +
              JSON.stringify(jsonResponse)
          );
          return {
            returnCode: 403,
            returnMessage: "Please login to complete request",
          };
        }
        return jsonResponse;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  sendPostWithUpload = async (uri, data) => {
    const httpHeaders = {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      body: data, // body data type must match "Content-Type" header
    };

    if (process.env.NODE_ENV === "production") {
      this.serverUrlUpload = url.PANGISA_PROD_URL;
    }
    console.log(process.env.NODE_ENV);

    return await fetch(this.serverUrlUpload + uri, httpHeaders)
      .then((response) => response.json())
      .then((responseData) => console.log(responseData))
      .catch((error) => console.error(error));
  };

  logDevMode(...data) {
    if (process.env.NODE_ENV !== "production") {
      console.log(JSON.stringify(data));
    }
  }

  extractData(res) {
    let body = res.json();
    return body || {};
  }

  checkEnv = () => {
    const urlDetection = window.location.host;

    if (urlDetection === "localhost:3006") {
      this.serverUrl = url.PANGISA_LOCAL_URL;
      this.serverUrlUpload = url.PANGISA_LOCAL_URL;
    } else if (urlDetection === "uat.nepserv.co.ug") {
      this.serverUrl = url.PANGISA_UAT_URL_INT;
      this.serverUrlUpload = url.PANGISA_UAT_URL_INT;
    } else if (urlDetection === "uat.nepserv.co.ug:4443") {
      this.serverUrl = url.PANGISA_UAT_URL_EXT;
      this.serverUrlUpload = url.PANGISA_UAT_URL_EXT;
    } else {
      this.serverUrl = url.PANGISA_PROD_URL;
      this.serverUrlUpload = url.PANGISA_PROD_URL;
    }
  };

  getPentaho(reportName, reportType, params) {
    if (process.env.NODE_ENV !== "production") {
      this.pentahoServerUrl = this.pentahoUatServerUrl;
    }
    return this.http.get(
      this.pentahoServerUrl + reportName + "/" + reportType,
      { params: params, responseType: "blob" }
    );
  }

  logAlert(event) {
    if (process.env.NODE_ENV !== "production") {
      if (event instanceof Object || event instanceof Array) {
        alert(JSON.stringify(event, null, 2));
        return;
      }
      alert(event);
    }
  }
}
