import { Trash } from "lucide-react";
import React, { useEffect, useState } from "react";
import { FaUpload } from "react-icons/fa";

const ImageUpload = ({ label, onChange, initialImage = "" }) => {
  const [image, setImage] = useState(initialImage || null);
  const [imageName, setImageName] = useState("");

  useEffect(() => {
    if (initialImage) {
      setImage(initialImage);
    }
  }, [initialImage]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
        setImageName(file.name);
        if (onChange) onChange(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
    setImageName("");
    if (onChange) onChange(null);
  };

  return (
      <div className="md:col-span-2 flex justify-center items-center">
        <div className="flex flex-col items-center justify-center p-8 border border-gray-300 rounded-lg shadow-md bg-white w-full">
          {!image ? (
              <>
                <h2 className="text-xl font-semibold mb-4">
                  {label || "Upload an Image"}
                </h2>
                <label
                    htmlFor="file-input"
                    className="flex items-center justify-center px-6 py-2 bg-[--primary-color] text-white rounded-lg cursor-pointer shadow hover:bg-[--primarylight-color] transition"
                >
                  <FaUpload className="mr-2" /> Upload Image
                </label>
                <input
                    id="file-input"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="hidden"
                />
              </>
          ) : (
              <div className="text-center">
                <h3 className="text-lg font-medium mb-2 text-[--primary-color]">Preview:</h3>
                <img
                    src={image}
                    alt="Uploaded Preview"
                    className="w-[300px] h-auto rounded-lg shadow-md mb-2"
                />
                <p className="text-gray-600 mb-2">{imageName}</p>
                <button
                    onClick={handleRemoveImage}
                    className="flex items-center px-4 py-2 text-red-600 rounded-lg shadow hover:bg-red-100 transition"
                >
                  <Trash className="mr-2" /> Remove Image
                </button>
              </div>
          )}
        </div>
      </div>
  );
};

export default ImageUpload;
