import React, { useContext, useLayoutEffect, useState } from "react";
import CustomSchoolPayTable from "../../../components/CustomComponents/Tables/CustomSchoolPayTable.tsx";
import TabbedHeaderComponent from "../../../components/CustomComponents/Tabs/TabbedHeaderComponent.tsx";
import { AppContext } from "../../../context/AppContext.js";
import { CommonComponent } from "../../../service/CommonComponent.js";
import { getCookie } from "../../../service/get-user.js";
import { X } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

type Props = {};

type RowItems = {
  id?: number;
  school_id?: number;
  vendor_id?: number;
  approval_status?: string;
  requested_on?: string;
  row_no?: number;
  school_name?: string;
  vendor_name?: string;
};

const vendorColumns = [
  { header: "Vendor Name", accessor: "vendor_name" },
  { header: "Email Address", accessor: "email" },
  { header: "School Name", accessor: "school_name" },
  { header: "School Code", accessor: "school_code" },
  { header: "Initiated By", accessor: "initiated_by" },
  { header: "Approval Status", accessor: "approval_status" },
  { header: "Requested On", accessor: "requested_on" },
];

const packageColumns = [
  { header: "Package Name", accessor: "package_name" },
  { header: "School Name", accessor: "school_name" },
  { header: "Class Name", accessor: "class_name" },
  { header: "School Code", accessor: "school_code" },
  { header: "School Type", accessor: "school_type" },
  { header: "Grand Price", accessor: "grandprice" },
  { header: "Mapping Status", accessor: "mapping_status" },
];
const SchoolColumns = [
  { header: "School Code", accessor: "school_code" as const },
  { header: "School Name", accessor: "school_name" as const },
  { header: "Package Price", accessor: "package_price" as const },
  { header: "Package Name", accessor: "package_name" as const },
  { header: "Package Start Date", accessor: "package_start_date" as const },
  { header: "Package End Date", accessor: "package_end_date" as const },
  { header: "Initiated By", accessor: "initiated_by" as const },
];

const auth = getCookie("loggedInUser");
const req = new CommonComponent();

const ApprovalModal = ({ isOpen, onClose, onConfirm, title }) => {
  const [comment, setComment] = useState("");

  if (!isOpen) return null;

  const handleConfirm = () => {
    onConfirm(comment);
    setComment(""); // Clear comment after submission
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md mx-4 transform transition-all">
        <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200">
          <h3 className="text-lg font-semibold text-[--school-pay-primary-color]">
            {title}
          </h3>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 transition-colors focus:outline-none"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="px-6 py-4">
          <div className="mb-4">
            <label
              htmlFor="comment"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Approval Comment or Reason
            </label>
            <textarea
              id="comment"
              rows={4}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-[--school-pay-primary-color] focus:border-[--school-pay-primary-color] sm:text-sm resize-none transition duration-150 ease-in-out"
              placeholder="Enter your approval comment..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
        </div>

        <div className="px-6 py-4 bg-gray-50 rounded-b-lg flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[--school-pay-primary-color] transition duration-150 ease-in-out"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            className="px-4 py-2 text-sm font-medium text-white bg-[--school-pay-primary-color] border border-transparent rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[--school-pay-primary-color] transition duration-150 ease-in-out"
          >
            Confirm Approval
          </button>
        </div>
      </div>
    </div>
  );
};

const SchoolMappingRequests = (props: Props) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [vendorData, setVendorData] = React.useState([]);
  const [packageData, setPackageData] = React.useState([]);
  const [SchoolPackageData, setSchoolPackageData] = useState<any[]>([]);

  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [pendingApproval, setPendingApproval] = useState<any>(null);
  const [approvalType, setApprovalType] = useState("");
  const [selectedVendorRows, setSelectedVendorRows] = useState([]);

  const { setLoading, setSnackAlert }: any = useContext(AppContext);
  const navigation = useNavigate();
  const { schoolCode } = useParams();

  const handleApprovalClick = (row, type) => {
    setPendingApproval(row);
    setApprovalType(type);
    setShowApprovalModal(true);
  };

  const handleApprovalConfirm = async (comment) => {
    if (approvalType === "package") {
      await handleApprovePackageMapping(pendingApproval, comment);
    } else if (approvalType === "school") {
      await SingleSchoolApproval(pendingApproval, comment);
    } else if (approvalType === "vendor") {
      // If we have a specific vendor row, use that, otherwise use all selected rows
      const rowsToApprove = pendingApproval
        ? [{ mappingId: pendingApproval.id }]
        : selectedVendorRows;

      await handleApproveVendorMapping(comment, rowsToApprove);
    } else if (approvalType === "School Package") {
      await approvePackageSchoolMapping(pendingApproval, comment);
    }
    setShowApprovalModal(false);
    setPendingApproval(null);
  };

  const fetchClassToPackageMapping = async () => {
    let postObject = {
      search: {
        schoolCode,
      },
    };
    setLoading(true);

    try {
      const res = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "getmapPackageToSchoolClasses",
        JSON.stringify(postObject)
      );

      if (res.returnCode === 200) {
        setLoading(false);
        setPackageData(res.returnData);
        return;
      } else {
        setLoading(false);
        setSnackAlert({
          open: true,
          severity: "error",
          message: res.returnMessage,
        });
        return;
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to fetch schools to be approved",
      });
      return;
    }
  };

  const handleApprovePackageMapping = async (row, comment) => {
    setLoading(true);

    let postObject = {
      auth,
      ids: [row.id],
      comment,
    };

    try {
      const res = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "approvePackageSchoolMapping",
        JSON.stringify(postObject)
      );

      const { returnCode, returnMessage } = res;

      if (returnCode === 200) {
        setSnackAlert({
          open: true,
          severity: "success",
          message: returnMessage,
        });
        setTimeout(() => {
          fetchClassToPackageMapping();
        }, 3000);
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: "Failed to approve school mapping",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to approve school mapping",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchVendorsToBeApproved = async () => {
    setLoading(true);

    try {
      const res = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "getAllVendorMappingRequests",
        JSON.stringify({
          auth,
          search: { status: "Not Approved", schoolCode, initiator: "vendor" },
        })
      );

      if (res.returnCode === 200) {
        setLoading(false);
        setVendorData(res.returnData.rows);
        return;
      } else {
        setLoading(false);
        setSnackAlert({
          open: true,
          severity: "error",
          message: "Failed to fetch vendors to be approved",
        });
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to fetch vendors to be approved",
      });
    }
  };

  useLayoutEffect(() => {
    let sub = true;

    if (sub) {
      fetchVendorsToBeApproved();
      fetchClassToPackageMapping();
      fetchSchooPackageMapping();
    }

    return () => {
      sub = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectVendorRow = (item: RowItems) => {
    setSelectedVendorRows((prev: any) => {
      const exists = prev.some((row) => row.mappingId === item.id);
      if (!exists) {
        return [...prev, { mappingId: item.id }];
      }
      return prev;
    });
  };

  const handleApproveVendorMapping = async (comment, rowsToApprove) => {
    setLoading(true);

    if (!rowsToApprove || rowsToApprove.length === 0) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Please select at least one vendor to approve.",
      });
      return;
    }

    try {
      const res = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "approveVendorMapping",
        JSON.stringify({ ids: rowsToApprove, comment })
      );

      const { returnCode, returnMessage } = res;

      if (returnCode === 200) {
        setSnackAlert({
          open: true,
          severity: "success",
          message: returnMessage,
        });

        // Clear selected rows and refresh the data
        setSelectedVendorRows([]);
        fetchVendorsToBeApproved();
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: "Failed to approve vendor mapping",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to approve vendor mapping",
      });
    } finally {
      setLoading(false);
    }
  };

  const [selectedSchoolRowData, setSelectedSchoolRowData] = useState([]);

  const handleSelectSchoolRowData = (item: RowItems) => {
    setSelectedSchoolRowData((prev: any) => {
      const exists = prev.some((row) => row.mappingId === item.id);
      if (!exists) {
        return [...prev, { mappingId: item.id }];
      }
      return prev;
    });
  };

  const approvePackageSchoolMapping = async (rows, comment) => {
    let postObject = {
      status: "Approved",
      schoolPackageId: rows.id,
      comment,
    };

    try {
      setLoading(true);
      const res = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "approvePackageSchools",
        JSON.stringify(postObject)
      );
      const { returnCode, returnMessage } = res;
      if (returnCode === 200) {
        setLoading(false);
        setSnackAlert({
          open: true,
          severity: "success",
          message: returnMessage,
        });
        setTimeout(() => {
          fetchSchooPackageMapping();
        }, 3000);
      } else {
        setLoading(false);
        setSnackAlert({
          open: true,
          severity: "error",
          message: returnMessage,
        });
      }
    } catch (error) {
      setLoading(false);

      setSnackAlert({
        open: true,
        severity: "error",
        message: "Error Ocuur: " + error.message,
      });
    }
  };

  const fetchSchooPackageMapping = async () => {
    let postObject = {
      search: {
        schoolCode,
        status: "Awaiting Review",
        initiator: "vendor",
      },
    };
    setLoading(true);

    try {
      const res = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "getPackageSchoolMapping",
        JSON.stringify(postObject)
      );

      if (res.returnCode === 200) {
        setLoading(false);
        setSchoolPackageData(res.returnData);
        return;
      } else {
        setLoading(false);
        setSnackAlert({
          open: true,
          severity: "error",
          message: res.returnMessage,
        });
        return;
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to fetch schools to be approved",
      });
      return;
    }
  };

  const SingleSchoolApproval = async (row, comment) => {
    handleSelectSchoolRowData(row);
    handleApproveSchoolMapping(comment);
  };

  const handleApproveSchoolMapping = async (comment) => {
    setLoading(true);

    if (selectedSchoolRowData.length === 0) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Please select at least one school to approve.",
      });
      return;
    }

    try {
      const res = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "approveSchoolMapping",
        JSON.stringify({ ids: selectedSchoolRowData, comment })
      );

      const { returnCode, returnMessage } = res;

      if (returnCode === 200) {
        setSnackAlert({
          open: true,
          severity: "success",
          message: returnMessage,
        });

        setSelectedSchoolRowData([]);
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: "Failed to approve school mapping",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to approve school mapping",
      });
    } finally {
      setLoading(false);
    }
  };

  const renderComponent = () => {
    if (activeIndex === 0) {
      return (
        <CustomSchoolPayTable
          selectable={false}
          columns={vendorColumns}
          data={vendorData}
          filters={[]}
          onSelectRow={(item) => handleSelectVendorRow(item)}
          actionComponent={(item) => {
            return (
              <div className="flex space-x-2 justify-evenly py-2 z-10">
                {item.status === "Not Approved" ? (
                  <button
                    onClick={
                      () => handleApprovalClick(item, "vendor")

                      // navigation("/schoolpay-Packageinfor/" + item.id)
                    }
                    className="text-green-500 font-extrabold bg-green-100 px-2 py-1 rounded-lg"
                  >
                    Approve Vendor
                  </button>
                ) : (
                  <button
                    onClick={() => alert("pending")}
                    className="text-red-500 font-extrabold bg-red-100 px-2 py-1 rounded-lg"
                  >
                    Reject
                  </button>
                )}
              </div>
            );
          }}
        />
      );
    } else if (activeIndex === 1) {
      return (
        <CustomSchoolPayTable
          selectable={false}
          columns={packageColumns}
          data={packageData}
          filters={[]}
          actionComponent={(item) => {
            return (
              <div className="flex space-x-2 justify-evenly py-2 z-10">
                {item.mapping_status === "Awaiting Review" ? (
                  <button
                    onClick={
                      () => navigation("/school-Packageinfor/" + item.id)

                      // navigation("/schoolpay-Packageinfor/" + item.id)
                    }
                    className="text-green-500 font-extrabold bg-green-100 px-2 py-1 rounded-lg"
                  >
                    Approve School
                  </button>
                ) : (
                  <button
                    onClick={() => alert("pending")}
                    className="text-red-500 font-extrabold bg-red-100 px-2 py-1 rounded-lg"
                  >
                    Reject
                  </button>
                )}
              </div>
            );
          }}
        />
      );
    } else if (activeIndex === 2) {
      return (
        <CustomSchoolPayTable
          selectable={false}
          columns={SchoolColumns}
          data={SchoolPackageData}
          filters={[]}
          actionComponent={(item) => {
            return (
              <div className="flex space-x-2 justify-evenly py-2 z-10">
                {item.mapping_status === "Awaiting Review" || "active" ? (
                  <button
                    onClick={
                      () => handleApprovalClick(item, "School Package")

                      // navigation("/schoolpay-Packageinfor/" + item.id)
                    }
                    className="text-green-500 font-extrabold bg-green-100 px-2 py-1 rounded-lg"
                  >
                    Approve School
                  </button>
                ) : (
                  <button
                    onClick={() => alert("pending")}
                    className="text-red-500 font-semibold"
                  >
                    Decline
                  </button>
                )}
              </div>
            );
          }}
        />
      );
    }
  };

  return (
    <div className="p-4">
      <div className="py-3 ">
        <h3 className="lg:text-3xl md:text-2xl sm:text-lg font-light transition ease duration-500">
          Mapping Requests
        </h3>
      </div>

      <div className="my-3">
        <TabbedHeaderComponent
          headers={[
            "Vendor Requests to Schools",
            "Package Requests to Class",
            "Package Requests to School",
          ]}
          activeIndex={activeIndex}
          onHeaderClick={(index) => () => setActiveIndex(index)}
        />
      </div>
      <div className="py-4">{renderComponent()}</div>

      <ApprovalModal
        isOpen={showApprovalModal}
        onClose={() => setShowApprovalModal(false)}
        onConfirm={handleApprovalConfirm}
        title={`Approve ${
          approvalType === "package"
            ? "School Package"
            : approvalType === "school"
            ? "School Vendor"
            : "Vendor School"
        } Mapping`}
      />
    </div>
  );
};

export default SchoolMappingRequests;
