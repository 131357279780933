export const shoppingCartConstants = {
  ADD_TO_CART: "ADD_TO_CART_REQUEST",
  UPDATE_STEP_ON_CHECKOUT: "UPDATE_STEP_ON_CHECKOUT",
  SET_STEP: "SET_STEP",
  SET_SHIPPING_ADDRESS: "SET_SHIPPING_ADDRESS",
  SET_BILLING_ADDRESS: "SET_BILLING_ADDRESS",
  SET_SAME_AS_BILLING: "SET_SAME_AS_BILLING",
  SET_DELIVERY_METHOD: "SET_DELIVERY_METHOD",
  SET_PICKUP_STATION: "SET_PICKUP_STATION",
  SET_PAYMENT_METHOD: "SET_PAYMENT_METHOD",
  SET_EDITING_SHIPPING: "SET_EDITING_SHIPPING",
  SET_EDITING_BILLING: "SET_EDITING_BILLING",
  RESET_CHECKOUT: "RESET_CHECKOUT",
  CLEAR_REDUX_STATE: "CLEAR_REDUX_STATE",
  CLEAR_CART: "CLEAR_CART",
};
