import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, Theme } from "@material-ui/core/styles";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import React from "react";

type Props = {
  // onClick: () => void;
  onClose: (event?: React.SyntheticEvent, reason?: string) => void;
  open: boolean;
  message: string;
  severity: 'error' | 'warning' | 'info' | 'success';
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const SnackBarAlert = (props: Props) => {
  const classes = useStyles();

  // const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpen(false);
  // };
  return (
    <div className={classes.root}>
      {/* <Button variant="outlined" onClick={props.onClick}>
        Open success snackbar
      </Button> */}
      <Snackbar
        open={props.open}
        autoHideDuration={6000}
        onClose={props.onClose}
      >
        <Alert
          onClose={props.onClose}
          severity={props.severity}
        >
          {props.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackBarAlert;
