import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import React from "react";

type BreadcrumbItem = {
    label: string;
    route?: any;
};

type BreadcrumbsProps = {
    breadcrumbs: BreadcrumbItem[];
    heading?: string; // New heading prop
};

const CustomBreadCrumb = ({
                              breadcrumbs,
                              heading = breadcrumbs[breadcrumbs.length - 1]?.label // Default to last breadcrumb
                          }: BreadcrumbsProps) => {
    return (
        <div className="w-full mb-4 mx-2 flex flex-col items-start justify-between">
            {/* Breadcrumbs Section */}
            <div className="capitalize text-xs flex-grow">
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    {breadcrumbs.map((breadcrumb, index) => {
                        const isLast = index === breadcrumbs.length - 1;

                        if (isLast) {
                            return (
                                <Typography
                                    key={index}
                                    color="textSecondary"
                                    className="py-1 no-underline"
                                >
                                    {breadcrumb.label}
                                </Typography>
                            );
                        }

                        return (
                            <Link
                                key={index}
                                color="initial"
                                onClick={() => breadcrumb.route?.()}
                                className="rounded-lg no-underline cursor-pointer text-base hover:text-gray-700 text-gray-900"
                            >
                                {breadcrumb.label}
                            </Link>
                        );
                    })}

                </Breadcrumbs>
            </div>
            {/* Heading Section */}
            <div className={'mb-2'}>
                {heading && (
                    <h3 className="text-lg font-semibold text-gray-700">
                        {heading}
                    </h3>
                )}
            </div>
        </div>
    );
};

export default CustomBreadCrumb;