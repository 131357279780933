import React, { useEffect, useState } from "react";

import { ListDataTypes } from "../../components/CustomComponents/Mappings/CustomMappingComponent.tsx";
import CustomVendorMaping from "../../components/CustomComponents/Mappings/CustomVendorMaping.tsx";
import TabbedHeaderVendor from "../../components/CustomComponents/Tabs/TabbedHeaderVendor.tsx";
import { AppContext } from "../../context/AppContext.js";
import { CommonComponent } from "../../service";
import { columns } from "./index.js";

const VendorMapping = () => {
  let listData = [];
  let schools = [];

  const [activeIndex, setActiveIndex] = useState(0);
  const [, setVendors] = React.useState([]);

  const [data, setData] = React.useState<any>([]);
  const [vendorData, setVendorData] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState<any[]>([]);
  const [schoolId, setSchoolId] = React.useState<number>();
  const [schoolData, setSchoolData] = React.useState([]);

  useEffect(() => {
    console.log(schoolData);

    getAllActiveVendors();
    getAllEnrolledSchools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const req = new CommonComponent();
  const { setLoading, setSnackAlert } = React.useContext(AppContext);

  const handleSelectItem = (item: ListDataTypes) => {
    setSelectedItem([item]);
  };

  const handleDeselectItem = () => {
    setSelectedItem([]);
  };

  const handleSubmit = async (e: Event) => {
    e.preventDefault();
    if (
      selectedItem === null ||
      selectedItem === undefined ||
      selectedItem.length === 0
    ) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Please select a list item to proceed.",
      });
      return;
    }

    const postObject = {
      vendorId: selectedItem[0].id,
      schoolId,
    };

    try {
      setLoading(true);

      const { returnCode, returnMessage } = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "mapVendorToSchoolWithoutSession",
        JSON.stringify(postObject)
      );

      switch (returnCode) {
        case 200:
          setLoading(false);

          setSnackAlert({
            open: true,
            severity: "success",
            message: returnMessage,
          });

          // change route
          setTimeout(() => {
            // history.push("/enroll_school");
            setSnackAlert({ open: false, severity: "success", message: "" });
          }, 5000);
          break;
        case 409:
          setLoading(false);

          setSnackAlert({
            open: true,
            severity: "error",
            message: returnMessage,
          });

          // change route
          setTimeout(() => {
            // history.push("/enroll_school");
            setSnackAlert({ open: false, severity: "error", message: "" });
          }, 5000);

          break;
        case 404:
          setLoading(false);

          setSnackAlert({
            open: true,
            severity: "error",
            message: returnMessage,
          });

          // change route
          setTimeout(() => {
            // history.push("/enroll_school");
            setSnackAlert({ open: false, severity: "error", message: "" });
          }, 5000);
          break;
        default:
          setLoading(false);

          setSnackAlert({
            open: true,
            severity: "error",
            message: "Failed to submit form.",
          });

          // change route
          setTimeout(() => {
            // history.push("/enroll_school");
            setSnackAlert({ open: false, severity: "error", message: "" });
          }, 5000);

          break;
      }
    } catch (e) {
      setLoading(false);

      console.warn(e);

      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to submit form.",
      });
    }
  };

  const getAllEnrolledSchools = async () => {
    setLoading(true);

    const postObject = {
      search: {},
    };
    try {
      const response: Promise<any> = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "getEnrolledSchools",
        JSON.stringify(postObject)
      );

      const { returnCode, returnData }: any = response;

      if (returnCode === 200) {
        setLoading(false);

        schools = returnData.map((item: any) => ({
          id: item.id,
          title: item.school_name,
          description: item.school_type,
        }));

        setSchoolData(schools);
        setData(returnData.map((i) => i));
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: "We're Having Trouble Retrieving Enrolled Schools",
        });

        // change route
        setTimeout(() => {
          // history.push("/enroll_school");
          setSnackAlert({ open: false, severity: "error", message: "" });
        }, 5000);
      }
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const getAllActiveVendors = async () => {
    setLoading(true);

    const postObject = {
      search: {},
    };
    try {
      const response: Promise<any> = await req.sendRequestToServer(
        "ClientManagement",
        "getClientWithoutSession",
        JSON.stringify(postObject)
      );

      const { returnCode, returnData }: any = response;

      if (returnCode === 200) {
        setLoading(false);

        listData = returnData.rows.map((item: any) => ({
          id: item.id,
          title: item.user_name,
          description: item.email_address,
        }));

        setVendors(returnData.rows);
        setVendorData(listData);
      } else {
        setLoading(false);

        setSnackAlert({
          open: true,
          severity: "error",
          message: "We're Having Trouble Retrieving Enrolled Schools",
        });

        // change route
        setTimeout(() => {
          // history.push("/enroll_school");
          setSnackAlert({ open: false, severity: "error", message: "" });
        }, 5000);
      }
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const renderComponent = () => {
    switch (activeIndex) {
      case 0:
        return (
          <div>
            <CustomVendorMaping
              title="Vendor Mapping"
              description="This is the default mapping description"
              columns={columns}
              loading={false}
              data={data}
              listData={vendorData}
              rout={{ id: "0", name: "/" }}
              submit={{ label: "Submit", onClick: (e) => handleSubmit(e) }}
              onSelectItem={(item) => handleSelectItem(item)}
              selectedItem={selectedItem}
              onDeselectItem={() => handleDeselectItem()}
              onSelectTableItem={(item) => setSchoolId(item.id)}
            />
          </div>
        );
      case 1:

      // eslint-disable-next-line no-fallthrough
      default:
        return <div>Loading...</div>;
    }
  };

  return (
    <div className="p-4 sticky">
      <div className="py-3 px-2">
        <h3 className="lg:text-3xl md:text-2xl sm:text-lg font-light transition ease duration-500">
          Vendor Mapping Management
        </h3>
      </div>

      <div className="my-3">
        <TabbedHeaderVendor
          headers={["Map Vendor To School"]}
          activeIndex={activeIndex}
          onHeaderClick={(index) => () => setActiveIndex(index)}
        />
      </div>
      <div className="my-3 py-1">{renderComponent()}</div>
    </div>
  );
};

export default VendorMapping;
