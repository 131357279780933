import { CheckCircle, Plus, StopCircle, UserRoundCheck } from "lucide-react";
import React, { useEffect, useMemo, useRef, useState } from "react";

interface TableProps<T> {
  data: T[];
  columns: { header: string; accessor: any }[];
  Edit?: (row: T) => void;
  EditActionLable: string;
  renderCell?: (accessor: keyof T, row: T) => React.ReactNode;
  Add?: (row: T) => void;
  create?: { label: string; onclick: () => void };
  ActiveStatus?: any;
  inActiveStatus?: any;
  filters?: { label: string; value: string; filter: string }[];
  onApproval?: (selectedRows: T[]) => void;
  onSelectRow?: (rowData: T[]) => void;
  showIndex?: boolean;
  showCheckbox?: boolean;
  rownavigation?: (row: T) => void;
}

const CustomSelectableTable = <T,>({
  data,
  columns,
  Edit,
  renderCell,
  Add,
  create,
  filters,
  onApproval,
  onSelectRow,
  showIndex = true,
  showCheckbox = true,
  rownavigation,
}: TableProps<T>) => {
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [paginatedData, setPaginatedData] = useState<T[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const [dropdownDirection, setDropdownDirection] = useState<string>("down");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [activeFilter, setActiveFilter] = useState<string>("All");
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const dropdownRefs = useRef<(HTMLDivElement | null)[]>([]);

  // Filter and search logic
  const filteredData = useMemo(() => {
    return data.filter((item: any) => {
      // Search filter
      const searchMatch = Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      );

      // Category filter (applies to all columns now)
      const filterMatch =
        activeFilter === "All" ||
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(activeFilter.toLowerCase())
        );
      return searchMatch && filterMatch;
    });
  }, [data, searchQuery, activeFilter]);

  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const newPaginatedData = filteredData.slice(start, end);
    setPaginatedData(newPaginatedData);
    setTotalPages(Math.ceil(filteredData.length / itemsPerPage));
  }, [currentPage, itemsPerPage, filteredData]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRefs.current.some(
          (ref, index) =>
            ref && dropdownOpen === index && !ref.contains(event.target as Node)
        )
      ) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  const toggleDropdown = (rowIndex: number) => {
    setDropdownOpen(dropdownOpen === rowIndex ? null : rowIndex);

    const rowRef = dropdownRefs.current[rowIndex];
    if (rowRef) {
      const rect = rowRef.getBoundingClientRect();
      const shouldOpenUp = window.innerHeight - rect.bottom < 150;
      setDropdownDirection(shouldOpenUp ? "up" : "down");
    }
  };

  // Exclude the 'id' column from being displayed
  const filteredColumns = columns.filter((column) => column.accessor !== "id");

  const handleFilterButtonClick = (filter: string) => {
    setActiveFilter(filter);
    setCurrentPage(1); // Reset to the first page when filter changes
  };

  const handleSelectRow = (rowIndex: number) => {
    const newSelectedRows = new Set(selectedRows);
    if (newSelectedRows.has(rowIndex)) {
      newSelectedRows.delete(rowIndex);
    } else {
      newSelectedRows.add(rowIndex);
    }
    setSelectedRows(newSelectedRows);

    // Notify parent component with the selected rows' data
    const selectedRowData = Array.from(newSelectedRows).map(
      (index) => data[index]
    );
    onSelectRow && onSelectRow(selectedRowData);
  };

  const handleSelectAllRows = () => {
    if (selectAll) {
      setSelectedRows(new Set());
      onSelectRow && onSelectRow([]);
    } else {
      const allRowIndices = new Set(
        paginatedData.map(
          (_, rowIndex) => (currentPage - 1) * itemsPerPage + rowIndex
        )
      );
      setSelectedRows(allRowIndices);

      // Notify parent component with all selected rows' data
      const selectedRowData = Array.from(allRowIndices).map(
        (index) => data[index]
      );
      onSelectRow && onSelectRow(selectedRowData);
    }
    setSelectAll(!selectAll);
  };

  return (
    <div className="bg-white rounded-xl">
      <div className="bg-white h-full py-2 mx-2 rounded-3xl -mt-4">
        {/* Top Panel */}
        <div className="flex flex-row justify-between mb-2 py-2">
          <select
            value={activeFilter}
            onChange={(e) => handleFilterButtonClick(e.target.value)}
            className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[--primary-color]"
          >
            <option value="" disabled>
              {" "}
              Select Status
            </option>
            {filters &&
              filters.map((status) => (
                <option key={status.value} value={status.filter}>
                  {status.label}
                </option>
              ))}
          </select>

          <div className="flex space-x-2 px-4">
            <input
              type="text"
              className="px-3 rounded-md border border-gray-300 text-xs"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button
              className="bg-[--primary-color] text-white px-2 rounded-md text-xs font-extrabold shadow-md uppercase transition-colors duration-300 cursor-pointer hover:shadow-none"
              onClick={() => {
                if (create?.onclick) {
                  create.onclick();
                }
              }}
              title={create?.label} // Tooltip on hover
            >
              <Plus size={22} />
            </button>
          </div>
        </div>

        {/* Selected Rows Count */}

        {showCheckbox && (
          <div className="flex items-center px-4 pb-2 space-x-4">
            <span className="text-sm text-gray-600">
              {selectedRows.size} {selectedRows.size === 1 ? "item" : "items"}{" "}
              selected
            </span>

            {onApproval && selectedRows.size > 0 && (
              <button
                className="bg-[--secondary_color] hover:opacity-[.8] text-white p-2 rounded-lg font-bold text-base shadow-sm capitalize transition-colors duration-300 cursor-pointer hover:shadow-none"
                onClick={() =>
                  onApproval(
                    Array.from(selectedRows).map((rowIndex) => data[rowIndex])
                  )
                }
              >
                Approve Item{selectedRows.size === 1 ? "" : "s"}
              </button>
            )}
          </div>
        )}

        <div className="overflow-x-auto bg-white rounded-lg max-h-[400px] min-h-[350px]">
          <table className="min-w-full bg-white text-xs">
            <thead className="bg-gray-100 text-gray-700 w-full">
              <tr className="bg-[--secondary_color] text-white">
                {showCheckbox && (
                  <th className="py-2 px-4 text-left">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllRows}
                    />
                  </th>
                )}

                {showIndex && <th className="py-2 px-4 text-left">No.</th>}
                {filteredColumns.map((column, index) => (
                  <th key={index} className="py-2 px-4 text-left font-semibold">
                    {column.header}
                  </th>
                ))}
                <th className="py-2 px-4 text-left">Action</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 mb-10">
              {paginatedData.length > 0 ? (
                paginatedData.map((row: any, rowIndex) => {
                  const globalIndex =
                    (currentPage - 1) * itemsPerPage + rowIndex;
                  return (
                    <tr
                      role="button"
                      key={rowIndex}
                      className="border-b min-h-[430px] hover:bg-slate-100"
                      onClick={() => rownavigation && rownavigation(row)}
                    >
                      {showCheckbox && (
                        <td className="py-1 px-4">
                          <input
                            type="checkbox"
                            checked={selectedRows.has(globalIndex)}
                            onChange={() => handleSelectRow(globalIndex)}
                          />
                        </td>
                      )}
                      {showIndex && (
                        <td className="py-1 px-4">{globalIndex + 1}</td>
                      )}
                      {filteredColumns.map((column, colIndex) => (
                        <td key={colIndex} className="py-1 px-4">
                          {renderCell ? (
                            renderCell(column.accessor, row)
                          ) : column.header.includes("Status") ? (
                            <span
                              className={
                                row[column.accessor] === "Rejected"
                                  ? "text-red-600 bg-red-100 px-2 py-1 rounded-lg"
                                  : row[column.accessor] === "Approved"
                                  ? "text-green-600"
                                  : row[column.accessor] === "Awaiting Approval"
                                  ? "text-[--primary-color] bg-orange-100 px-2 py-1 rounded-lg"
                                  : row[column.accessor] === "Active"
                                  ? "text-green-600  bg-green-100 px-2 py-1 rounded-lg"
                                  : ""
                              }
                            >
                              {(row as any)[column.accessor]}
                            </span>
                          ) : (
                            (row as any)[column.accessor]
                          )}
                        </td>
                      ))}
                      <td className="py-1 px-4">
                        <div className="relative">
                          <button
                            className="bg-slate-200 text-black py-2 px-2 rounded-md transition-colors duration-300 cursor-pointer hover:shadow-none"
                            onClick={() => toggleDropdown(rowIndex)}
                          >
                            <UserRoundCheck size={16} />
                          </button>

                          {/* Action Dropdown */}
                          {dropdownOpen === rowIndex && (
                            <div
                              ref={(el) =>
                                (dropdownRefs.current[rowIndex] = el)
                              }
                              className={`absolute ${
                                dropdownDirection === "up"
                                  ? "bottom-8"
                                  : "top-8"
                              } right-0 mt-2 bg-gray-300 rounded-lg shadow-lg z-10`}
                            >
                              <div className="">
                                {Edit && (
                                  <div
                                    className="flex items-center space-x-2 py-2 px-4 cursor-pointer hover:bg-gray-400 rounded-lg"
                                    onClick={() => Edit && Edit(row)}
                                  >
                                    {row.status === "Approved" ? (
                                      <StopCircle size={14} />
                                    ) : (
                                      <CheckCircle
                                        size={14}
                                        className="text-green-600"
                                      />
                                    )}
                                    <span
                                      className={`${
                                        row.status === "Awaiting Approval"
                                          ? "text-green-600"
                                          : "text-red-600"
                                      }`}
                                    >
                                      {row.status === "Awaiting Approval"
                                        ? "Approve"
                                        : "Decline"}
                                    </span>
                                  </div>
                                )}
                                {Add && (
                                  <div
                                    className="flex items-center space-x-2 p-2 cursor-pointer hover:bg-gray-100"
                                    onClick={() => Add(row)}
                                  >
                                    <Plus size={14} />
                                    <span className="text-xs">Add</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={columns.length + 2}
                    className="py-2 px-4 text-center text-gray-500"
                  >
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center px-4 py-2">
          <span className="text-sm text-gray-600">
            Page {currentPage} of {totalPages}
          </span>

          <div className="flex items-center space-x-2">
            <select
              className="px-3 rounded-md border border-gray-300 text-xs"
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>

            <button
              className="px-3 py-1 text-sm text-gray-600 bg-gray-200 rounded-md shadow-md hover:bg-gray-300"
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            >
              Previous
            </button>

            <button
              className="px-3 py-1 text-sm text-gray-600 bg-gray-200 rounded-md shadow-md hover:bg-gray-300"
              disabled={currentPage === totalPages}
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomSelectableTable;
