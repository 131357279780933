import React from "react";

export class ErrorBoundary extends React.Component {
  constructor(params: any) {
    super(params);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    logErrorToMyService(error, errorInfo.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="w-screen h-screen flex items-center justify-center bg-white">
          <div className="w-[65%] mx-auto border rounded-md hover:bg-slate-200 transition-all ease-out duration-300 px-4 py-8">
            <h1 className="text-xl text-[tomato] capitalize">
              An error occurred.
            </h1>
            <p>Please check your internet connection and try again.</p>
            <p>
              If the error persists, please contact our support team for further
              assistance.
            </p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

function logErrorToMyService(error: any, componentStack: any) {
  console.debug("Function not implemented.");
}
