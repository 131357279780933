import { Component } from "react";
import { CommonComponent } from "./CommonComponent";

class ReferenceDataManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.commonComponent = new CommonComponent();
  }

  getFarmerCategories = (requestData) => {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getFarmerCategories",
        JSON.stringify(requestData)
      )
      .then((response) => {
        return response;
      });
  };

  getFarmerSubCategories = (categoryId, auth) => {
    const postObject = {
      auth,
      requestData: { categoryId },
    };

    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getFarmerSubCategories",
        JSON.stringify(postObject)
      )
      .then((response) => {
        return response;
      });
  };

  getPaymentChannelsRefData(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getPaymentChannelsRefData",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getBanks(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getBanks",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getRegions(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getRegions",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getTitles(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getTitles",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getEducationLevels(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getEducationLevelRefData",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getProfessions(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getProfessionRefData",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getIndustrySubSectors(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getIndustrySubSectors",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getMonthlyIncomeRefData(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getMonthlyIncomeRefData",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getPropertyRefData(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getPropertyRefData",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getPropertyOwnershipRefData(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getPropertyOwnershipRefData",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getProductionUnitsRefData(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getProductionUnitsRefData",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getProductionScaleRefData(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getProductionScaleRefData",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getIndustrySubSectorTypes(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getIndustrySubSectorTypes",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getDisabilityStatusRefData(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getDisabilityStatusRefData",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getCustomerRelationshipRefData(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getCustomerRelationshipRefData",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getIdentityTypes(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getIdentityTypes",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getIndustries(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getIndustries",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getCountries(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getCountryRefData",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getMaritalStatus(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getMaritalStatusRefData",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getDistricts(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getDistricts",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getCounties(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getCounties",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getSubCounties(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getSubCounties",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getParishes(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getParishes",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getIndustrySubCategoryType(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getSubSectors",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getBusinessRegistrationAuth(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getBusinessRegistrationAuth",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getRegistrationBusinessBody(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getRegistrationBusinessBody",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getEmployeeRangeRefData(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getEmployeeRangeRefData",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }

  getAccountTypesRefData(requestData) {
    return this.commonComponent
      .sendRequestToServer(
        "ReferenceDataManagement",
        "getAccountTypesRefData",
        JSON.stringify(requestData),
        true,
        null
      )
      .then((data) => {
        return data;
      });
  }
}

export default ReferenceDataManagement;
