
import SnackBarAlert from "../../../components/CustomComponents/Alerts/SnackBarAlert.tsx";
import TabbedHeaderComponent from "../../../components/CustomComponents/Tabs/TabbedHeaderComponent.tsx";
import React, { useState } from "react";
import VendorShool from "./VendorShool.tsx";
import PackageToSchool from "./PackageToSchool.tsx";

type Props = {};

const HEADERS = ["Map Vendor To School", "Map Package to School"];

const VendorMappingManagement = (props: Props) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [msg, setMsg] = useState<any>({
    show: false,
    severity: "error",
    message: "",
  });

  const renderComponent = () => {
    switch (activeIndex) {
      case 0:
        return (
          <div>
            <VendorShool />
          </div>
        );
      case 1:
        return (
          <div>
            <PackageToSchool />
          </div>
        );

      default:
        return <div>No component found</div>;
    }
  };
  // @ts-ignore
  return (
    <>
      <div className="p-4 sticky">
        <div className="py-2 px-2">
          <h3 className="lg:text-3xl md:text-2xl sm:text-lg font-light transition ease duration-500">
            Mapping Management
          </h3>
        </div>

        <div className="my-3">
          <TabbedHeaderComponent
            headers={HEADERS}
            activeIndex={activeIndex}
            onHeaderClick={(index) => () => {
              setActiveIndex(index);
            }}
          />
        </div>
        <div className="my-3 py-1">{renderComponent()}</div>
      </div>
      <SnackBarAlert
        open={msg.show}
        onClose={() =>
          setMsg({
            show: false,
            message: "",
            severity: "",
          })
        }
        message={msg.message}
        severity={msg.severity}
      />
    </>
  );
};

export default VendorMappingManagement;
