import { shoppingCartConstants } from "../constants";

export function addToCart(payload) {
  return { type: shoppingCartConstants.ADD_TO_CART, payload };
}

export const updateStepOnCheckout = (payload) => {
  return {
    type: shoppingCartConstants.UPDATE_STEP_ON_CHECKOUT,
    payload: payload,
  };
};

export const checkoutActions = {
  setStep: (step: number) => ({
    type: shoppingCartConstants.SET_STEP,
    payload: step,
  }),
  setShippingAddress: (address: any) => ({
    type: shoppingCartConstants.SET_SHIPPING_ADDRESS,
    payload: address,
  }),
  setBillingAddress: (address: any) => ({
    type: shoppingCartConstants.SET_BILLING_ADDRESS,
    payload: address,
  }),
  setSameAsBilling: (value: boolean) => ({
    type: shoppingCartConstants.SET_SAME_AS_BILLING,
    payload: value,
  }),
  setDeliveryMethod: (method: "pickup" | "delivery") => ({
    type: shoppingCartConstants.SET_DELIVERY_METHOD,
    payload: method,
  }),
  setPickupStation: (station: string) => ({
    type: shoppingCartConstants.SET_PICKUP_STATION,
    payload: station,
  }),
  setPaymentMethod: (method: string) => ({
    type: shoppingCartConstants.SET_PAYMENT_METHOD,
    payload: method,
  }),
  setEditingShipping: (value: boolean) => ({
    type: shoppingCartConstants.SET_EDITING_SHIPPING,
    payload: value,
  }),
  setEditingBilling: (value: boolean) => ({
    type: shoppingCartConstants.SET_EDITING_BILLING,
    payload: value,
  }),
  resetCheckout: (value: number) => ({
    type: shoppingCartConstants.RESET_CHECKOUT,
    payload: value,
  }),
};

export const clearReduxState = () => {
  return {
    type: shoppingCartConstants.CLEAR_REDUX_STATE,
  };
};
