// import { Cookies } from "react-cookie";
import { routings } from "../routes/routings.ts";

// const cookie = new Cookies();

export function setCookie(cookiename, value) {
  // logAlert("Setting cookie: " + cookiename + " = " + JSON.stringify(value));
  localStorage.setItem(cookiename, JSON.stringify(value));
}

export function getCookie(cookiename) {
  const data = localStorage.getItem(cookiename);

  if (!data) {
    // logAlert("No data found for: " + cookiename);
    return null;
  }

  try {
    const parsedData = JSON.parse(data);
    // logAlert("Parsed data: " + JSON.stringify(parsedData));
    return parsedData;
  } catch (error) {
    // logAlert("Error parsing JSON for " + cookiename + ": " + error.message);
    return data; // Return raw data if JSON parsing fails
  }
}

// Delete cookies and remove logged-in user
export function logOut(name) {
  if (name === "customer-logout") {
    if (getCookie("loggedInUser")) {
      localStorage.removeItem("loggedInUser");

      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  } else {
    localStorage.removeItem("loggedInUser");
  }
}

export function loggedInUser() {
  return getCookie("loggedInUser"); // Now returns a parsed object or null
}

// Ensure user role is accessed correctly
const user = loggedInUser();
export const userDashboard =
  user?.user?.userRole === 5 || user?.user?.userRole === 4
    ? routings.admin_dashboard
    : routings.vendor_dashboard;
