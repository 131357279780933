import React from "react";
import { Bar } from "react-chartjs-2";

type Props = {
  title: string;
  chartData: any;
};

const BarChart = ({ title, chartData }: Props) => {
  return (
    <div>
      <Bar
        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              //   text: "Users Gained between 2016-2020",
              text: title,
            },
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};

export default BarChart;
