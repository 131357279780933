import { DialogContentText } from "@material-ui/core";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  AlertCircle,
  Calendar,
  CheckCircle2,
  Clock,
  Coins,
  LayersIcon,
  Share2,
  User,
} from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomBreadCrumb from "../../components/CustomComponents/Tables/CustomBreadCrumb.tsx";
import { AppContext } from "../../context/AppContext.js";
import { CommonComponent } from "../../service";
import { getCookie, userDashboard } from "../../service/get-user.js";

interface CommissionData {
  commissionName: string;
  commissionCode: string;
  commissionId: number;
  applicableModule: string;
  applicableModuleName: string;
  commissionType: string;
  baseAmount: number;
  percentageRate: number;
  startDate: string;
  endDate: string;
  commissionStatus: string;
  reviewStatus: string;
  createdBy: string;
  createdByName: string;
  lastUpdatedBy: string;
  lastUpdatedByName: string;
  rejectReason: string;
  taxInclusive: boolean;
  createDate: string;
  lastUpdateDate: string;
  tiers?: {
    minAmount: number;
    maxAmount: number | null;
    tierCalculationType: string;
    commissionRate: number;
  }[];
}

interface StatusBadgeProps {
  status: string;
  type: "commission" | "review";
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status, type }) => {
  const baseClasses =
    "px-3 py-1 rounded-full text-sm font-medium flex items-center gap-2";

  if (type === "commission") {
    return (
      <span
        className={`${baseClasses} ${
          status === "ACTIVE"
            ? "bg-green-100 text-green-800"
            : "bg-red-100 text-red-800"
        }`}
      >
        {status === "ACTIVE" ? (
          <CheckCircle2 size={16} />
        ) : (
          <AlertCircle size={16} />
        )}
        {status}
      </span>
    );
  }

  return (
    <span
      className={`${baseClasses} ${
        status === "APPROVED"
          ? "bg-blue-100 text-green-800"
          : status === "REJECTED"
          ? "bg-red-100 text-red-800"
          : "bg-yellow-100 text-yellow-800"
      }`}
    >
      {status === "APPROVED" ? (
        <CheckCircle2 size={16} />
      ) : status === "REJECTED" ? (
        <AlertCircle size={16} />
      ) : (
        <Clock size={16} />
      )}
      {status}
    </span>
  );
};

const TierTable: React.FC<{ tiers: CommissionData["tiers"] }> = ({ tiers }) => {
  if (!tiers) return null;

  return (
    <div className="mt-6 overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Range
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Calculation Type
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Commission Rate
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Fixed Commission Amount(UGX)
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {tiers.map((tier, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap">
                {tier.minAmount.toLocaleString()} -{" "}
                {tier.maxAmount ? tier.maxAmount.toLocaleString() : "∞"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {tier.tierCalculationType === "RATE_BASED"
                  ? "Rate"
                  : "Fixed Amount"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {tier.commissionRate ? tier.commissionRate : "N/A"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                {tier.fixedCommissionRate
                  ? tier.fixedCommissionRate.toLocaleString()
                  : "N/A"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const CommissionProfile: React.FC = () => {
  const { commissionId } = useParams();
  const commonComponent = new CommonComponent();
  const auth = getCookie("loggedInUser");
  const userRole = auth?.userRole;
  const userId = auth?.user?.id;
  const navigate = useNavigate();
  const [commissionData, setCommissionData] = useState<CommissionData | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [approveDialogOpen, setApproveDialogOpen] = useState(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const { setSnackAlert } = useContext(AppContext);

  useEffect(() => {
    fetchCommissionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commissionId]);

  const fetchCommissionData = async () => {
    const postObject = {
      auth,
      search: { commissionId },
    };

    try {
      const data = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "getCommissionConfiguration",
        JSON.stringify(postObject)
      );

      if (data.returnCode === 200) {
        setCommissionData(data.returnData);
      } else {
        setError(data.returnMessage || "Failed to fetch commission data");
      }
    } catch (err) {
      setError("Error fetching commission data");
      console.error("Error fetching commission data:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleApproveClick = () => {
    setApproveDialogOpen(true);
  };

  const handleApproveConfirm = async () => {
    setApproveDialogOpen(false);
    const postObject = {
      auth,
      requestData: {
        status: "APPROVED",
        commissionId,
      },
    };

    try {
      const data = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "updateCommissionReviewStatus",
        JSON.stringify(postObject)
      );

      if (data.returnCode === 200) {
        setSnackAlert({
          open: true,
          message: data.returnMessage,
          severity: "success",
        });
        fetchCommissionData();
      } else {
        setSnackAlert({
          open: true,
          message: "Failed to approve commission",
          severity: "error",
        });
      }
    } catch (err) {
      console.error("Error approving commission:", err);
    }
  };

  const handleRejectClick = () => {
    setRejectDialogOpen(true);
  };

  const handleRejectConfirm = async () => {
    if (!rejectReason.trim()) {
      return; // Don't proceed if reason is empty
    }

    setRejectDialogOpen(false);
    const postObject = {
      auth,
      requestData: {
        commissionId,
        status: "REJECTED",
        rejectReason,
      },
    };

    try {
      const data = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "updateCommissionReviewStatus",
        JSON.stringify(postObject)
      );

      if (data.returnCode === 200) {
        setSnackAlert({
          open: true,
          message: data.returnMessage,
          severity: "success",
        });
        fetchCommissionData();
      } else {
        setSnackAlert({
          open: true,
          message: "Failed to reject commission",
          severity: "error",
        });
      }
    } catch (err) {
      console.error("Error rejecting commission:", err);
    }
  };

  const isAdminAndEligibleForCommissionReview = (): boolean => {
    return (
      userRole === "Business Admin" && commissionData?.createdBy !== userId
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen text-red-600">
        {error}
      </div>
    );
  }

  if (!commissionData) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        No commission data found
      </div>
    );
  }

  const breadcrumbs = [
    { label: "Dashboard", route: () => navigate(userDashboard) },
    { label: "Commissions", route: () => navigate("/view-commissions") },
    { label: "Commission Profile" },
  ];

  return (
    <div className="min-h-screen bg-white-50 my-16">
      {/* Header */}

      <CustomBreadCrumb breadcrumbs={breadcrumbs} />
      {/* Header */}
      <div className="bg-white shadow my-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="text-3xl font-bold text-gray-900">
                {commissionData.commissionName}
              </h1>
              <p className="mt-1 text-sm text-gray-500">
                Commission Code: {commissionData.commissionCode}
              </p>
            </div>
            <div className="flex gap-4">
              <StatusBadge
                status={commissionData.commissionStatus}
                type="commission"
              />
              <StatusBadge status={commissionData.reviewStatus} type="review" />
              {isAdminAndEligibleForCommissionReview() && (
                <div className="flex gap-2">
                  {(commissionData.reviewStatus === "AWAITING REVIEW" ||
                    commissionData.reviewStatus === "REJECTED") && (
                    <button
                      onClick={handleApproveClick}
                      className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
                    >
                      Approve
                    </button>
                  )}
                  {commissionData.reviewStatus === "AWAITING REVIEW" && (
                    <button
                      onClick={handleRejectClick}
                      className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                    >
                      Reject
                    </button>
                  )}
                </div>
              )}

              {/* Approve Confirmation Dialog */}
              <Dialog
                open={approveDialogOpen}
                onClose={() => setApproveDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
              >
                <DialogTitle>Confirm Commission Approval</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <p className="mb-4 font-semibold text-slate-900">
                      Are you sure you want to approve this commission?
                    </p>
                    <p className="text-gray-700">
                      <span className="font-medium">Commission Details:</span>{" "}
                      {commissionData?.commissionName} (Code:{" "}
                      {commissionData?.commissionCode}), applicable to the{" "}
                      {commissionData?.applicableModuleName} module, categorized
                      as {commissionData?.commissionType}.
                    </p>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setApproveDialogOpen(false)}
                    color="inherit"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleApproveConfirm}
                    variant="contained"
                    color="warning"
                  >
                    Confirm Approval
                  </Button>
                </DialogActions>
              </Dialog>

              {/* Reject Dialog with Reason */}
              <Dialog
                open={rejectDialogOpen}
                onClose={() => setRejectDialogOpen(false)}
                maxWidth="sm"
                fullWidth
              >
                <DialogTitle>Reject Commission</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to reject this commission?
                  </DialogContentText>

                  <p className="text-gray-700">
                    <span className="font-medium">Commission Details:</span>{" "}
                    {commissionData?.commissionName} (Code:{" "}
                    {commissionData?.commissionCode}), applicable to the{" "}
                    {commissionData?.applicableModuleName} module, categorized
                    as {commissionData?.commissionType}.
                  </p>

                  {/* Rejection Reason Input */}
                  <textarea
                    autoFocus
                    required
                    margin="dense"
                    label="Reason for Rejection"
                    className="form-control mt-4"
                    value={rejectReason}
                    onChange={(e) => setRejectReason(e.target.value)}
                    rows={4}
                    placeholder="Reason for Rejection"
                    error={rejectDialogOpen && !rejectReason.trim()}
                    helperText={
                      rejectDialogOpen && !rejectReason.trim()
                        ? "Reason is required"
                        : ""
                    }
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setRejectDialogOpen(false)}
                    color="inherit"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleRejectConfirm}
                    variant="contained"
                    color="error"
                    disabled={!rejectReason.trim()}
                  >
                    Confirm Rejection
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-1">
        <div className="bg-white shadow rounded-lg">
          {/* Basic Information */}
          <div className="px-6 py-5 border-b border-gray-200">
            <h2 className="text-xl font-semibold text-gray-900">
              Basic Information
            </h2>
            <div className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
              <div className="flex items-center gap-2">
                <LayersIcon className="text-[--primary-color]" size={20} />
                <div>
                  <p className="text-sm font-medium text-gray-500">
                    Applicable Module
                  </p>
                  <p className="mt-1 text-sm text-gray-900">
                    {commissionData.applicableModuleName}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <Share2 className="text-[--primary-color]" size={20} />
                <div>
                  <p className="text-sm font-medium text-gray-500">
                    Commission Type
                  </p>
                  <p className="mt-1 text-sm text-gray-900">
                    {commissionData.commissionType}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <Coins className="text-[--primary-color]" size={20} />
                <div>
                  <p className="text-sm font-medium text-gray-500">
                    Tax Inclusive
                  </p>
                  <p className="mt-1 text-sm text-gray-900">
                    {commissionData.taxInclusive ? "Yes" : "No"}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <Calendar className="text-[--primary-color]" size={20} />
                <div>
                  <p className="text-sm font-medium text-gray-500">
                    Created On
                  </p>
                  <p className="mt-1 text-sm text-gray-900">
                    {new Date(commissionData.createDate).toLocaleString()}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <Calendar className="text-[--primary-color]" size={20} />
                <div>
                  <p className="text-sm font-medium text-gray-500">
                    Last Updated On
                  </p>
                  <p className="mt-1 text-sm text-gray-900">
                    {commissionData.lastUpdateDate
                      ? new Date(commissionData.lastUpdateDate).toLocaleString()
                      : "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Commission Details */}
          <div className="px-6 py-5">
            <h2 className="text-xl font-semibold text-gray-900">
              Commission Structure
            </h2>
            <div className="mt-4">
              {commissionData.commissionType === "FIXED" && (
                <div className="flex items-center gap-2 mb-4">
                  <p className="text-sm font-medium text-gray-500">
                    Base Amount:
                  </p>
                  <p className="text-sm text-gray-900">
                    {typeof commissionData.baseAmount === "number"
                      ? `UGX ${commissionData.baseAmount.toLocaleString()}`
                      : "N/A"}
                  </p>
                </div>
              )}
              {commissionData.commissionType === "PERCENTAGE" && (
                <div className="flex items-center gap-2 mb-4">
                  <p className="text-sm font-medium text-gray-500">
                    Percentage Rate:
                  </p>
                  <p className="text-sm text-gray-900">
                    {typeof commissionData.percentageRate === "number"
                      ? `${commissionData.percentageRate}%`
                      : "N/A"}
                  </p>
                </div>
              )}
              {commissionData.commissionType === "TIER" &&
                commissionData.tiers && (
                  <>
                    <h3 className="text-lg font-medium text-gray-900 mb-4">
                      Tier Rules
                    </h3>
                    <TierTable tiers={commissionData.tiers} />
                  </>
                )}
            </div>
          </div>

          {/* Reject Reason */}
          {commissionData.reviewStatus === "REJECTED" && (
            <div className="px-6 py-5 bg-red-50 rounded-b-lg">
              <h2 className="text-xl font-semibold text-red-900">
                Rejection Reason
              </h2>
              <p className="mt-2 text-sm text-red-700">
                {commissionData.rejectReason}
              </p>
            </div>
          )}

          {/* Footer Information */}
          <div className="px-6 py-5 bg-gray-50 rounded-b-lg">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <div className="flex items-center gap-2">
                <User className="text-[--primary-color]" size={20} />
                <div>
                  <p className="text-sm font-medium text-gray-500">
                    Created By
                  </p>
                  <p className="mt-1 text-sm text-gray-900">
                    {commissionData.createdByName || "N/A"}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <User className="text-[--primary-color]" size={20} />
                <div>
                  <p className="text-sm font-medium text-gray-500">
                    Last Updated By
                  </p>
                  <p className="mt-1 text-sm text-gray-900">
                    {commissionData.lastUpdatedByName || "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommissionProfile;
