export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FILTER_PRODUCTS = "FILTER_PRODUCTS";
export const ORDER_PRODUCTS_BY_PRICE = "ORDER_PRODUCTS_BY_PRICE";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CREATE_ORDER = "CREATE_ORDER";
export const CLEAR_ORDER = "CLEAR_ORDER";
export const CLEAR_CART = "CLEAR_CART";
export const FETCH_ORDERS = "FETCH_ORDERS";
export const LOGGED_IN = "LOGGED_IN";
export const LOGGED_OUT = "LOGGED_OUT";
export const RESET_CART = "RESET_CART";

// school pay types
export const COMPLETE_SCHOOL_ENROLLMENT = "COMPLETE_SCHOOL_ENROLLMENT";
export const CAPTURE_SCHOOL_PAY_PROFILE_COMPLETION_PERCNTG =
  "CAPTURE_SCHOOL_PAY_PROFILE_COMPLETION_PERCNTG";
