import React, {useEffect, useState, useRef, useContext} from "react";
import { useNavigate } from "react-router-dom";
import {MoreVertical, Eye, Edit, CircleAlert, ChevronLeft, ChevronRight, Star, CheckCircle2, Archive} from 'lucide-react';
import CustomBreadCrumbs from "../../../components/CustomComponents/Tables/CustomBreadCrumb.tsx";
import { routings } from "../../../routes/routings.ts";
import { getCookie } from "../../../service/get-user.js";
import { CommonComponent } from "../../../service/index.js";
import { AppContext } from '../../../context/AppContext.js';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface Product {
  average_rating: number;
  id: number;
  image: string;
  product_name: string;
  price_per_unit: number;
  qty_available: number;
  status: string;
  category: string;
  subcategory: string;
  productbrand: string;
}

export default function Products() {
  const [products, setProducts] = useState<Product[]>([]);
  const [totalProducts, setTotalProducts] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const commonComponent = new CommonComponent();
  const navigation = useNavigate();
  const { setSnackAlert } = useContext(AppContext);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [pendingAction, setPendingAction] = useState<{
    product: Product | null;
    status: string;
  }>({ product: null, status: "" });

  const pageSizeOptions = [5, 10, 20, 50, 100];
  const statusOptions = ["All", "Inactive", "Active"];

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, searchQuery, selectedStatus]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const loggedInUser = getCookie("loggedInUser");

  const getProducts = () => {
    const offset = (currentPage - 1) * pageSize;
    const postObject = {
      search: {
        offset: offset,
        limit: pageSize,
        query: searchQuery,
        status: selectedStatus === "All" ? "" : selectedStatus,
        clientId: loggedInUser?.user?.clientId || "",
      }
    };

    commonComponent
        .sendRequestToServer(
            "ProductManagement",
            "getProducts",
            JSON.stringify(postObject)
        )
        .then((data) => {
          if (data.returnCode === 200) {
            setProducts(data.returnData.rows || []);
            setTotalProducts(data.returnData.totalRecords || 0);
          } else {
            console.log("Unexpected return code", data.returnCode);
          }
        })
        .catch(() => console.log("Error occurred while retrieving products."));
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handleStatusChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatus(event.target.value);
    setCurrentPage(1); // Reset to first page when changing status
  };
  const totalPages = Math.ceil(totalProducts / pageSize);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSize = parseInt(event.target.value);
    setPageSize(newSize);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  const editProduct = (product: Product) => {
    navigation("/edit-product/" + product.id);
  };

  const viewProduct = (product: Product) => {
    navigation("/product-profile/" + product.id);
  };

  const toggleDropdown = (id: number) => {
    setActiveDropdown(activeDropdown === id ? null : id);
  };

  const userDashboard = (loggedInUser?.user?.userRole === 5 ||
      loggedInUser?.user?.userRole === 4)
      ? routings.admin_dashboard
      : routings.vendor_dashboard;

  const createProduct = () => {
    navigation("/add-product");
  };

  const getStatusClass = (status: string) => {
    switch (status.toLowerCase()) {
      case 'active':
        return 'bg-green-100 text-green-800 border border-green-200 font-semibold';
      case 'inactive':
        return 'bg-red-100 text-red-800 border border-red-200 font-semibold';
      default:
        return 'bg-gray-100 text-gray-800 border border-gray-200 font-semibold';
    }
  };

  const handleStatusUpdate = (product: Product, status: string) => {
    setPendingAction({ product, status });
    setIsConfirmOpen(true);
    setActiveDropdown(null); // Close the dropdown
  };

  const confirmStatusUpdate = () => {
    if (!pendingAction.product || !pendingAction.status) return;

    const postObject = {
      auth: getCookie("loggedInUser"),
      requestData: {
        productId: pendingAction.product.id,
        status: pendingAction.status,
      },
    };

    commonComponent
        .sendRequestToServer(
            "ProductManagement",
            "updateProductStatus",
            JSON.stringify(postObject)
        )
        .then((data) => {
          const { returnCode, returnMessage } = data;
          setSnackAlert({
            open: true,
            severity: returnCode === 200 ? "success" : "error",
            message: returnMessage,
          });

          if (returnCode === 200) {
            getProducts();
          }
        })
        .catch(() => {
          console.log("Error occurred while updating product status.");
        })
        .finally(() => {
          setIsConfirmOpen(false);
          setPendingAction({ product: null, status: "" });
        });
  };

  const handleClose = () => {
    setIsConfirmOpen(false);
    setPendingAction({ product: null, status: "" });
  };
  const breadcrumbs = [
    { label: "Dashboard", route: () => navigation(userDashboard) },
    { label: "Products Management", route: () => navigation("/products") },
    { label: "Products", route: () => navigation("/details") },
  ];

  return (
      <div className="my-16">
        <div className="flex justify-between items-center">
          <div>
            <CustomBreadCrumbs breadcrumbs={breadcrumbs}/>
          </div>
          <button
              onClick={createProduct}
              className="px-4 py-2 bg-[--primary-color] text-white rounded-md hover:bg-[--primary-color-hover"
          >
            Add Product
          </button>
        </div>

        <div className="mt-2 bg-white rounded-lg shadow">
          <div className="p-4 flex justify-between items-center border-b">
            <input
                type="text"
                placeholder="Search Products"
                value={searchQuery}
                onChange={handleSearch}
                className="px-4 py-2 border rounded-md w-64 focus:outline-none focus:ring-2 focus:ring-[--primary-color]"
            />
            <select
                value={selectedStatus}
                onChange={handleStatusChange}
                className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[--primary-color]"
            >
              <option value="" disabled> Select Status</option>
              {statusOptions.map(status => (
                  <option key={status} value={status}>{status}</option>
              ))}
            </select>
          </div>
          <div className="overflow-x-auto">
          <table className="text-left w-full whitespace-nowrap">
              <thead className="bg-gray-200 text-gray-700">
              <tr className="bg-[--secondary_color] text-white border-transparent !border-b-0">
                <th className="px-4 py-2 text-left text-sm font-semibold text-white-500">Image</th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-white-500">Product Name</th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-white-500">Price (UGX)</th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-white-500">Rating</th>
                <th className="px-4 py-2 text-left text-sm font-semibold text-white-500">Status</th>
                <th className="px-4 py-2 text-right text-sm font-semibold text-white-500">Actions</th>
              </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
              {products.map((product) => (
                  <tr key={product.id} className="hover:bg-gray-50">
                    <td className="px-4 py-2 text-sm text-gray-900">
                      {product.image ? (
                          <div className="flex items-center">
                            <img
                                src={product.image}
                                alt={product.product_name}
                                className="h-12 w-12 object-contain rounded-md"
                            />
                          </div>
                      ) : (
                          <div className="h-10 w-10 flex items-center justify-center bg-gray-200 rounded-md">
                            <CircleAlert className="w-5 h-5 text-gray-500"/>
                          </div>
                      )}
                    </td>
                    <td className="px-4 py-2 text-sm font-bold text-black-900">{product.product_name}</td>
                    <td className="px-4 py-2 text-sm font-medium text-slate-500">
                      {product.price_per_unit.toLocaleString()}
                    </td>
                    <td className="px-4 py-2 text-sm font-medium text-slate-500">
                      <div className="flex items-center">
                        {[...Array(5)].map((_, i) => (
                            <Star
                                key={i}
                                className={`h-5 w-5 ${
                                    i < Math.floor(product.average_rating) ? "text-[--primary-color]" : "text-[--primary-color]"
                                }`}
                                fill={i < Math.floor(product.average_rating) ? "currentColor" : "none"}
                            />
                        ))}
                      </div>
                    </td>
                    <td className="px-4 py-2 text-sm">
                    <span
                        className={`inline-block p-1 text-sm align-baseline leading-none rounded ${getStatusClass(product.status)}`}
                    >
                      {product.status}
                    </span>
                    </td>
                    <td className="px-4 py-2 text-right text-sm font-medium relative">
                      <button
                          onClick={() => toggleDropdown(product.id)}
                          className="text-gray-500 hover:text-gray-700"
                      >
                        <MoreVertical className="w-5 h-5"/>
                      </button>
                      {activeDropdown === product.id && (
                          <div ref={dropdownRef}
                               className="absolute right-8 top-12 w-48 bg-white rounded-md shadow-lg z-10 border border-gray-200">
                            <div className="py-1">
                              <button
                                  onClick={() => viewProduct(product)}
                                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                <Eye className="w-4 h-4 mr-2"/>
                                View Details
                              </button>
                              <button
                                  onClick={() => editProduct(product)}
                                  className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                <Edit className="w-4 h-4 mr-2"/>
                                Edit Product
                              </button>
                              {product.status.toLowerCase() === "active" ? (
                                  <button
                                      onClick={() => handleStatusUpdate(product, "Inactive")}
                                      className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  >
                                    <Archive className="w-4 h-4 mr-2"/>
                                    Archive Product
                                  </button>
                              ) : (
                                  <button
                                      onClick={() => handleStatusUpdate(product, "Active")}
                                      className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                  >
                                    <CheckCircle2 className="w-4 h-4 mr-2"/>
                                    Activate Product
                                  </button>
                              )}
                            </div>
                          </div>
                      )}
                    </td>
                  </tr>
              ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls */}
          <div className="flex items-center justify-between px-6 py-4 border-t border-gray-200">
            <div className="flex items-center">
              <span className="text-sm text-gray-700 mr-2">Rows per page:</span>
              <select
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  className="border rounded-md px-4 py-2 text-sm"
              >
                {pageSizeOptions.map(size => (
                    <option key={size} value={size}>{size}</option>
                ))}
              </select>
            </div>

            <div className="flex items-center">
            <span className="text-sm text-gray-700 mr-4">
              {`${(currentPage - 1) * pageSize + 1}-${Math.min(currentPage * pageSize, totalProducts)} of ${totalProducts}`}
            </span>
              <div className="flex space-x-2">
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className={`p-1 rounded ${currentPage === 1 ? 'text-gray-400' : 'text-gray-700 hover:bg-gray-100'}`}
                >
                  <ChevronLeft className="w-5 h-5"/>
                </button>
                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className={`p-1 rounded ${currentPage === totalPages ? 'text-gray-400' : 'text-gray-700 hover:bg-gray-100'}`}
                >
                  <ChevronRight className="w-5 h-5"/>
                </button>
              </div>
            </div>
          </div>
          {/* Add the Dialog component at the end of your JSX, before the closing div */}
          <Dialog
              open={isConfirmOpen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {pendingAction.status === "active" ? "Activate Product" : "Archive Product"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to {pendingAction.status.toLowerCase() === "active" ? "activate" : "archive"}
                {" "}<strong>{pendingAction.product?.product_name}</strong>?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button
                  onClick={handleClose}
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md"
              >
                Cancel
              </button>
              <button
                  onClick={confirmStatusUpdate}
                  className="px-4 py-2 bg-[--primary-color] text-white rounded-md hover:bg-[--primary-color-hover]"
                  autoFocus
              >
                Confirm
              </button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
  );
}