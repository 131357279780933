import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../context/AppContext.js";
import TabbedHeaderComponent from "../../../components/CustomComponents/Tabs/TabbedHeaderComponent.tsx";
import { CommonComponent } from "../../../service";
import {
  FiFileText,
  FiDownload,
  FiFilter,
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";
import { getCookie } from "../../../service/get-user.js";
import { title } from "process";
import { columns } from "../index.js";

// Types
interface BaseData {
  approval_status: string;
  requested_on: string;
}

interface VendorData extends BaseData {
  vendor_name: string;
  school_name: string;
  category: string;
}

interface PackageData extends BaseData {
  package_name: string;
  school_name: string;
  class_name: string;
}

// Column definitions
const vendorColumns = [
  { header: "Vendor Name", accessor: "vendor_name" as const },
  { header: "Email Address", accessor: "email" as const },
  { header: "School Name", accessor: "school_name" as const },
  { header: "Initiated By", accessor: "initiated_by" as const },
  { header: "school Code", accessor: "school_code" as const },
  { header: "Approval Status", accessor: "approval_status" as const },
  { header: "Requested On", accessor: "requested_on" as const },
];

const SchoolColumns = [
  { header: "School Code", accessor: "school_code" as const },
  { header: "School Name", accessor: "school_name" as const },
  { header: "Initiated By", accessor: "initiated_by" as const },
  { header: "Package Price", accessor: "package_price" as const },
  { header: "Package Name", accessor: "package_name" as const },
  { header: "Start Date", accessor: "package_start_date" as const },
  { header: "End Date", accessor: "package_end_date" as const },
  { header: "Market Status", accessor: "market_status" as const },
];

const packageColumns = [
  { header: "School Name", accessor: "school_name" as const },
  { header: "school Code", accessor: "school_code" as const },
  { header: "Class Name", accessor: "class_name" as const },
  { header: "Package Name", accessor: "package_name" as const },
  { header: "Package grandPrice", accessor: "grandprice" as const },
  { header: "Approval Status", accessor: "mapping_status" as const },
  { header: "Approved On", accessor: "approved_on" as const },
];

const req = new CommonComponent();
const auth = getCookie("loggedInUser");

export function MappingReports() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [vendorData, setVendorData] = useState<VendorData[]>([]);
  const [ClassPackageData, setClassPackageData] = useState<PackageData[]>([]);
  const [SchoolPackageData, setSchoolPackageData] = useState<PackageData[]>([]);

  const { setLoading, setSnackAlert }: any = useContext(AppContext);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const showError = (message: string) => {
    setSnackAlert({
      open: true,
      severity: "error",
      message,
    });
    setLoading(false);
  };

  const fetchSchoolsToPackageMapping = async () => {
    let postObject = {
      auth,
      search: {
        vendor: "working on it",
      },
    };
    setLoading(true);

    try {
      const res = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "getPackageSchoolMapping",
        JSON.stringify(postObject)
      );

      if (res.returnCode === 200) {
        setLoading(false);
        setSchoolPackageData(res.returnData);
        return;
      } else {
        setLoading(false);
        setSnackAlert({
          open: true,
          severity: "error",
          message: res.returnMessage,
        });
        return;
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to fetch schools to be approved",
      });
      return;
    }
  };

  const getAllVendorMappingRequests = () => {
    let postObject = {
      search: { isLoggined: "working well" },
      auth,
    };

    setLoading(true);

    try {
      req
        .sendRequestToServer(
          "SchoolpayShoppingHelper",
          "getAllVendorMappingRequests",
          JSON.stringify(postObject)
        )
        .then((resp) => {
          const { returnCode, returnData, returnMessage } = resp;
          if (returnCode === 200) {
            setVendorData(returnData.rows);
            setLoading(false);
          } else {
            setLoading(false);

            showError(returnMessage);
          }
        });
    } catch (error) {
      setLoading(false);
      showError("error: " + error.message);
    }
  };

  const getmapPackageToSchoolClasses = () => {
    let postObject = {
      search: { vendor: "working well" },
      auth,
    };

    setLoading(true);

    try {
      req
        .sendRequestToServer(
          "SchoolpayShoppingHelper",
          "getmapPackageToSchoolClasses",
          JSON.stringify(postObject)
        )
        .then((resp) => {
          console.log("resp ", resp);

          const { returnCode, returnData, returnMessage } = resp;
          if (returnCode === 200) {
            setClassPackageData(returnData);
            setLoading(false);
          } else {
            setLoading(false);

            showError(returnMessage);
          }
        });
    } catch (error) {
      setLoading(false);
      showError("error: " + error.message);
    }
  };

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    let sub = true;

    if (sub) {
      getmapPackageToSchoolClasses();
      getAllVendorMappingRequests();
      fetchSchoolsToPackageMapping();

      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }

    return (sub = false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderStatusBadge = (status: string) => {
    const statusStyles: { [key: string]: string } = {
      Approved: "bg-green-100 text-green-800",
      Rejected: "bg-red-100 text-red-800",
      "Not Approved": "bg-yellow-100 text-yellow-600",
      "Awaiting Review": "bg-yellow-100 text-yellow-600",
    };

    return (
      <span
        className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${
          statusStyles[status] || "bg-gray-100 text-gray-800"
        }`}
      >
        {status}
      </span>
    );
  };

  const handleExportExcel = () => {
    showError("Excel Export Not Implemented Yet");
    // Add actual Excel export logic here
  };

  const handleExportPDF = () => {
    showError("PDF Export Not Implemented Yet");
    // Add actual PDF export logic here
  };

  const filters = ["All", "Approved", "Awaiting Review", "Not Approved"];

  const handleFilterChange = (filter: string) => {
    setSelectedFilter(filter);
    console.log(`Filtering by: ${filter}`);
    // Add actual filtering logic here
  };

  const renderTable = (
    data: any[],
    columns: { header: string; accessor: string }[]
  ) => {
    const rowsPerPage = 10;

    const filteredData = data
      ? selectedFilter === "All"
        ? data
        : data.filter((row) =>
            row.approval_status
              ? row.approval_status === selectedFilter
              : row.mapping_status === selectedFilter
          )
      : [];

    const totalPages = filteredData
      ? Math.ceil(filteredData.length / rowsPerPage)
      : 0;
    const paginatedData = filteredData.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );

    const handlePageChange = (newPage: number) => {
      if (newPage >= 1 && newPage <= totalPages) {
        setCurrentPage(newPage);
      }
    };

    return (
      <div className="bg-white rounded-xl shadow-lg overflow-hidden relative border border-gray-100">
        {/* Enhanced Top Toolbar */}
        <div className="flex justify-between items-center p-4 border-b border-gray-100 bg-gray-50">
          {/* Styled Filter Dropdown */}
          <div ref={dropdownRef} className="relative z-20">
            {/* Filter Button */}
            <button
              onClick={toggleDropdown}
              className="flex items-center gap-2 bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded-lg shadow-sm transition text-[--school-pay-primary-color] border-2"
            >
              <FiFilter
                size={16}
                className="text-[--school-pay-primary-color] font-extrabold"
              />
              {selectedFilter}
            </button>

            {/* Dropdown Menu */}
            {isOpen && (
              <div className="absolute left-0 mt-2 w-40 bg-white border rounded-md shadow-lg transition-all duration-200">
                {filters.map((filter) => (
                  <button
                    key={filter}
                    onClick={() => {
                      handleFilterChange(filter);
                      setIsOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                  >
                    {filter}
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* Enhanced Export Buttons */}
          <div className="flex gap-2">
            <button
              onClick={handleExportExcel}
              className="flex items-center gap-1.5 bg-emerald-500 hover:bg-emerald-600 text-white px-3 py-1.5 rounded-lg shadow-sm transition-all duration-200 font-medium text-sm"
            >
              <FiDownload className="w-4 h-4" />
              Export Excel
            </button>
            <button
              onClick={handleExportPDF}
              className="flex items-center gap-1.5 bg-rose-500 hover:bg-rose-600 text-white px-3 py-1.5 rounded-lg shadow-sm transition-all duration-200 font-medium text-sm"
            >
              <FiFileText className="w-4 h-4" />
              Export PDF
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          {/* Enhanced Table Body */}
          <div className="max-h-[calc(100vh-300px)] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
            <table className="min-w-full border-collapse table-fixed">
              <thead className="bg-gradient-to-r from-[--school-pay-primary-color] to-[--school-pay-primary-color]/90 text-black shadow-md">
                <tr>
                  <th className="px-4 py-2.5 text-left text-xs font-semibold uppercase tracking-wider w-10">
                    #
                  </th>
                  {columns.map((column, index) => (
                    <th
                      key={index}
                      className="px-4 py-2.5 text-left text-xs font-semibold uppercase tracking-wider w-[200px]"
                    >
                      {column.header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-100">
                {paginatedData.length > 0 ? (
                  paginatedData.map((row, rowIndex) => (
                    <tr
                      key={rowIndex}
                      className="transition-all duration-200 hover:bg-gray-50"
                    >
                      <td className="px-4 py-2 text-xs font-medium text-gray-900 w-10">
                        {(currentPage - 1) * rowsPerPage + rowIndex + 1}
                      </td>
                      {columns.map((column, colIndex) => (
                        <td
                          key={colIndex}
                          className="px-4 py-2 text-xs text-gray-700 whitespace-nowrap overflow-hidden text-ellipsis"
                        >
                          {column.accessor === "approval_status" ||
                          column.accessor === "mapping_status"
                            ? renderStatusBadge(row[column.accessor])
                            : row[column.accessor]}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={columns.length + 1}
                      className="px-4 py-8 text-center text-gray-500 bg-gray-50"
                    >
                      <div className="flex flex-col items-center justify-center space-y-1">
                        <span className="text-sm font-medium">
                          No records found
                        </span>
                        <span className="text-xs text-gray-400">
                          for "{selectedFilter}"
                        </span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Enhanced Pagination Controls */}
        <div className="flex justify-between items-center px-4 py-2 bg-gray-50 border-t border-gray-100">
          <span className="text-xs text-gray-700">
            Showing {(currentPage - 1) * rowsPerPage + 1} to{" "}
            {Math.min(currentPage * rowsPerPage, filteredData.length)} of{" "}
            {filteredData.length} entries
          </span>

          <div className="flex items-center space-x-2">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="flex items-center gap-1 px-3 py-1.5 bg-white text-gray-700 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed text-xs"
            >
              <FiChevronLeft className="w-4 h-4" />
              <span className="font-medium">Previous</span>
            </button>

            <div className="flex items-center px-3 py-1.5 bg-white rounded-lg border border-gray-200">
              <span className="text-xs font-medium text-gray-700">
                Page {currentPage} of {totalPages}
              </span>
            </div>

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="flex items-center gap-1 px-3 py-1.5 bg-white text-gray-700 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed text-xs"
            >
              <span className="font-medium">Next</span>
              <FiChevronRight className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    const contentMap = [
      {
        data: vendorData,
        columns: vendorColumns,
        title: "Vendor Mapping Requests",
      },
      {
        data: ClassPackageData,
        columns: packageColumns,
        title: "Package Mapping Requests",
      },
      {
        data: SchoolPackageData,
        columns: SchoolColumns,
        title: "School Mapping Request",
      },
    ];

    const currentContent = contentMap[activeIndex];

    return (
      <div className="space-y-4">
        <h2 className="text-xl font-semibold text-gray-800">
          {currentContent.title}
        </h2>
        {renderTable(currentContent.data, currentContent.columns)}
      </div>
    );
  };

  return (
    <div className="p-6">
      <div className="mb-6">
        <h1 className="text-3xl font-light text-gray-900">Mapping Reports</h1>
      </div>

      <div className="mb-6">
        <TabbedHeaderComponent
          headers={[
            "Vendor Requests to Schools",
            "Class Requests to Package",
            "Package Requests to School",
          ]}
          activeIndex={activeIndex}
          onHeaderClick={(index) => () => setActiveIndex(index)}
        />
      </div>

      <div className="bg-gray-50 p-6 rounded-lg">{renderContent()}</div>
    </div>
  );
}

export default MappingReports;
