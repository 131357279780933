import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import notFound from "../assets/img/vectors/not_found.png";
import ButtonBase from "./CustomComponents/Buttons/ButtonBase.tsx";

const variants = ["h1", "h3", "body1", "caption"];

function TypographyDemo(props) {
  const { loading = false } = props;

  return (
    <div>
      {variants.map((variant) => (
        <Typography component="div" key={variant} variant={variant}>
          {loading ? <Skeleton /> : variant}
        </Typography>
      ))}
    </div>
  );
}

TypographyDemo.propTypes = {
  loading: PropTypes.bool,
};

const NotFoundPage = () => {
  // const theme = useTheme();
  const navigation = useNavigate();
  // const navigate = useNavigation();

  return (
    <div className="flex w-screen max-h-screen justify-center overflow-hidden">
      <div className="not-found-container items-center justify-center flex flex-col w-full">
        <img
          src={notFound}
          alt="Not Found"
          className="not-found-image h-[80%]"
        />

        <div className="space-x-5 flex justify-between mt-10">
          <div className="flex justify-between">
            <ButtonBase
              title="Go Back"
              onClick={() => navigation(-1)}
              theme="secondary"
              className="text-white"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
