import { useEffect, useState } from "react";

export type ConnectionStatus = "online" | "offline" | "slow";

export function useInternetStatus() {
  const [status, setStatus] = useState<ConnectionStatus>("online");

  useEffect(() => {
    let slowConnectionTimeout: any;

    const checkConnectionSpeed = async () => {
      try {
        const startTime = performance.now();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        // const response = await fetch("https://healthpal.co.ug");
        const endTime = performance.now();
        const duration = endTime - startTime;

        // Consider connection slow if response takes more than 2 seconds
        if (duration > 2000) {
          setStatus("slow");
        } else {
          setStatus("online");
        }
      } catch (error) {
        setStatus("offline");
      }
    };

    const handleOnline = () => {
      // Clear any existing timeout
      if (slowConnectionTimeout) {
        clearTimeout(slowConnectionTimeout);
      }

      // Set a small delay before checking the connection speed
      slowConnectionTimeout = setTimeout(checkConnectionSpeed, 1000);
    };

    const handleOffline = () => {
      setStatus("offline");
      if (slowConnectionTimeout) {
        clearTimeout(slowConnectionTimeout);
      }
    };

    // Initial check
    checkConnectionSpeed();

    // Set up periodic checks every 30 seconds
    const intervalId = setInterval(checkConnectionSpeed, 30000);

    // Add event listeners
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      clearInterval(intervalId);
      if (slowConnectionTimeout) {
        clearTimeout(slowConnectionTimeout);
      }
    };
  }, []);

  return status;
}
