export const type_of_school_data = [
  { id: 1, label: "Secondary (A level)" },
  { id: 2, label: "Secondary (O level)" },
  { id: 3, label: "Secondary (O and A level)" },

  { id: 4, label: "Primary" },
  { id: 5, label: "Nursery" },
  { id: 6, label: "Nursery and Primary" },
  { id: 7, label: "Day Care" },
  { id: 8, label: " Nursery and Day care" },
  { id: 9, label: "Technical School" },
  { id: 10, label: "Vocational School" },
];

export const sex_of_school_data = [
  { id: 1, label: "Boys' School" },
  { id: 2, label: "Girls' School" },
  { id: 3, label: "Mixed School" },
];

export const skeletondata = [
  "one",
  "two",
  " three",
  "four",
  "five",
  "six",
  "seven",
  "eight",
];
