import { Plus, UserPen, UserRoundCheck } from "lucide-react";
import React, { useEffect, useMemo, useRef, useState } from "react";

interface TableProps<T> {
  data: T[];
  columns: { header: string; accessor: any }[];
  Edit?: (row: T) => void;
  renderCell?: (accessor: keyof T, row: T) => React.ReactNode;
  Add?: (row: T) => void;
  create?: { label: string; onclick: () => void };
  ActiveStatus?: any;
  inActiveStatus?: any;
  filters: { label: string; value: any }[];
  onApproval?: () => void;
  onSelectRow?: (rowData) => any;
  generateReportEnabled?: boolean;
  onGenerateReport?: (reportType: string) => any;
  actionComponent?: (item: any) => JSX.Element;
  selectable?: boolean;
  editlabel?: string;
  addlabel?: string;
  showCheckbox?: boolean;
}

const CustomSchoolPayTable = <T,>({
  data,
  columns,
  Edit,
  editlabel,
  addlabel,
  renderCell,
  Add,
  create,
  filters,
  onApproval,
  onSelectRow,
  generateReportEnabled,
  onGenerateReport,
  actionComponent,
  selectable = true,
}: TableProps<T>) => {
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [paginatedData, setPaginatedData] = useState<T[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const [dropdownDirection, setDropdownDirection] = useState<string>("down");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [activeFilter, setActiveFilter] = useState<string>("All");
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const dropdownRefs = useRef<(HTMLDivElement | null)[]>([]);

  // Filter and search logic
  const filteredData = useMemo(() => {
    return data.filter((item: any) => {
      // Search filter
      const searchMatch = Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      );

      // Category filter (applies to all columns now)
      const filterMatch =
        activeFilter === "All" ||
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(activeFilter.toLowerCase())
        );
      return searchMatch && filterMatch;
    });
  }, [data, searchQuery, activeFilter]);

  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const newPaginatedData = filteredData.slice(start, end);
    setPaginatedData(newPaginatedData);
    setTotalPages(Math.ceil(filteredData.length / itemsPerPage));
  }, [currentPage, itemsPerPage, filteredData]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRefs.current.some(
          (ref, index) =>
            ref && dropdownOpen === index && !ref.contains(event.target as Node)
        )
      ) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  const toggleDropdown = (rowIndex: number) => {
    setDropdownOpen(dropdownOpen === rowIndex ? null : rowIndex);

    const rowRef = dropdownRefs.current[rowIndex];
    if (rowRef) {
      const rect = rowRef.getBoundingClientRect();
      const shouldOpenUp = window.innerHeight - rect.bottom < 150;
      setDropdownDirection(shouldOpenUp ? "up" : "down");
    }
  };

  // Exclude the 'id' column from being displayed
  const filteredColumns = columns.filter((column) => column.accessor !== "id");

  const handleFilterButtonClick = (filter: string) => {
    setActiveFilter(filter);
    setCurrentPage(1); // Reset to the first page when filter changes
  };

  const handleSelectRow = (rowIndex: number, rowData: any) => {
    if (onSelectRow) onSelectRow(rowData);

    const newSelectedRows = new Set(selectedRows);
    if (newSelectedRows.has(rowIndex)) {
      newSelectedRows.delete(rowIndex);
    } else {
      newSelectedRows.add(rowIndex);
    }
    setSelectedRows(newSelectedRows);
  };

  const handleSelectAllRows = () => {
    if (selectAll) {
      setSelectedRows(new Set());
    } else {
      const allRowIndices = new Set(
        paginatedData.map(
          (_, rowIndex) => (currentPage - 1) * itemsPerPage + rowIndex
        )
      );
      setSelectedRows(allRowIndices);
    }
    setSelectAll(!selectAll);
  };

  const FilterButton = ({
    filter,
  }: {
    filter: { value: string; label: string };
  }) => {
    const isActive = filter.value === activeFilter;

    return (
      <button
        className={`bg-gray-200 text-gray-700 hover:bg-slate-300 hover:text-white py-2 uppercase rounded-md text-xs shadow-md font-bold transition-colors duration-300 cursor-pointer px-4 ${
          isActive
            ? "border-4 border-[--secondary_color] bg-slate-400 pl-2 text-[--secondary_color]"
            : ""
        }`}
        onClick={() => handleFilterButtonClick(filter.value)}
      >
        {filter.label}
        {isActive && (
          <span className="ml-2 bg-[--secondary_color] text-white rounded-md px-2 font-bold text-xs">
            {filteredData.length}
          </span>
        )}
      </button>
    );
  };

  return (
    <div className="bg-white rounded-3xl">
      <div className="bg-white h-full py-2 mx-2 rounded-3xl -mt-4">
        {/* Top Panel */}
        <div className="flex flex-row justify-between mb-2 py-2">
          <div className="space-x-2 pl-2">
            {filters.map((filter) => {
              return <FilterButton filter={filter} key={filter.value} />;
            })}
          </div>

          <div className="flex space-x-2 px-4">
            <input
              type="text"
              className="px-3 rounded-md border border-gray-300 text-xs"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button
              className="bg-[#43C6DB] text-white px-2 rounded-md text-xs font-extrabold shadow-md uppercase transition-colors duration-300 cursor-pointer hover:shadow-none"
              onClick={() => {
                if (create?.onclick) {
                  create.onclick();
                }
              }}
              title={create?.label} // Tooltip on hover
            >
              <Plus size={22} />
            </button>

            {generateReportEnabled && (
              <div className="px-2 flex justify-between">
                <button
                  onClick={() => onGenerateReport && onGenerateReport("pdf")}
                  className="w-[45%] border border-[#c54195] hover:bg-[#c54195] px-3 flex items-center justify-center py-1 hover:text-white text-[#c54195] cursor-pointer space-x-3"
                >
                  <i className="pi pi-file-pdf" />
                  PDF
                </button>
                <button
                  onClick={() => onGenerateReport && onGenerateReport("xls")}
                  className="w-[45%] border border-[--school-pay-secondary-color] hover:bg-[--school-pay-secondary-color] px-3 flex  items-center justify-center py-1 text-[--school-pay-secondary-color] hover:text-white cursor-pointer space-x-3"
                >
                  <i className="pi pi-file-excel" />
                  EXCEL
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Selected Rows Count */}
        <div className="flex items-center px-4 pb-2 space-x-4">
          <span className="text-sm text-gray-600">
            {selectedRows.size} {selectedRows.size === 1 ? "item" : "items"}{" "}
            selected
          </span>

          {onApproval && selectedRows.size > 0 && (
            <button
              className="bg-[#43C6DB] hover:opacity-[.8] text-white p-2 rounded-lg font-bold text-base shadow-sm capitalize transition-colors duration-300 cursor-pointer hover:shadow-none"
              onClick={() => onApproval()}
            >
              Approve Item{selectedRows.size === 1 ? "" : "s"}
            </button>
          )}
        </div>
        <div className="overflow-x-auto bg-white rounded-lg max-h-[400px] min-h-[350px] shadow-md">
          <table className="min-w-full bg-white text-xs border-collapse">
            {/* Table Head */}
            <thead className="bg-gradient-to-r from-[--school-pay-primary-color] to-[--school-pay-primary-color]/90 text-black shadow-md">
              <tr>
                {selectable && (
                  <th className="py-3 px-4 text-left">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllRows}
                      className="cursor-pointer accent-[#43C6DB]"
                    />
                  </th>
                )}
                <th className="py-3 px-4 text-left font-bold">No.</th>
                {filteredColumns.map((column, index) => (
                  <th
                    key={index}
                    className="py-3 px-4 text-left font-semibold overflow-hidden text-ellipsis whitespace-nowrap"
                  >
                    {column.header}
                  </th>
                ))}
                <th className="py-3 px-4 text-left text-black font-bold">
                  Action
                </th>
              </tr>
            </thead>

            {/* Table Body */}
            <tbody className="text-gray-600">
              {paginatedData.length > 0 ? (
                paginatedData.map((row, rowIndex) => {
                  const globalIndex =
                    (currentPage - 1) * itemsPerPage + rowIndex;
                  return (
                    <tr
                      key={rowIndex}
                      className={`border-b transition duration-150 ${
                        rowIndex % 2 === 0 ? "bg-gray-50" : "bg-white"
                      } ${
                        selectedRows.has(globalIndex) ? "bg-[#ade8f1]" : ""
                      } hover:bg-gray-100`}
                    >
                      {selectable && (
                        <td
                          className="py-2 px-4 cursor-pointer"
                          onClick={() => handleSelectRow(globalIndex, row)}
                        >
                          <input
                            type="checkbox"
                            checked={selectedRows.has(globalIndex)}
                            className="cursor-pointer accent-[#43C6DB]"
                          />
                        </td>
                      )}
                      <td className="py-2 px-4">{globalIndex + 1}</td>
                      {filteredColumns.map((column, colIndex) => (
                        <td
                          key={colIndex}
                          className="py-2 px-4 overflow-hidden text-ellipsis whitespace-nowrap"
                        >
                          {renderCell
                            ? renderCell(column.accessor, row)
                            : row[column.accessor]}
                        </td>
                      ))}

                      {/* Action Column */}
                      <td className="py-2 px-4 relative">
                        {actionComponent ? (
                          actionComponent(row)
                        ) : (
                          <div className="relative">
                            <button
                              className="bg-gray-200 text-black py-2 px-2 rounded-md transition-all hover:bg-gray-300"
                              onClick={() => toggleDropdown(rowIndex)}
                            >
                              <UserRoundCheck size={16} />
                            </button>

                            {/* Action Dropdown */}
                            {dropdownOpen === rowIndex && (
                              <div
                                ref={(el) =>
                                  (dropdownRefs.current[rowIndex] = el)
                                }
                                className={`absolute right-0 mt-2 w-40 bg-white rounded-md shadow-lg z-50 transform scale-95 opacity-0 transition-all duration-200 ease-in-out ${
                                  dropdownOpen === rowIndex
                                    ? "scale-100 opacity-100"
                                    : ""
                                }`}
                              >
                                <div className="py-2">
                                  <div
                                    className="flex items-center space-x-2 p-2 cursor-pointer hover:bg-gray-100"
                                    onClick={() => Edit && Edit(row)}
                                  >
                                    <UserPen size={14} />
                                    <span className="text-xs">
                                      {editlabel || "Edit"}
                                    </span>
                                  </div>
                                  {Add && (
                                    <div
                                      className="flex items-center space-x-2 p-2 cursor-pointer hover:bg-gray-100"
                                      onClick={() => Add(row)}
                                    >
                                      <Plus size={14} />
                                      <span className="text-xs">
                                        {addlabel || "Add"}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={filteredColumns.length + (selectable ? 2 : 1)}
                    className="py-4 px-4 text-center text-gray-500"
                  >
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center px-4 py-2">
          <span className="text-sm text-gray-600">
            Page {currentPage} of {totalPages}
          </span>

          <div className="flex items-center space-x-2">
            <select
              className="px-3 rounded-md border border-gray-300 text-xs"
              value={itemsPerPage}
              onChange={(e) => setItemsPerPage(Number(e.target.value))}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
            </select>

            <button
              className="px-3 py-1 text-sm text-gray-600 bg-gray-200 rounded-md shadow-md hover:bg-gray-300"
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            >
              Previous
            </button>

            <button
              className="px-3 py-1 text-sm text-gray-600 bg-gray-200 rounded-md shadow-md hover:bg-gray-300"
              disabled={currentPage === totalPages}
              onClick={() =>
                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
              }
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomSchoolPayTable;
