import { makeStyles } from "@material-ui/core";
import { Tooltip } from "@mui/material";

const COLORS = {
  primary: "hsl(38, 100%, 50%)",
  secondary: "#1e0030",
  black: "#000000",
  white: "#ffffff",
  gray: "#f2f2f2",
  schoolPayPrimary: "teal",
};

const style = {
  regular_text: {
    fontSize: 13,
    color: COLORS.black,
  },
  search_engine: {
    height: 35,
    fontSize: 18,
  },
  action_btn: {
    marginRight: ".5em",

    padding: 0,
    backgroundColor: "transparent !important",
  },
  excel:
    "text-white bg-[--excel-color] px-2 py-1 rounded-md shadow-md lg:ml-4 md:ml-4",
  pdf: " text-white bg-[--pdf-color] px-2 py-1 rounded-md shadow-md lg:ml-4 md:ml-4 ",
  add_btn:
    " text-xs flex items-center size-5 text-[--excel-color] pi pi-plus  ring-2 ring-[--excel-color] rounded-full p-1",
  disable_btn:
    "text-xs flex items-center size-5 text-[--pdf-color] pi pi-times  ring-2 ring-[--pdf-color] rounded-full p-1",
  editbtn:
    "text-xs flex items-center size-5 pi pi-pencil text-[--primary-color] ring-2 ring-[--primary-color] rounded-full p-1",
  AlignItems: "flex items-center",
  justifyContent: "flex justify-around",
  ActionWidth: "w-32",
};

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "var(--secondary_color)",
  },
  tooltip: {
    border: "2px solid var(--secondary_color)",
    padding: "5px",
    marginTop: 5,
    marginBottom: 5,
    fontSize: 12,
    color:
      "linear-gradient(0deg, var(--primary-color) 0%, var(--secondary_color))",
    backgroundColor: "white",
    fontFamily: "fantasy",
  },
}));

export default function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export { COLORS, style };
