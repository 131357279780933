import { User } from "lucide-react";
import React, { MouseEventHandler } from "react";

interface ProfileContainerProps {
  profilePic?: any;
  firstName: string;
  lastName: string;
  onClick: (event?: MouseEventHandler<HTMLDivElement>) => void;
  img: any;
}

const ProfileContainer: React.FC<ProfileContainerProps> = ({
  profilePic,
  firstName,
  lastName,
  onClick,
  img,
}) => {
  return (
    <div
      className="relative group  w-7 h-7 rounded-[20px] bg-gray-200 flex items-center justify-center border border-[--primary-color] cursor-pointer"
      role="button"
      onClick={onClick}
    >
      {/* Profile Image or Icon */}
      {profilePic ? (
        <img src={img} alt="Profile" className="w-24 h-24 rounded-full" />
      ) : (
        <p className="sm:text-base">
          <User size={18} color="#1e0030" />
        </p>
      )}

      {/* Anchor Element (Tooltip) */}
      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full mt-2 hidden group-hover:block bg-gray-800 text-white text-xs rounded-lg py-1 px-2 shadow-lg">
        {firstName} {lastName}
      </div>
    </div>
  );
};

export default ProfileContainer;
