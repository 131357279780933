import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../context/AppContext";
import TabbedHeaderComponent from "../../components/CustomComponents/Tabs/TabbedHeaderComponent.tsx";
import { CommonComponent } from "../../service";
import {
  FiFileText,
  FiDownload,
  FiFilter,
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";
import { getCookie } from "../../service/get-user.js";

interface BaseData {
  approval_status: string;
  requested_on: string;
}

interface SchoolData extends BaseData {
  school_name: string;
  vendor_name: string;
}

interface VendorData extends BaseData {
  vendor_name: string;
  school_name: string;
  category: string;
}

interface PackageData extends BaseData {
  package_name: string;
  school_name: string;
  class_name: string;
}

const schoolColumns = [
  { header: "School Name", accessor: "school_name" as const },
  { header: "Vendor Name", accessor: "vendor_name" as const },
  { header: "Vendor email", accessor: "email" as const },
  { header: "School Code", accessor: "school_code" as const },
  { header: "Approval Status", accessor: "approval_status" as const },
  { header: "Requested On", accessor: "requested_on" as const },
];

const vendorColumns = [
  { header: "Vendor Name", accessor: "vendor_name" as const },
  { header: "Email Address", accessor: "email" as const },

  { header: "School Name", accessor: "school_name" as const },
  { header: "School Code", accessor: "school_code" as const },
  { header: "Approval Status", accessor: "approval_status" as const },
  { header: "Requested On", accessor: "requested_on" as const },
];

const packageColumns = [
  { header: "School Name", accessor: "school_name" as const },
  { header: "Class Name", accessor: "class_name" as const },
  { header: "Package Name", accessor: "package_name" as const },
  { header: "Package grandPrice", accessor: "grandprice" as const },
  { header: "Approval Status", accessor: "mapping_status" as const },
  { header: "Approved On", accessor: "approved_on" as const },
];

const TAB_HEADERS = [
  "Vendor Requests to Schools",
  "School Requests to Vendors",
  "Package Requests to School",
  "School Request to Package",
];

const req = new CommonComponent();
const auth = getCookie("loggedInUser");

export function MappingReports() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [schoolData, setSchoolData] = useState<SchoolData[]>([]);
  const [vendorData, setVendorData] = useState<VendorData[]>([]);
  const [packageData, setPackageData] = useState<PackageData[]>([]);
  const { setLoading, setSnackAlert }: any = useContext(AppContext);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const showError = (message: string) => {
    setSnackAlert({
      open: true,
      severity: "error",
      message,
    });
    setLoading(false);
  };

  const fetchData = async (
    endpoint: string,
    searchParams: object = {},
    setter: React.Dispatch<React.SetStateAction<any[]>>,
    errorMessage: string
  ) => {
    setLoading(true);
    try {
      const res = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        endpoint,
        JSON.stringify({ search: searchParams })
      );

      if (res.returnCode === 200) {
        if (endpoint === "getmapPackageToSchoolClasses") {
          setter(res.returnData);
        } else setter(res.returnData.rows);
      }
    } catch (err) {
      console.error(err);
      showError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllData = async () => {
    await Promise.all([
      fetchData(
        "getAllVendorMappingRequests",
        {},
        setVendorData,
        "Failed to fetch vendors to be approved"
      ),
      fetchData(
        "getAllSchoolMappingRequests",
        {},
        setSchoolData,
        "Failed to fetch schools to be approved"
      ),
      fetchData(
        "getmapPackageToSchoolClasses",
        {},
        setPackageData,
        "Failed to fetch package mappings"
      ),
    ]);
  };

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    let sub = true;

    if (sub) {
      getAllVendorMappingRequests();
      fetchAllData();
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => (sub = false);
  }, []);

  const getAllVendorMappingRequests = () => {
    let postObject = {
      auth,
    };

    setLoading(true);

    try {
      req
        .sendRequestToServer(
          "SchoolpayShoppingHelper",
          "getAllVendorMappingRequests",
          JSON.stringify(postObject)
        )
        .then((resp) => {
          const { returnCode, returnData, returnMessage } = resp;
          if (returnCode === 200) {
            setVendorData(returnData.rows);
            setLoading(false);
          } else {
            setLoading(false);
            showError(returnMessage);
          }
        });
    } catch (error) {
      setLoading(false);
      showError("error: " + error.message);
    }
  };

  const renderStatusBadge = (status: string) => {
    const statusStyles: { [key: string]: string } = {
      Approved: "bg-green-100 text-green-800",
      Rejected: "bg-red-100 text-red-800",
      "Not Approved": "bg-yellow-100 text-yellow-600",
      "Awaiting Review": "bg-yellow-100 text-yellow-600",
    };

    return (
      <span
        className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${
          statusStyles[status] || "bg-gray-100 text-gray-800"
        }`}
      >
        {status}
      </span>
    );
  };

  const handleExportExcel = () => {
    console.log("Exporting to Excel...");
  };

  const handleExportPDF = () => {
    console.log("Exporting to PDF...");
  };

  const filters = ["All", "Approved", "Awaiting Review", "Not Approved"];

  const handleFilterChange = (filter: string) => {
    setSelectedFilter(filter);
    setCurrentPage(1);
  };

  const renderTable = (
    data: any[],
    columns: { header: string; accessor: string }[]
  ) => {
    const rowsPerPage = 10;

    const filteredData = data
      ? selectedFilter === "All"
        ? data
        : data.filter((row) =>
            row.approval_status
              ? row.approval_status === selectedFilter
              : row.mapping_status === selectedFilter
          )
      : [];

    const totalPages = Math.max(
      1,
      Math.ceil(filteredData.length / rowsPerPage)
    );
    const paginatedData = filteredData.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    );

    const handlePageChange = (newPage: number) => {
      if (newPage >= 1 && newPage <= totalPages) {
        setCurrentPage(newPage);
      }
    };

    return (
      <div className="bg-white rounded-xl shadow-lg overflow-hidden relative border border-gray-100">
        <div className="flex justify-between items-center p-4 border-b border-gray-100 bg-gray-50">
          <div ref={dropdownRef} className="relative z-20">
            <button
              onClick={toggleDropdown}
              className="flex items-center gap-2 bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded-lg shadow-sm transition text-[--school-pay-primary-color] border-2"
            >
              <FiFilter
                size={16}
                className="text-[--school-pay-primary-color] font-extrabold"
              />
              {selectedFilter}
            </button>

            {isOpen && (
              <div className="absolute left-0 mt-2 w-40 bg-white border rounded-md shadow-lg transition-all duration-200">
                {filters.map((filter) => (
                  <button
                    key={filter}
                    onClick={() => {
                      handleFilterChange(filter);
                      setIsOpen(false);
                    }}
                    className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                  >
                    {filter}
                  </button>
                ))}
              </div>
            )}
          </div>

          <div className="flex gap-2">
            <button
              onClick={handleExportExcel}
              className="flex items-center gap-1.5 bg-emerald-500 hover:bg-emerald-600 text-white px-3 py-1.5 rounded-lg shadow-sm transition-all duration-200 font-medium text-sm"
            >
              <FiDownload className="w-4 h-4" />
              Export Excel
            </button>
            <button
              onClick={handleExportPDF}
              className="flex items-center gap-1.5 bg-rose-500 hover:bg-rose-600 text-white px-3 py-1.5 rounded-lg shadow-sm transition-all duration-200 font-medium text-sm"
            >
              <FiFileText className="w-4 h-4" />
              Export PDF
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse table-fixed">
            <thead className="bg-gradient-to-r from-[--school-pay-primary-color] to-[--school-pay-primary-color]/90 text-black shadow-md">
              <tr>
                <th className="px-4 py-2.5 text-left text-xs font-semibold uppercase tracking-wider w-10">
                  #
                </th>
                {columns.map((column, index) => (
                  <th
                    key={index}
                    className="px-4 py-2.5 text-left text-xs font-semibold uppercase tracking-wider w-[200px]"
                  >
                    {column.header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-100">
              {paginatedData.length > 0 ? (
                paginatedData.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className="transition-all duration-200 hover:bg-gray-50"
                  >
                    <td className="px-4 py-2 text-xs font-medium text-gray-900 w-10">
                      {(currentPage - 1) * rowsPerPage + rowIndex + 1}
                    </td>
                    {columns.map((column, colIndex) => (
                      <td
                        key={colIndex}
                        className="px-4 py-2 text-xs text-gray-700 whitespace-nowrap overflow-hidden text-ellipsis"
                      >
                        {column.accessor === "approval_status" ||
                        column.accessor === "mapping_status"
                          ? renderStatusBadge(row[column.accessor])
                          : row[column.accessor]}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={columns.length + 1}
                    className="px-4 py-8 text-center text-gray-500 bg-gray-50"
                  >
                    <div className="flex flex-col items-center justify-center space-y-1">
                      <span className="text-sm font-medium">
                        No records found
                      </span>
                      <span className="text-xs text-gray-400">
                        for "{selectedFilter}"
                      </span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center px-4 py-2 bg-gray-50 border-t border-gray-100">
          <span className="text-xs text-gray-700">
            Showing {(currentPage - 1) * rowsPerPage + 1} to{" "}
            {Math.min(currentPage * rowsPerPage, filteredData.length)} of{" "}
            {filteredData.length} entries
          </span>

          <div className="flex items-center space-x-2">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="flex items-center gap-1 px-3 py-1.5 bg-white text-gray-700 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed text-xs"
            >
              <FiChevronLeft className="w-4 h-4" />
              <span className="font-medium">Previous</span>
            </button>

            <div className="flex items-center px-3 py-1.5 bg-white rounded-lg border border-gray-200">
              <span className="text-xs font-medium text-gray-700">
                Page {currentPage} of {totalPages}
              </span>
            </div>

            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="flex items-center gap-1 px-3 py-1.5 bg-white text-gray-700 rounded-lg border border-gray-200 hover:bg-gray-50 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed text-xs"
            >
              <span className="font-medium">Next</span>
              <FiChevronRight className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    const contentMap = [
      {
        data: vendorData,
        columns: vendorColumns,
        title: TAB_HEADERS[0],
      },
      {
        data: schoolData,
        columns: schoolColumns,
        title: TAB_HEADERS[1],
      },
      {
        data: packageData,
        columns: packageColumns,
        title: TAB_HEADERS[2],
      },
      {
        data: packageData,
        columns: packageColumns,
        title: "Package Mapping Requests",
      },
    ];

    const currentContent = contentMap[activeIndex];

    return (
      <div className="space-y-4">
        <h2 className="text-xl font-semibold text-gray-800">
          {currentContent.title}
        </h2>
        {renderTable(currentContent.data, currentContent.columns)}
      </div>
    );
  };

  return (
    <div className="p-6">
      <div className="mb-6">
        <h1 className="text-3xl font-light text-gray-900">Mapping Reports</h1>
      </div>

      <div className="mb-6">
        <TabbedHeaderComponent
          headers={TAB_HEADERS}
          activeIndex={activeIndex}
          onHeaderClick={(index) => () => setActiveIndex(index)}
        />
      </div>

      <div className="bg-gray-50 p-6 rounded-lg">{renderContent()}</div>
    </div>
  );
}

export default MappingReports;
