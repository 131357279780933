/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import SnackBarAlert from "../../components/CustomComponents/Alerts/SnackBarAlert.tsx";

import TabbedHeaderComponent from "../../components/CustomComponents/Tabs/TabbedHeaderComponent.tsx";
import { AppContext } from "../../context/AppContext.js";
import { CommonComponent } from "../../service/CommonComponent.js";
import { getCookie } from "../../service/get-user.js";
import SchoolClassToPackage from "./SchoolPackageMappingManagement.tsx";
import SchoolToVendor from "./SchoolVendor.tsx";
import SchoolToPackage from "./SchoolToPackage.tsx";

type Props = {};

const SchoolMappingManagement = (props: Props) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [msg, setMsg] = React.useState({
    show: false,
    severity: "error",
    message: "",
  });

  const [packageData, setPackageData] = React.useState([]);

  const navigate = useNavigate();
  const { setLoading, setSnackAlert }: any = React.useContext(AppContext);
  const { schoolCode } = useParams();

  let listData = [];
  let schools = [];
  // let ProductIds: any = [];

  const renderComponent = () => {
    switch (activeIndex) {
      case 0:
        return (
          <div>
            <SchoolToVendor schoolCode={schoolCode} />
          </div>
        );
      case 1:
        return (
          <div>
            <SchoolClassToPackage />
          </div>
        );
      case 2:
        return (
          <div>
            <SchoolToPackage schoolCode={schoolCode} />
          </div>
        );

      default:
        return (
          <div className="flex justify-center items-center pt-12 text-2xl text-gray-400">
            No component found
          </div>
        );
    }
  };
  return (
    <>
      <div className="p-4 sticky">
        <div className="py-3 px-2">
          <h3 className="lg:text-3xl md:text-2xl sm:text-lg font-light transition ease duration-500">
            Mapping Management
          </h3>
        </div>

        <div className="my-3">
          <TabbedHeaderComponent
            headers={[
              "Map School to Vendor",
              "Map Package to Class",
              "Map Package to School",
            ]}
            activeIndex={activeIndex}
            onHeaderClick={(index) => () => setActiveIndex(index)}
          />
        </div>
        <div className="my-3 py-1">{renderComponent()}</div>
      </div>
      <SnackBarAlert
        open={msg.show}
        onClose={() =>
          setMsg({
            show: false,
            message: "",
            severity: "",
          })
        }
        message={msg.message}
        severity={msg.severity}
      />
    </>
  );
};

export default SchoolMappingManagement;
