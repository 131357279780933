import { ListCollapse, Pencil, Plus } from "lucide-react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import DottedButton from "../Buttons/DottedButton.tsx";

interface TableProps<T> {
  data: T[];
  columns: { header: string; accessor: keyof T }[] | any;
  editAction?: { label: string; onclick: (row: T) => void };
  addAction?: { label: string; onclick: (row: T) => void } | any;
  createAction?: { label: string; onclick: () => void };
  detial?: { label: string; onclick: (row: T) => void };
  filters?: { label: string; value: string }[];
  loading?: boolean;
  excel?: { label?: string; onclick?: any } | any;
  pdf?: { label?: string; onclick?: any } | any;
  header?: boolean;
  actionComponent?: any;
  onRowClick?: (row: T) => void;
  renderCell?: (accessor: keyof T, row: T) => React.ReactNode;
}

const CustomTable = <T,>({
  data,
  columns,
  editAction,
  addAction,
  detial,
  createAction,
  filters = [],
  loading = false,
  excel,
  pdf,
  header = true,
  actionComponent,
  onRowClick,
  renderCell,
}: TableProps<T>) => {
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [paginatedData, setPaginatedData] = useState<T[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [dropdownOpen, setDropdownOpen] = useState<number | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [activeFilter, setActiveFilter] = useState<string>("All");
  const dropdownRefs = useRef<(HTMLDivElement | null)[]>([]);

  // Filter and search logic
  const filteredData = useMemo(() => {
    return data.filter((item: any) => {
      // Search filter
      const searchMatch = Object.values(item).some((value) =>
        String(value).toLowerCase().includes(searchQuery.toLowerCase())
      );
      // Category filter (applies to all columns now)
      const filterMatch =
        activeFilter === "All" ||
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(activeFilter.toLowerCase())
        );
      return searchMatch && filterMatch;
    });
  }, [data, searchQuery, activeFilter]);

  useEffect(() => {
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const newPaginatedData = filteredData.slice(start, end);
    setPaginatedData(newPaginatedData);
    setTotalPages(Math.ceil(filteredData.length / itemsPerPage));
  }, [currentPage, itemsPerPage, filteredData]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRefs.current.some(
          (ref, index) =>
            ref && dropdownOpen === index && !ref.contains(event.target as Node)
        )
      ) {
        setDropdownOpen(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownOpen]);

  // Exclude the 'id' column from being displayed
  const filteredColumns = columns.filter((column) => column.accessor !== "id");

  const handleDropdownToggle = (index: number) => {
    setDropdownOpen(dropdownOpen === index ? null : index); // Toggle and close previous
  };

  const handleFilterButtonClick = (filter: string) => {
    setActiveFilter(filter);
    setCurrentPage(1); // Reset to the first page when filter changes
  };

  const DROPDOWN_ITEMS = [
    {
      label: editAction?.label,
      onClick: (item) => editAction?.onclick(item),
      icon: <Pencil size={18} color={"gray"} />,
    },
    {
      label: addAction?.label,
      onClick: (item) => addAction?.onclick(item),
      icon: <Plus size={18} color={"gray"} />,
    },
    {
      label: detial?.label,
      onClick: (item) => detial?.onclick(item),
      icon: <ListCollapse size={18} color={"gray"} />,
    },
  ];

  return (
    <div className="bg-white rounded-lg">
      <div className="bg-white h-full py-2 mx-2 rounded-3xl -mt-4">
        {header && (
          <div className="flex flex-row justify-between mb-2 py-2">
            <select
              value={activeFilter}
              onChange={(e) => handleFilterButtonClick(e.target.value)}
              className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[--primary-color]"
            >
              <option value="" disabled>
                {" "}
                Select Status
              </option>
              {filters.map((status) => (
                <option key={status.value} value={status.value}>
                  {status.label}
                </option>
              ))}
            </select>

            <div className="flex space-x-2 px-4">
              <input
                type="text"
                className="px-3 rounded-md border border-gray-300 text-xs"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {excel && (
                <div className="flex flex-row justify-evenly items-center text-white bg-[--excel-color] px-2 py-1 rounded-md shadow-md transition-colors duration-300">
                  <i className="pi pi-file-excel" />
                  <button
                    className="px-1 rounded-md text-xs font-extrabold cursor-pointer"
                    onClick={() => {
                      excel.onclick
                        ? excel.onclick()
                        : alert("Excel Export function !");
                    }}
                  >
                    {excel.label ? excel.label : "Excel"}
                  </button>
                </div>
              )}
              {pdf && (
                <div className="flex flex-row justify-evenly items-center text-white bg-[--pdf-color] px-2 py-1 rounded-md shadow-md transition-colors duration-300">
                  <i className="pi pi-file-pdf" />
                  <button
                    className="px-1 rounded-md text-xs font-extrabold cursor-pointer"
                    onClick={() => {
                      pdf.onclick
                        ? pdf.onclick()
                        : alert("PDF Export function not provided!");
                    }}
                  >
                    {pdf.label ? pdf.label : "PDF"}
                  </button>
                </div>
              )}
              <button
                className="bg-[--primary-color] text-white px-2 rounded-md text-xs font-extrabold shadow-md uppercase transition-colors duration-300 cursor-pointer hover:shadow-none"
                onClick={() => {
                  if (createAction?.onclick) {
                    createAction.onclick();
                  }
                }}
                title={createAction?.label}
              >
                <Plus size={22} />
              </button>
            </div>
          </div>
        )}

        <div className="overflow-auto bg-white min-h-[45vh]">
          <table className="min-w-full bg-white text-xs overflow-x-auto">
            <thead className="w-full bg-gray-200 text-gray-700 sticky top-0 z-10">
              <tr className="bg-[--secondary_color] text-white">
                <th className="py-2 px-4 text-left whitespace-nowrap">No.</th>
                {filteredColumns.map((column, index) => (
                  <th
                    key={index}
                    className="py-2 px-4 text-left font-semibold capitalize text-white whitespace-nowrap"
                  >
                    {column.header}
                  </th>
                ))}
                {header && (
                  <th className="py-2 px-4 text-left whitespace-nowrap">
                    Action
                  </th>
                )}
              </tr>
            </thead>
            {loading ? (
              <div className="flex justify-center items-center h-full">
                <div className="loader ease-in-out rounded-full border-8 border-t-8 border-gray-200 h-20 w-20"></div>
              </div>
            ) : (
              <tbody className="text-gray-600 mb-10">
                {paginatedData.length > 0 ? (
                  paginatedData.map((row, rowIndex) => (
                    <tr
                      key={rowIndex}
                      className={`border-b py-2 capitalize ${
                        rowIndex % 2 === 0 ? "bg-gray-100" : "bg-white"
                      } ${onRowClick ? "cursor-pointer" : ""}`}
                      onClick={() => onRowClick && onRowClick(row)} // Add onClick event
                    >
                      <td className="px-4 font-bold whitespace-nowrap">
                        {(currentPage - 1) * itemsPerPage + rowIndex + 1}
                      </td>
                      {filteredColumns.map((column, colIndex) => (
                        <td
                          key={colIndex}
                          className="py-1 px-4 whitespace-nowrap overflow-hidden text-ellipsis max-w-[150px]"
                        >
                          {renderCell ? (
                            renderCell(column.accessor, row)
                          ) : column.header.includes("Status") ? (
                            <span
                              className={`px-2 py-1 rounded-lg ${
                                row[column.accessor] === "Rejected"
                                  ? "text-red-600 bg-red-100"
                                  : row[column.accessor] === "Approved"
                                  ? "text-green-600 bg-green-100"
                                  : row[column.accessor] === "Awaiting Approval"
                                  ? "text-[--primary-color] bg-orange-100"
                                  : ""
                              }`}
                            >
                              {(row as any)[column.accessor]}
                            </span>
                          ) : (
                            (row as any)[column.accessor]
                          )}
                        </td>
                      ))}
                      {!actionComponent ? (
                        <td
                          ref={(el) => (dropdownRefs.current[rowIndex] = el)}
                          className="whitespace-nowrap"
                        >
                          <DottedButton
                            items={DROPDOWN_ITEMS}
                            dropdownDirection="down"
                            row={row}
                            dropdownOpen={dropdownOpen === rowIndex}
                            onToggleDropdown={() =>
                              handleDropdownToggle(rowIndex)
                            }
                            onClick={(e) => e.stopPropagation()}
                          />
                        </td>
                      ) : (
                        <td
                          className="py-2 px-4 flex items-center whitespace-nowrap"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {actionComponent(row)}
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={filteredColumns.length + 1}
                      className="text-center py-4 text-gray-400 text-2xl"
                    >
                      No mapped product on this package found.
                    </td>
                  </tr>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
      <div className="flex flex-col items-center mt-1">
        <div className="flex items-center space-x-2 mb-2">
          <select
            value={currentPage}
            onChange={(e) => setCurrentPage(Number(e.target.value))}
          >
            {Array.from({ length: totalPages }, (_, index) => (
              <option key={index + 1} value={index + 1}>
                Page {index + 1}
              </option>
            ))}
          </select>
          <nav className="flex items-center space-x-2">
            <button
              onClick={() =>
                setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)
              }
              aria-label="Previous Page"
            >
              &laquo;
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                className={`px-3 py-1 rounded-md border text-xs transition-colors ${
                  currentPage === index + 1
                    ? "bg-[--primary-color] text-white"
                    : "bg-white text-gray-700 hover:bg-gray-100"
                }`}
                onClick={() => setCurrentPage(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() =>
                setCurrentPage(
                  currentPage < totalPages ? currentPage + 1 : totalPages
                )
              }
              aria-label="Next Page"
            >
              &raquo;
            </button>
          </nav>
          <select
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
              setCurrentPage(1);
            }}
          >
            <option value={5}>5 rows</option>
            <option value={10}>10 rows</option>
            <option value={25}>25 rows</option>
            <option value={50}>50 rows</option>
            <option value={100}>100 rows</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
