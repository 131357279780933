import React, { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";

export default function MessagesComponent({ messageType, message }) {
  const toast = useRef(null);

  const showMessage = () => {
    switch (messageType) {
      case "Success":
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: message,
        });
        break;
      case "Error":
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: message,
        });
        break;
      case "Info":
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: message,
        });
        break;
      case "Warning":
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: message,
        });
        break;
      default:
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: message,
        });
        break;
    }
  };

  // toast.current.show({ severity: "info", summary: "Info", detail: message });
  useEffect(() => showMessage());

  return <Toast ref={toast} position="center" />;
}
