import React from "react";

type Props = {
  items: any[];
  dropdownDirection?: string;
  buttonStyle?: string;
  icon?: React.ReactNode;
  row?: any;
  dropdownOpen: boolean; // Controlled by parent component
  onToggleDropdown: () => void; // Function to toggle dropdown, passed from parent
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void; // Add onClick prop
};

const DottedButton = ({
  items,
  dropdownDirection = "down",
  buttonStyle = "",
  icon,
  row,
  dropdownOpen, // Controlled from the parent component
  onToggleDropdown, // Function to toggle dropdown passed from parent
  onClick,
}: Props) => {
  return (
    <div className="relative">
      {/* Reusable Button */}
      <button
        className={`text-gray-500 hover:text-gray-700 focus:outline-none ${buttonStyle}`}
        onClick={(e) => {
          if (onClick) onClick(e); // Call the onClick prop if provided
          onToggleDropdown(); // Use the passed toggle function
        }} // Use the passed toggle function
      >
        {icon || (
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 6v.01M12 12v.01M12 18v.01"
            ></path>
          </svg>
        )}
      </button>

      {dropdownOpen && (
        <div
          className={`absolute -ml-20 z-[1000000000] ${dropdownDirection === "up" ? "bottom-full mb-1" : "top-full mt-2"
            } left-0 bg-white shadow-md rounded-sm z-50 w-40 overflow-hidden`}
        >
          <ul className="py-1">
            {items.map((item, index) => (
              <li
                key={index}
                className="px-2 py-1 text-sm text-gray-700 hover:bg-gray-200 cursor-pointer"
                onClick={(e) => {
                    e.stopPropagation();
                  if (item.onClick) {
                    item.onClick(row); // Trigger the action
                  }
                  onToggleDropdown(); // Close dropdown after item click
                }}
              >
                <div className="flex justify-evenly capitalize hover:text-[--secondary_color] font-light hover:font-medium">
                  {item.icon && item.label && (
                    <div className="flex items-start justify-start min-w-5">
                      {item.icon}
                    </div>
                  )}
                  {item.label}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DottedButton;
