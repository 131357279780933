import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import "../../App.scss";
import CustomAlert from "../../components/CustomComponents/Alerts/CustomAlert.tsx";
import CustomDialog from "../../components/CustomComponents/Dialogs/CustomDialog.tsx";
import { AppContext } from "../../context/AppContext.js";
import "../../layout/layout.scss";
import { CommonComponent } from "../../service";

// Validation Schema
const validationSchema = Yup.object({
  school_code: Yup.string()
    .required("School code is required")
    .matches(/^\d{3}$/, "School code must be exactly 3 digits"),
});

const initialValues = {
  school_code: "",
};

const EnrollSchoolFromShoolPay = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setFormValue] = useState(initialValues);
  const [viewProfile, setViewProfile] = useState(false);
  const [code, setCode] = useState("");
  const [opened, setOpened] = useState(false);

  const navigation = useNavigate();
  const commonComponent = new CommonComponent();
  const { snackAlert, setSnackAlert, setLoading } = useContext(AppContext);

  // Handle Form Submission
  const handleFormSubmit = async (values) => {
    setLoading(true);

    try {
      const postObject = {
        // auth: getCookie("loggedInUser"),
        schoolCode: code,
        schoolDetails: {
          schoolCode: code,
        },
      };

      const data = await commonComponent.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "getSchoolBySchoolCode",
        JSON.stringify(postObject)
      );

      const { returnCode, returnMessage } = data;

      switch (returnCode) {
        case 100:
          setSnackAlert({
            open: true,
            message: returnMessage,
            severity: "error",
          });
          break;
        case 409:
          setSnackAlert({
            open: true,
            message: "School already exists in our database.",
            severity: "success",
          });

          setViewProfile(true);

          break;
        case 401:
          setSnackAlert({
            open: true,
            message: returnMessage,
            severity: "error",
          });
          break;
        case 404:
          setTimeout(() => {
            setOpened(true);
          }, 1000);

          setSnackAlert({
            open: true,
            message: "School Code is Valid",
            severity: "success",
          });
          break;

        default:
          setSnackAlert({
            open: true,
            message: returnMessage,
            severity: "error",
          });
          break;
      }

      setLoading(false);
    } catch (error) {
      console.warn(error);

      setLoading(false);
      setSnackAlert({
        open: true,
        message: "An error occurred. Please try again.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const enrollSchool = async () => {
    setLoading(true);

    setOpened(false);
    const postObject = {
      // auth: getCookie("loggedInUser"),
      instance: "ENROLL",
      schoolCode: code,
      // userId: getCookie("loggedInUser").user.id,
      // schoolDetails: {
      //   // auth: {
      //   //   hash: "ghjkERTYUIOPl",
      //   //   nonce: "ghjkl,",
      //   //   accessCode: "HEALTHPAL",
      //   // },
      //   schoolCode: code,
      // },
    };
    try {
      const data = await commonComponent.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "enrollSchool",
        JSON.stringify(postObject)
      );

      const { returnCode, returnMessage } = data;
      alert(returnMessage);
      if (returnCode === 100) {
        setSnackAlert({
          open: true,
          message: returnMessage,
          severity: "error",
        });
      } else if (returnCode === 409) {
        setSnackAlert({
          open: true,
          message: returnMessage,
          severity: "success",
        });

        setViewProfile(true);
        setFormValue(initialValues); // Reset form on success
      } else if (returnCode === 201) {
        setSnackAlert({
          open: true,
          message: returnMessage,
          severity: "success",
        });

        setTimeout(() => {
          setSnackAlert({ open: false });
          handleViewProfile();
        }, 2000);
      } else if (returnCode === 404) {
        setSnackAlert({
          open: true,
          message: returnMessage,
          severity: "success",
        });
      } else if (returnCode === 401) {
        setSnackAlert({
          open: true,
          message: "Code provided does not exist. Please enter a valid code ",
          severity: "error",
        });
      } else {
        setSnackAlert({
          open: true,
          message: returnMessage,
          severity: "error",
        });
      }
    } catch (error) {
      setSnackAlert({
        open: true,
        message:
          "An error occurred while enrolling the specified school(s). Please try again.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleViewProfile = () => {
    navigation(`/schoolpay/view-school-profile/${code}`);
  };

  return (
    <>
      <div className="w-screen flex items-center justify-center">
        <div
          id="main_body"
          className="col-sm-10 enroll_school_c d-flex justify-content-center align-items-start flex-column overflow-hidden my-3 w-[60%]"
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              handleBlur,
              handleSubmit,
              handleChange,
              touched,
              values,
              errors,
              setErrors,
            }) => (
              <form
                onSubmit={handleSubmit}
                className="overflow-hidden bg-[whitesmoke] shadow-md rounded-md w-[75%] mx-auto py-16"
              >
                <h2 className="border-b border-b-[#43C6DB] text-base lg:text-2xl font-semibold mx-5 text-gray-600 capitalize">
                  enroll a school
                </h2>
                <div className="inpu_and_label_c">
                  {snackAlert.open && (
                    <CustomAlert
                      message={snackAlert.message}
                      open={snackAlert.open}
                      onClose={() =>
                        setSnackAlert({ ...snackAlert, open: false })
                      }
                      severity={snackAlert.severity}
                    />
                  )}
                </div>

                <div className="flex flex-col px-12 my-4">
                  <label htmlFor="school_code">
                    <div className="txt_n_star">
                      School Code <span className="required_field">*</span>
                    </div>
                  </label>
                  <input
                    type="text"
                    name="school_code"
                    value={values.school_code}
                    id="school_code"
                    onChange={(e) => {
                      handleChange(e);
                      setCode(e.target.value);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      setErrors(errors);
                    }}
                    className={`border-2 border-black px-2 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow-sm focus:outline-none focus:ring ease-linear transition-all duration-150 ${
                      touched.school_code &&
                      errors.school_code &&
                      "school_code_input_error"
                    }`}
                    placeholder="Enter School Code"
                    minLength={3}
                    maxLength={3}
                    required
                  />
                  {touched.school_code && errors.school_code && (
                    <p className="text_error">{errors.school_code}</p>
                  )}
                </div>

                <div className="flex w-[90%] mx-auto justify-end">
                  <div>
                    {viewProfile && (
                      <button
                        onClick={handleViewProfile}
                        id="send-btn"
                        className="py-1 px-3 bg-[#43C6DB] rounded-md border border-black text-white text-sm hover:opacity-[.9] mr-5"
                      >
                        View Profile
                      </button>
                    )}
                  </div>

                  <div>
                    <button
                      type="submit"
                      disabled={
                        touched.school_code && errors.school_code ? true : false
                      }
                      id="send-btn"
                      className="py-1 px-3 bg-[#43C6DB] rounded-md border border-black text-white text-sm hover:opacity-[.9] "
                    >
                      Enroll
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>

      <CustomDialog
        open={opened}
        buttonTitle="Enroll"
        message="This school is not registered in our database. Would you like to enroll this school onto the database?"
        onClose={() => setOpened((prev) => !prev)}
        onSubmit={enrollSchool}
        title="Enrollment Alert"
      />
    </>
  );
};

export default EnrollSchoolFromShoolPay;
