import { shoppingCartConstants } from "../constants";

interface AddressData {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  postalCode: string;
  phone: string;
}

interface Product {
  id: string;
  product_name: string;
  price_per_unit: number;
  qty_available: number;
  status: string;
  category: string;
  subcategory: string;
  productbrand: string;
  quantity: number;
  totalCost: number;
  productImage: string;
  selected: boolean;
  productUnit: string;
  productUnitId: string;
  productId: number;
}

interface CheckoutState {
  step: number;
  shippingAddress: AddressData | null;
  billingAddress: AddressData | null;
  sameAsBilling: boolean;
  deliveryMethod: "pickup" | "delivery";
  selectedPickupStation: string;
  paymentMethod: string;
  editingShipping: boolean;
  editingBilling: boolean;
  currentView: string;
  cartItems: Product[];
}

const initialState: CheckoutState = {
  step: 1,
  shippingAddress: null,
  billingAddress: null,
  sameAsBilling: false,
  deliveryMethod: "delivery",
  selectedPickupStation: "",
  paymentMethod: "card",
  editingShipping: true,
  editingBilling: true,
  currentView: "",
  cartItems: (() => {
    const savedCartItems = localStorage.getItem("cartItems");
    try {
      return savedCartItems ? JSON.parse(savedCartItems).cartItems || [] : [];
    } catch (e) {
      console.error("Error parsing cartItems from localStorage:", e);
      return [];
    }
  })(),
};

function shoppingCartReducer(state = initialState, action) {
  switch (action.type) {
    case shoppingCartConstants.ADD_TO_CART:
      const existingItemIndex = state.cartItems.findIndex(
        (item) => item.productId === action.payload.product_id
      );

      if (existingItemIndex >= 0) {
        // If item exists, increase its quantity
        const updatedCartItems = [...state.cartItems];
        updatedCartItems[existingItemIndex] = {
          ...updatedCartItems[existingItemIndex],
          quantity: updatedCartItems[existingItemIndex].quantity + 1,
        };

        return {
          ...state,
          cartItems: updatedCartItems,
        };
      }

      // If item is new, add it with quantity 1
      return {
        ...state,
        cartItems: [...state.cartItems, { ...action.payload, quantity: 1 }],
      };
    case shoppingCartConstants.UPDATE_STEP_ON_CHECKOUT:
      return {
        ...state,
        currentView: action.payload,
      };
    case shoppingCartConstants.SET_STEP:
      return {
        ...state,
        step: action.payload,
      };
    case shoppingCartConstants.SET_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload,
        editingShipping: false,
        ...(state.sameAsBilling && {
          billingAddress: action.payload,
          editingBilling: false,
        }),
      };
    case shoppingCartConstants.SET_BILLING_ADDRESS:
      return {
        ...state,
        billingAddress: action.payload,
        editingBilling: false,
      };
    case shoppingCartConstants.SET_SAME_AS_BILLING:
      return {
        ...state,
        sameAsBilling: action.payload,
        ...(action.payload &&
          state.shippingAddress && {
            billingAddress: state.shippingAddress,
            editingBilling: false,
          }),
      };
    case shoppingCartConstants.SET_DELIVERY_METHOD:
      return {
        ...state,
        deliveryMethod: action.payload,
      };
    case shoppingCartConstants.SET_PICKUP_STATION:
      return {
        ...state,
        selectedPickupStation: action.payload,
      };
    case shoppingCartConstants.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case shoppingCartConstants.SET_EDITING_SHIPPING:
      return {
        ...state,
        editingShipping: action.payload,
      };
    case shoppingCartConstants.SET_EDITING_BILLING:
      return {
        ...state,
        editingBilling: action.payload,
      };
    case shoppingCartConstants.CLEAR_CART:
      return {
        ...state,
        cartItems: [],
      };
    case shoppingCartConstants.RESET_CHECKOUT:
      return initialState;
    case shoppingCartConstants.CLEAR_REDUX_STATE:
      return initialState;
    default:
      return state;
  }
}

export default shoppingCartReducer;
