export const routings = {
  test: "/tests",
  login: "/login",
  register: "/register",
  forgot_password: "/forgot-password",
  active_vendors: "/view-active-vendors",
  mapping_requests: "/schoolpay-mapping-requests",
  packages: "/packages",
  add_packages: "/create-package",
  vendor_dashboard: "/vendor-dashboard",
  admin_dashboard: "/admin-dashboard",
  dashboard: "/dashboard",
  register_customer: "/register-customer",
  register_vendor: "/register_vendor",

  enroll_school: "/enroll-school",
  view_school_profile: "/view-school-profile/:id",
  bulk_enroll_schools: "/bulk-enroll-schools",
  commission_tier: "/commission-tier",
  vendor_profile: "/view-vendor-profile/:vendorId",
  vendor_history: "/view-vendor-history/:vendorId",
  vendor_transactions: "/view-vendor-transactions/:vendorId",
  pending_approvals: "/pending-approvals",
  product_mapping: "/package-product-mapping/",
  view_products: "/products",
  add_user: "/add-user",
  package_details: "/mapping-package-details/",

  school_pay: {
    enroll_school: "/enroll_school",
    view_school_profile: "/schoolpay/view-school-profile/",
    extra_info: "/schoolpay/extra-info/",
    mapping_requests: "/schoolpay-mapping-requests",
  },
  dashboard_test: "/new-test-dashboard",
  vendorMapping: "/vendor-mapping",
  package_approvals: "/package-approvals",
  customer_details: "/customer-details/:customerId",
  testcode: "/testcode",
  home: "/",
  sub_categories: "/add-sub-categories/:categoryId/:categoryName",
  view_users: "/users",
  terms_conditions: "/terms-conditions",
  partner_registration: "/partner-registration",
  mapping_reports: "/schoolpay-mapping-reports",
  school_package_infor: "/school-Packageinfor/:schoolpackageId",
  vendor_mapping_management: "/vendor-mapping-management",
  vendor_mapping_requests: "/vendor-mapping-requests",
  school_mapping_request: "/school-mapping-requests/:schoolCode",
  vendor_mapping_report: "/vendor-mapping-reports",
  wishlist: "wishlist",
  cart: "cart",
  notifications: "notifications",
  messages: "messages",
  add_sub_category: "/add-product-sub-category",
  schoolpay_package_infor: "/schoolpay-Packageinfor/:schoolpackageId",
  vendor_mapping_request: "/vendor_mapping_request",
  school_mapping_report: "/school-mapping-reports/:schoolCode",
  school_mapping_management: "/school-mapping-management/:schoolCode",
  pangisa_mapping_management: "/pangisa-mapping-management",
  admin_mapping_management: "/admin-mapping-management",
  schoolpay_mapping_management: "/schoolpay-mapping-management",
  school_package_mapping_management:
    "/school-package-mapping-management/:schoolCode",
  user_profile: "/myaccount",
};
