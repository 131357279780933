export const packageColumns = [
  { header: "Package Name", accessor: "package_name" },
  { header: "Package Price", accessor: "package_price" },
  { header: "Package Type", accessor: "package_type" },
  { header: "Created On", accessor: "created_on" },
  { header: "Expires On", accessor: "date_before_end_date" },
  { header: "Package Status", accessor: "status" },
];

export const HEADERS = [
  "Map Vendor To School",
  "Map School to Vendor",
  "Map Package to School",
];

export const columns = [
  {
    header: "School Name",
    accessor: "school_name",
  },
  {
    header: "School Code",
    accessor: "school_code",
  },
  {
    header: "P.O Box Number",
    accessor: "physical_addr",
  },
  {
    header: "Enrolled On",
    accessor: "enrolled_on",
  },
  {
    header: "Nature Of School",
    accessor: "school_type",
  },
  {
    header: "Status",
    accessor: "status",
  },
];

export const vendorColumns = [
  {
    header: "Vendor Name",
    accessor: "user_name",
  },
  {
    header: "Phone Number",
    accessor: "phone_no",
  },
  {
    header: "Email Address",
    accessor: "email_address",
  },
  {
    header: "Enrolled On",
    accessor: "created_on",
  },
  {
    header: "User Type",
    accessor: "user_type",
  },
  {
    header: "Status",
    accessor: "status",
  },
];
