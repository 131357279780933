import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

export default function CustomDialog({
  open = false,
  onClose,
  message = "Are you sure you want to proceed?",
  buttonTitle = "Submit",
  title = "Confirmation",
  onSubmit,
}: {
  open: boolean;
  onClose: () => void;
  message: string;
  buttonTitle: string;
  title: string;
  onSubmit?: () => void;
}) {
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={false}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className="form-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <div className="flex flex-row w-[105%] overflow-hidden justify-end items-center"> */}
        {/* <div> */}
        <button
          onClick={onClose}
          id="send-btn"
          className="send-btn hover:opacity-[.9] mr--5"
        >
          Cancel
        </button>
        {/* </div> */}

        {/* <div className="px-4"> */}
        <button
          onClick={onSubmit}
          type="submit"
          id="send-btn"
          className="send-btn hover:opacity-[.9] bg-[--secondary_color] text-white rounded-xl py-1 px-4"
        >
          {buttonTitle}
        </button>
        {/* </div> */}
        {/* </div> */}
      </DialogActions>
    </Dialog>
  );
}
