import "bootstrap/dist/css/bootstrap.css";
import * as React from "react";
import "react-app-polyfill/ie11";
import { CookiesProvider } from "react-cookie";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App.tsx";
import store from "./redux/helpers/store.ts";

const Pangisa = () => {
  return (
    <CookiesProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </CookiesProvider>
  );
};

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Pangisa />);
