import {lazy} from "react";
import AddSystemUsers from "../components/Authentication/OtherSystemUsers/AddSystemUsers.jsx";
import asyncComponent from "../components/GenericComponents/AsyncComponent.jsx";
import AddProductBrand from "../views/ProductConfigurations/AddProductBrand.tsx";
import CommissionManagement from "../views/ProductConfigurations/CommisssionManagement.jsx";

import AddPackage from "../views/Packages/AddPackage.tsx";
import EditPackage from "../views/Packages/EditPackage.tsx";
import PackageDetails from "../views/Packages/PackageDetails.tsx";
import Packages from "../views/Packages/Packages.tsx";

// import UserForm from "../components/ClientRegistration/UserForm.js";
import UserDetails from "../components/Packages/UserDetails.jsx";
import ImageUpload from "../shared/ImageUpload.js";
import NewAdminDashboard from "../views/Dashboard/NewAdminDashboard.tsx";
import MappingReports from "../views/Mappings/MappingReports.tsx";
import SchoolpackageInfor from "../views/Mappings/SchoolpackageInfor.tsx";
import VendorMapping from "../views/Mappings/VendorMapping.tsx";
import VendorMappingManagement from "../views/Mappings/VendorSchoolMapping/VendorMappingManagement.tsx";
import VendorMappingReports from "../views/Mappings/VendorSchoolMapping/VendorMappingReports.tsx";
import PackageMapping from "../views/Packages/PackageMapping.tsx";
import PackageApprovalDetails from "../views/PendingApprovals/PackageApprovalDetails.tsx";
import PackageApprovals from "../views/PendingApprovals/PackageApprovals.tsx";
import CommissionProfile from "../views/ProductConfigurations/CommissionProfile.tsx";
import Products from "../views/ProductConfigurations/Products/Products.tsx";
import EnrollSchoolFromShoolPay from "../views/SchoolEnrollment/EnrollSchoolFromShoolPay.tsx";
import {routings} from "./routings.ts";

import AdminMappingManagement from "../views/Mappings/AdminMappingManagement.tsx";
import SchoolMappingManagement from "../views/Mappings/SchoolMappingManagement.tsx";
import SchoolMappingReports from "../views/Mappings/SchoolMappingReport.tsx";
import SchoolPackageMappingManagement from "../views/Mappings/SchoolPackageMappingManagement.tsx";
import SchoolPayMappingManagement from "../views/Mappings/SchoolPayMappingManagement.tsx";
import SchoolMappingRequests from "../views/Mappings/VendorSchoolMapping/SchoolMappingRequests.tsx";
import VendorMappingRequests from "../views/Mappings/VendorSchoolMapping/VendorMappingRequests.tsx";

const SchoolPayProfile = lazy(
  () => import("../views/SchoolEnrollment/SchoolPayProfile.tsx")
);

const MappingManagement = lazy(
  () => import("../views/Mappings/MappingManagement.tsx")
);
const MappingRequests = lazy(
  () => import("../views/Mappings/MappingRequests.tsx")
);
const PendingApprovals = lazy(
  () => import("../views/PendingApprovals/PendingApprovals.tsx")
);

const VendorsList = lazy(() => import("../views/Vendors/VendorsList.tsx"));

const SchoolProfile = lazy(
  () => import("../components/SchoolpayShopping/profiles/SchoolProfile.tsx")
);
const VendorProfile = lazy(
  () => import("../components/SchoolpayShopping/profiles/VendorProfile.tsx")
);
const AddCommissionDescription = lazy(
  () => import("../components/SchoolpayShopping/AddCommissionDescription.tsx")
);
const CommissionView = lazy(
  () => import("../components/SchoolpayShopping/Commissions.tsx")
);
const RegsiterSystemModule = lazy(
  () => import("../components/SchoolpayShopping/RegisterSystemModule.js")
);
const ViewSystemModules = lazy(
  () => import("../components/SchoolpayShopping/ViewSystemModules.js")
);
// const AccountProfile = lazy(() =>
//   import("../components/GenericComponents/AccountProfile")
// );
const Dashboard = lazy(() => import("../components/Dashboard.tsx"));
const VendorDashboard = lazy(
  () => import("../views/Dashboard/NewVendorDashboard.tsx")
);
const EnrollSchool = lazy(
  () => import("../components/SchoolpayShopping/EnrollSchool.jsx")
);
const ViewEnrolledSchools = lazy(
  () => import("../components/SchoolpayShopping/ViewEnrolledSchools.jsx")
);

// const TestComponent = lazy(() =>
//   import("../components/TestComponent/TestComponent.jsx")
// );

// const TestComponent = lazy(() =>
//   import("../components/TestComponent/TestComponent.tsx")
// );

const TermsAndCondition = lazy(
  () => import("../components/TermsAndConditions/TermsAndCondition.tsx")
);
const VendorItemList = lazy(
  () => import("../components/SchoolpayShopping/VendorItemList.js")
);
// const Products = lazy(() =>
//   import("../components/ProductConfigurations/Products")
// );

// Pangisa/Seed Admin
const UserRoles = lazy(
  () => import("../components/Authentication/UserRoles.tsx")
);
const AddUserRoles = lazy(
  () => import("../components/Authentication/AddUserRoles.jsx")
);

const AddUser = lazy(
  () => import("../components/Authentication/SystemUsers/AddUser.tsx")
);

const Users = lazy(() => import("../components/Authentication/Users.tsx"));
const RegisterComponent = lazy(
  () => import("../views/auth/UserRegistration.tsx")
);
const AddProductCategory = lazy(
  () => import("../views/ProductConfigurations/AddProductCategory.tsx")
);
// Business Admin / vendor
const AddProduct = lazy(
  () => import("../views/ProductConfigurations/AddProduct.tsx")
);
const WithdrawCash = lazy(
  () => import("../components/Payments/WithdrawCash.jsx")
);
const RegisterBusiness = lazy(
  () => import("../components/BusinessRegistration/BusinessRegistration.tsx")
);

const Registration = asyncComponent(() =>
  import("../components/ClientRegistration/Registration.jsx").then(
    (module) => module.default
  )
);

const ProductCategories = asyncComponent(() =>
  import("../views/ProductConfigurations/AddProductCategory.tsx").then(
    (module) => module.default
  )
);
const LoginComponent = lazy(
  () => import("../components/Authentication/LoginComponent.tsx")
);

const ClientBusinesses = lazy(
  () => import("../components/BusinessRegistration/ClientBusinesses.jsx")
);

const PassWordResetComponent = lazy(
  () => import("../components/Authentication/PasswordResetComponent.tsx")
);
const NewPasswordComponent = lazy(
  () => import("../views/auth/EnterNewPassword.tsx")
);
const ContactVerificationComponent = lazy(
  () => import("../components/Authentication/ContactVerificationComponent.jsx")
);

const ShoppingCart = lazy(
  () => import("../components/Shop/ShopingCart/ShoppingCartIndex.jsx")
);
const CategoryProducts = lazy(
  () => import("../components/Shop/ProductComponents/CategoryProducts.jsx")
);
const ProductDetails = lazy(
  () => import("../components/Ecommerce/ProductDetails.tsx")
);
const CheckOut = lazy(
  () => import("../components/Shop/CheckOut/CheckoutIndex.jsx")
);
const CustomerOrders = lazy(
  () => import("../components/Shop/Customers/CustomerOrders.jsx")
);
const OrderDetails = lazy(
  () => import("../components/Shop/Customers/OrderDetails.jsx")
);
const CustomerAddresses = lazy(
  () => import("../components/Shop/Customers/CustomerAddresses.jsx")
);
const AddCustomerAddress = lazy(
  () => import("../components/Shop/Customers/AddCustomerAddress.jsx")
);
const WishList = lazy(
  () => import("../components/Shop/Customers/WishList.jsx")
);
const CustomerProfile = lazy(
  () => import("../components/Shop/Customers/CustomerProfile.tsx")
);
const ProductReviews = lazy(
  () => import("../components/Shop/Customers/ProductReviews.jsx")
);

const MapSchoolToVendor = lazy(
  () => import("../components/SchoolpayShopping/MapSchoolToVendor.tsx")
);
const ProductSubCategories = lazy(
  () => import("../views/ProductConfigurations/ProductSubCategories.tsx")
);
const ProductBrands = lazy(
  () => import("../views/ProductConfigurations/ProductBrands.tsx")
);
const ProductTags = lazy(
  () => import("../views/ProductConfigurations/ProductTags.tsx")
);

const NotFoundPage = lazy(() => import("../components/NotFoundpage.jsx"));
const AddProductTag = lazy(
  () => import("../views/ProductConfigurations/AddProductTag.jsx")
);
const ProductionUnitView = lazy(
  () => import("../components/global-configurations/ProductionUnitView.js")
);
const ProductionUnit = lazy(
  () => import("../components/global-configurations/ProductionUnit.js")
);
const IndustrySubSectorView = lazy(
  () => import("../components/global-configurations/IndustrySubSectorView.js")
);
const IndustrySubSector = lazy(
  () => import("../components/global-configurations/IndustrySubSector.js")
);
const SubSectorTypeView = lazy(
  () => import("../components/global-configurations/SubSectorTypeView.js")
);
const SubSectorType = lazy(
  () => import("../components/global-configurations/SubSectorType.js")
);
const PromotionalPackages = lazy(
  () => import("../components/global-configurations/PromotionalPackages.jsx")
);
const AddPromotionalPackage = lazy(
  () => import("../components/global-configurations/AddPromotionalPackage.jsx")
);
const BusinessBodyView = lazy(
  () => import("../components/global-configurations/BusinessBodyView.js")
);
const BusinessAssociation = lazy(
  () => import("../components/global-configurations/BusinessAssociation.js")
);
const BankView = lazy(
  () => import("../components/global-configurations/BankView.js")
);
const BankRef = lazy(
  () => import("../components/global-configurations/BankRef.js")
);
const LocationView = lazy(
  () => import("../components/global-configurations/LocationView.js")
);
const District = lazy(
  () => import("../components/global-configurations/District.js")
);
const County = lazy(
  () => import("../components/global-configurations/County.js")
);
const SubCounty = lazy(
  () => import("../components/global-configurations/SubCounty.js")
);
const Parish = lazy(
  () => import("../components/global-configurations/Parish.js")
);
const IdTypesView = lazy(
  () => import("../components/global-configurations/IdTypesView.js")
);
const IdTypes = lazy(
  () => import("../components/global-configurations/IdTypes.js")
);
const PaymentChannelView = lazy(
  () => import("../components/global-configurations/PaymentChannelView.js")
);
const PaymentChannel = lazy(
  () => import("../components/global-configurations/PaymentChannel.js")
);
const ProfessionView = lazy(
  () => import("../components/global-configurations/ProfessionView.js")
);
const Profession = lazy(
  () => import("../components/global-configurations/Profession.js")
);
const RegistrationAuthorityView = lazy(
  () =>
    import("../components/global-configurations/RegistrationAuthorityView.js")
);
const RegistrationAuthority = lazy(
  () => import("../components/global-configurations/RegistrationAuthority.js")
);
const TitleView = lazy(
  () => import("../components/global-configurations/TitleView.js")
);
const Title = lazy(
  () => import("../components/global-configurations/Title.js")
);
const BeneficiaryScreen = lazy(
  () => import("../views/ProductConfigurations/AddBeneficiary.jsx")
);
const Beneficiaries = lazy(
  () => import("../views/ProductConfigurations/BeneficiariesView.jsx")
);
const AssignPermissions = lazy(
  () => import("../components/Permissions/AssignPermission.tsx")
);
const ProductProfile = lazy(
  () => import("../views/ProductConfigurations/ProductProfile.tsx")
);
const Catalog = lazy(
  () => import("../components/Shop/Catalog/CatalogMain.jsx")
);
const AboutUs = lazy(() => import("../components/StaticPages/AboutUs.jsx"));
const ContactUs = lazy(() => import("../components/StaticPages/ContactUs.jsx"));
const Privacy = lazy(() => import("../components/StaticPages/Privacy.jsx"));
const Delivery = lazy(() => import("../components/StaticPages/Delivery.jsx"));
const ExtraSchoolInformation = lazy(
  () => import("../views/SchoolEnrollment/ExtraSchoolInformation.tsx")
);

const BusinessProfile = lazy(
  () => import("../components/BusinessProfile/BusinessProfile.tsx")
);
const Wishlist = lazy(() => import("../components/Ecommerce/WishList.tsx"));

const ModuleRegistration = lazy(
  () => import("../components/Commission/ModuleRegistration.tsx")
);

const PartnerRegistrationForm = lazy(
    () => import("../components/PartnerRegistration/PartnerRegistrationForm.tsx")
);

const Partners = lazy(
    () => import("../components/PartnerRegistration/Partners.tsx")
);
const PartnerProfile = lazy(
    () => import("../components/PartnerRegistration/PartnerProfile.tsx")
);

interface AppRoutesInterface {
  path: string;
  isPrivateRoute: boolean;
  name?: string;
  Component: any;
  isNotCustomer: boolean;
}

const appRoutes: AppRoutesInterface[] = [
  {
    path: "/login/:productId",
    isPrivateRoute: false,
    name: "Login",
    Component: LoginComponent,
    isNotCustomer: true,
  },
  {
    path: "/product-categories",
    isPrivateRoute: true,
    name: "Product Categories",
    Component: ProductCategories,
    isNotCustomer: true,
  },
  {
    path: routings.sub_categories,
    isPrivateRoute: true,
    name: "Product Sub Categories",
    Component: ProductSubCategories,
    isNotCustomer: true,
  },
  {
    path: "/edit-product-category/:categoryId/:categoryName",
    isPrivateRoute: true,
    name: "Edit Product Category",
    Component: AddProductCategory,
    isNotCustomer: true,
  },
  {
    path: "/add-product-category",
    isPrivateRoute: true,
    name: "Add Product Category",
    Component: AddProductCategory,
    isNotCustomer: true,
  },
  {
    path: routings.login,
    name: "Login",
    isPrivateRoute: false,
    Component: LoginComponent,
    isNotCustomer: true,
  },
  {
    path: "/process-login",
    isPrivateRoute: false,
    name: "Process Login",
    Component: LoginComponent,
    isNotCustomer: true,
  },
  {
    path: routings.register,
    isPrivateRoute: false,
    name: "Register",
    Component: RegisterComponent,
    isNotCustomer: true,
  },
  {
    path: "/register-business",
    isPrivateRoute: true,
    name: "Register Bussiness",
    Component: RegisterBusiness,
    isNotCustomer: true,
  },
  {
    path: "/client-businesses",
    isPrivateRoute: true,
    name: "Client Bussiness",
    Component: ClientBusinesses,
    isNotCustomer: true,
  },
  {
    path: "/complete-registration",
    isPrivateRoute: true,
    name: "Register Vendor",
    Component: Registration,
    isNotCustomer: true,
  },
  {
    path: routings.forgot_password,
    isPrivateRoute: false,
    name: "Forgot Password",
    Component: PassWordResetComponent,
    isNotCustomer: false,
  },
  {
    path: "/new-password/:email",
    isPrivateRoute: false,
    name: "Create Password",
    Component: NewPasswordComponent,
    isNotCustomer: false,
  },
  {
    path: "/verify-email/:email",
    isPrivateRoute: false,
    isNotCustomer: false,
    name: "Verify Contact",
    Component: ContactVerificationComponent,
  },
  {
    path: "/verify-vendor-email/:email",
    isPrivateRoute: false,
    name: "Verify Vendor Contact",
    isNotCustomer: false,
    Component: ContactVerificationComponent,
  },

  {
    path: "/shopping-cart",
    isPrivateRoute: false,
    name: "Cart",
    Component: ShoppingCart,
    isNotCustomer: false,
  },
  {
    path: "/product-detailsv1/:productId",
    isPrivateRoute: false,
    name: "Product Details",
    Component: ProductDetails,
    isNotCustomer: true,
  },
  {
    path: routings.view_products,
    isPrivateRoute: true,
    name: "Products",
    isNotCustomer: true,
    Component: Products,
  },

  {
    path: "/add-product",
    isPrivateRoute: true,
    name: "add Products",
    isNotCustomer: true,
    Component: AddProduct,
  },
  {
    path: "/withdraw-cash",
    isPrivateRoute: true,
    name: "add Products",
    isNotCustomer: true,
    Component: WithdrawCash,
  },
  {
    path: "/user-roles",
    isPrivateRoute: true,
    name: "user roles",
    isNotCustomer: true,
    Component: UserRoles,
  },
  {
    path: "/add-role",
    isPrivateRoute: true,
    name: "add role",
    isNotCustomer: true,
    Component: AddUserRoles,
  },
  {
    path: routings.add_user,
    isPrivateRoute: true,
    name: "Add user",
    Component: AddUser,
    isNotCustomer: true,
  },
  {
    path: "/users",
    isPrivateRoute: true,
    name: "users",
    isNotCustomer: true,
    Component: Users,
  },
  {
    path: "/order-details",
    isPrivateRoute: true,
    name: "Order Details",
    Component: OrderDetails,
    isNotCustomer: true,
  },
  {
    path: "/checkout",
    isPrivateRoute: false,
    name: "Check Out",
    Component: CheckOut,
    isNotCustomer: false,
  },
  {
    path: "/catalog",
    isPrivateRoute: false,
    name: "Search",
    Component: Catalog,
    isNotCustomer: false,
  },
  {
    path: "/product-category/:categoryId",
    isPrivateRoute: false,
    name: "Category Products",
    Component: CategoryProducts,
    isNotCustomer: false,
  },
  {
    path: "/about-us",
    isPrivateRoute: false,
    name: "About",
    Component: AboutUs,
    isNotCustomer: false,
  },
  {
    path: "/contact-us",
    isPrivateRoute: false,
    name: "Contact Us",
    Component: ContactUs,
    isNotCustomer: false,
  },
  {
    path: "/privacy",
    isPrivateRoute: false,
    name: "Privacy Policy",
    Component: Privacy,
    isNotCustomer: false,
  },
  {
    path: "/delivery",
    isPrivateRoute: false,
    name: "Delivery",
    Component: Delivery,
    isNotCustomer: false,
  },
  {
    path: "/my-orders",
    isPrivateRoute: true,
    name: "Orders",
    Component: CustomerOrders,
    isNotCustomer: true,
  },
  {
    path: "/dashboard",
    isPrivateRoute: true,
    name: "Dashboard",
    Component: Dashboard,
    isNotCustomer: true,
  },
  // {
  //   path: routings.vendor_dashboard,
  //   isPrivateRoute: true,
  //   name: "Vendor Dashboard",
  //   isNotCustomer: true,
  //   Component: VendorDashboard,
  // },
  {
    path: routings.enroll_school,
    isPrivateRoute: true,
    name: "Schoolpay Shopping",
    Component: EnrollSchool,
    isNotCustomer: true,
  },
  {
    path: "/enrolled-schools",
    isPrivateRoute: true,
    name: "Enrolled Schools",
    Component: ViewEnrolledSchools,
    isNotCustomer: true,
  },
  {
    path: routings.view_school_profile,
    isPrivateRoute: true,
    name: "School Profile",
    Component: SchoolProfile,
    isNotCustomer: true,
  },
  {
    path: routings.school_pay.enroll_school,
    isPrivateRoute: false,
    name: "Schoolpay Shopping",
    Component: EnrollSchoolFromShoolPay,
    isNotCustomer: true,
  },
  {
    path: "/enrolled_schools",
    isPrivateRoute: false,
    name: "Enrolled Schools",
    Component: ViewEnrolledSchools,
    isNotCustomer: true,
  },
  {
    path: "/vendor_item_list/:schoolCode",
    isPrivateRoute: false,
    name: "Vendor Items",
    Component: VendorItemList,
    isNotCustomer: true,
  },
  {
    path: "/addresses",
    isPrivateRoute: true,
    name: "Addresses",
    Component: CustomerAddresses,
    isNotCustomer: true,
  },
  {
    path: "/add-address",
    isPrivateRoute: true,
    name: "Add Address",
    Component: AddCustomerAddress,
    isNotCustomer: true,
  },
  {
    path: "/edit-address/:addressId",
    isPrivateRoute: true,
    name: "Edit Address",
    Component: AddCustomerAddress,
    isNotCustomer: true,
  },
  {
    path: "/wishlist",
    isPrivateRoute: true,
    name: "Wish List",
    Component: WishList,
    isNotCustomer: true,
  },
  {
    path: routings.user_profile,
    isPrivateRoute: true,
    name: "My Profile",
    Component: CustomerProfile,
    isNotCustomer: true,
  },
  {
    path: "/my-profile",
    isPrivateRoute: true,
    name: "My Profile",
    // Component: AccountProfile,
    Component: CustomerProfile,
    isNotCustomer: true,
  },
  {
    path: "/product-reviews",
    isPrivateRoute: true,
    name: "My Reviews",
    Component: ProductReviews,
    isNotCustomer: true,
  },

  {
    path: routings.admin_dashboard,
    isPrivateRoute: true,
    name: "My test",
    Component: NewAdminDashboard,
    isNotCustomer: true,
  },

  {
    path: routings.terms_conditions,
    isPrivateRoute: true,
    name: "Terms and condition",
    Component: TermsAndCondition,
    isNotCustomer: true,
  },
  {
    path: "/map-school-to-vendor/:id",
    name: "MapSchoolToVendor",
    Component: MapSchoolToVendor,
    isNotCustomer: true,
    isPrivateRoute: false,
  },
  {
    path: "/product-brands",
    isPrivateRoute: true,
    name: "Product Brands",
    Component: ProductBrands,
    isNotCustomer: true,
  },
  {
    path: "/add-brand",
    isPrivateRoute: true,
    name: "Add Brand",
    Component: AddProductBrand,
    isNotCustomer: true,
  },
  {
    path: "/edit-product-brand/:brandId/:brandName",
    isPrivateRoute: true,
    name: "Edit Product Brand",
    Component: AddProductBrand,
    isNotCustomer: true,
  },
  {
    path: "/product-tags",
    isPrivateRoute: true,
    name: "Product Tags",
    Component: ProductTags,
    isNotCustomer: true,
  },
  {
    path: "/add-tag",
    isPrivateRoute: true,
    name: "Add Product Tag",
    Component: AddProductTag,
    isNotCustomer: true,
  },
  {
    path: "/edit-tag/:tagId/:tagName",
    isPrivateRoute: true,
    name: "Edit Product Tag",
    Component: AddProductTag,
    isNotCustomer: true,
  },
  {
    path: "/production-units",
    isPrivateRoute: true,
    name: "View production units",
    Component: ProductionUnitView,
    isNotCustomer: true,
  },
  {
    path: "/add-production-unit",
    isPrivateRoute: true,
    name: "Add production units",
    Component: ProductionUnit,
    isNotCustomer: true,
  },
  {
    path: "/edit-product/:productId",
    isPrivateRoute: true,
    name: "Edit Product",
    Component: AddProduct,
    isNotCustomer: true,
  },
  // {
  //   path: "/extra-school-info/:id",
  //   isPrivateRoute: true,
  //   name: "Register Customer",
  //   Component: ExtraSchoolInfoComponent,
  // },
  {
    path: "/industry-sub-sectors",
    isPrivateRoute: true,
    name: "View Industry Sub sectors",
    Component: IndustrySubSectorView,
    isNotCustomer: true,
  },
  {
    path: "/add-industry-sub-sector",
    isPrivateRoute: true,
    name: "Add Industry Sub sectors",
    Component: IndustrySubSector,
    isNotCustomer: true,
  },
  {
    path: "/industry-sub-sector-types",
    isPrivateRoute: true,
    name: "View Industry Sub sectors Types",
    Component: SubSectorTypeView,
    isNotCustomer: true,
  },
  {
    path: "/add-industry-sub-sector-type",
    isPrivateRoute: true,
    name: "Add Industry Sub sector Type",
    Component: SubSectorType,
    isNotCustomer: true,
  },
  {
    path: "/promotion-packages",
    isPrivateRoute: true,
    name: "Promotion Packages",
    Component: PromotionalPackages,
    isNotCustomer: true,
  },
  {
    path: "/add-promotion-package",
    isPrivateRoute: true,
    name: "Add Promotion Package",
    Component: AddPromotionalPackage,
    isNotCustomer: true,
  },
  {
    path: "/edit-promotion-package/:packageId",
    isPrivateRoute: true,
    name: "Add Promotion Package",
    Component: AddPromotionalPackage,
    isNotCustomer: true,
  },
  {
    path: "/business-body",
    isPrivateRoute: true,
    name: "Business Body",
    Component: BusinessBodyView,
    isNotCustomer: true,
  },
  {
    path: "/add-business-association",
    isPrivateRoute: true,
    name: "Add Business Association",
    Component: BusinessAssociation,
    isNotCustomer: true,
  },
  {
    path: "/bank-view",
    isPrivateRoute: true,
    name: "Banks",
    Component: BankView,
    isNotCustomer: true,
  },
  {
    path: "/add-bank",
    isPrivateRoute: true,
    name: "add bank",
    Component: BankRef,
    isNotCustomer: true,
  },
  {
    path: "/view-location",
    isPrivateRoute: true,
    name: "View Location",
    Component: LocationView,
    isNotCustomer: true,
  },
  {
    path: "/add-district",
    isPrivateRoute: true,
    name: "Add District",
    Component: District,
    isNotCustomer: true,
  },
  {
    path: "/add-county",
    isPrivateRoute: true,
    name: "Add County",
    Component: County,
    isNotCustomer: true,
  },
  {
    path: "/add-sub-county",
    isPrivateRoute: true,
    name: "Add Sub County",
    Component: SubCounty,
    isNotCustomer: true,
  },
  {
    path: "/add-parish",
    isPrivateRoute: true,
    name: "Add Parish",
    Component: Parish,
    isNotCustomer: true,
  },
  {
    path: "/view-id-types",
    isPrivateRoute: true,
    name: "View Id Types",
    Component: IdTypesView,
    isNotCustomer: true,
  },
  {
    path: "/add-id-type",
    isPrivateRoute: true,
    name: "Add Id Types",
    Component: IdTypes,
    isNotCustomer: true,
  },
  {
    path: "/view-payment-channel",
    isPrivateRoute: true,
    name: "View Payment Channels",
    Component: PaymentChannelView,
    isNotCustomer: true,
  },
  {
    path: "/add-payment-channel",
    isPrivateRoute: true,
    name: "Add Payment Channels",
    Component: PaymentChannel,
    isNotCustomer: true,
  },
  {
    path: "/view-profession",
    isPrivateRoute: true,
    name: "View Professions",
    Component: ProfessionView,
    isNotCustomer: true,
  },
  {
    path: "/add-profession",
    isPrivateRoute: true,
    name: "Add Professions",
    Component: Profession,
    isNotCustomer: true,
  },
  {
    path: "/view-registration-body",
    isPrivateRoute: true,
    name: "RegistrationAuthorityView",
    Component: RegistrationAuthorityView,
    isNotCustomer: true,
  },
  {
    path: "/add-registration-body",
    isPrivateRoute: true,
    name: "Add Registration Body",
    Component: RegistrationAuthority,
    isNotCustomer: true,
  },
  {
    path: "/view-title",
    isPrivateRoute: true,
    name: "View Titles",
    Component: TitleView,
    isNotCustomer: true,
  },
  {
    path: "/add-title",
    isPrivateRoute: true,
    name: "Add Titles",
    Component: Title,
    isNotCustomer: true,
  },
  {
    path: "/add-beneficiary",
    isPrivateRoute: true,
    name: "Add Beneficiary",
    Component: BeneficiaryScreen,
    isNotCustomer: true,
  },
  {
    path: "/view-beneficiaries",
    isPrivateRoute: true,
    name: "View Beneficiaries",
    Component: Beneficiaries,
    isNotCustomer: true,
  },
  {
    path: "/edit-beneficiary/:beneficiaryId",
    isPrivateRoute: true,
    name: "Edit Beneficiary",
    Component: BeneficiaryScreen,
    isNotCustomer: true,
  },
  {
    path: "/commission-management",
    isPrivateRoute: true,
    name: "Commission Management",
    Component: CommissionManagement,
    isNotCustomer: true,
  },
  {
    path: "/edit-business/:businessId",
    isPrivateRoute: true,
    name: "Edit Business",
    Component: RegisterBusiness,
    isNotCustomer: true,
  },
  {
    path: "/product-profile/:productId",
    isPrivateRoute: true,
    name: "Product Profile",
    Component: ProductProfile,
    isNotCustomer: true,
  },
  {
    path: "/assign-permissions/:userId",
    isPrivateRoute: true,
    name: " Assign Permissions",
    Component: AssignPermissions,
    isNotCustomer: true,
  },
  {
    path: "/not-found",
    isPrivateRoute: false,
    name: " page not found",
    Component: NotFoundPage,
    isNotCustomer: true,
  },
  {
    path: "/add-system-user",
    isPrivateRoute: true,
    name: "Add systems user",
    Component: AddSystemUsers,
    isNotCustomer: true,
  },
  {
    path: routings.school_pay.view_school_profile + ":id",
    name: "School Profile",
    Component: SchoolPayProfile,
    isNotCustomer: false,
    isPrivateRoute: false,
  },
  {
    path: "/view-vendor-profile/:id",
    isPrivateRoute: true,
    name: "Vendor Profile",
    Component: VendorProfile,
    isNotCustomer: true,
  },
  {
    path: routings.add_packages,
    isPrivateRoute: true,
    name: "all packages",
    Component: AddPackage,
    isNotCustomer: true,
  },
  {
    path: routings.packages,
    isPrivateRoute: true,
    name: "all packages",
    Component: Packages,
    isNotCustomer: true,
  },
  {
    path: "/package/edit/:PackageId",
    isPrivateRoute: true,
    name: "Edit Package",
    Component: EditPackage,
    isNotCustomer: true,
  },
  {
    path: routings.product_mapping + ":packageId",
    isPrivateRoute: true,
    name: "Package Mapping",
    Component: PackageMapping,
    isNotCustomer: true,
  },
  // {
  //   path: "/Packages",
  //   isPrivateRoute: true,
  //   name: "all packages",
  //   Component: Package,
  // },
  {
    path: "/add-commission-description",
    isPrivateRoute: true,
    name: "Add Commission Description",
    Component: AddCommissionDescription,
    isNotCustomer: true,
  },
  {
    path: "/view-commissions",
    isPrivateRoute: true,
    name: "View Commissions",
    Component: CommissionView,
    isNotCustomer: true,
  },
  {
    path: "/register_system_module",
    isPrivateRoute: true,
    name: "Register System Module",
    Component: RegsiterSystemModule,
    isNotCustomer: true,
  },
  {
    path: "/view-system-modules",
    isPrivateRoute: true,
    name: "View System Modules",
    Component: ViewSystemModules,
    isNotCustomer: true,
  },
  {
    path: "/edit-system-module/:id",
    isPrivateRoute: true,
    name: "Edit System Module",
    Component: RegsiterSystemModule,
    isNotCustomer: true,
  },
  {
    path: routings.pending_approvals,
    isPrivateRoute: true,
    name: "Pending Approvals",
    Component: PendingApprovals,
    isNotCustomer: true,
  },
  {
    path: routings.active_vendors,
    isPrivateRoute: false,
    name: "Vendor Listing",
    Component: VendorsList,
    isNotCustomer: false,
  },
  {
    path: routings.mapping_management,
    isPrivateRoute: false,
    name: "Mapping Management",
    Component: MappingManagement,
    isNotCustomer: true,
  },
  {
    path: routings.school_pay.mapping_requests,
    isPrivateRoute: false,
    name: "Mapping Requests",
    Component: MappingRequests,
    isNotCustomer: true,
  },
  {
    path: "/package-detail/:packageId",
    isPrivateRoute: true,
    name: "Edit System Module",
    Component: PackageDetails,
    isNotCustomer: true,
  },
  {
    path: "/edit-commission/:commissionId",
    isPrivateRoute: true,
    name: "Edit Commission",
    Component: AddCommissionDescription,
    isNotCustomer: true,
  },
  {
    path: "/commission-profile/:commissionId",
    isPrivateRoute: true,
    name: "Commission Profile",
    Component: CommissionProfile,
    isNotCustomer: true,
  },
  {
    path: routings.school_pay.extra_info + ":id",
    name: "Extra School Information",
    Component: ExtraSchoolInformation,
    isNotCustomer: true,
    isPrivateRoute: false,
  },
  {
    path: "/package-detail-school/:packageId",
    name: "Package Details",
    Component: PackageApprovalDetails,
    isNotCustomer: true,
    isPrivateRoute: true,
  },
  {
    path: routings.vendor_dashboard,
    name: "Dashboard",
    Component: VendorDashboard,
    isNotCustomer: true,
    isPrivateRoute: true,
  },
  {
    path: routings.vendorMapping,
    name: "Vendor Mapping",
    Component: VendorMapping,
    isPrivateRoute: true,
    isNotCustomer: true,
  },
  {
    path: "/image-upload",
    name: "Upload Image",
    Component: ImageUpload,
    isNotCustomer: true,
    isPrivateRoute: true,
  },
  {
    path: routings.dashboard_test,
    name: "Dashboard Testing",
    Component: VendorMappingRequests,
    isNotCustomer: true,
    isPrivateRoute: true,
  },
  {
    path: routings.package_approvals,
    name: "Package Approvals",
    Component: PackageApprovals,
    isNotCustomer: true,
    isPrivateRoute: true,
  },
  {
    path: "/business-profile/:businessId",
    isPrivateRoute: true,
    name: "BusinessProfile",
    Component: BusinessProfile,
    isNotCustomer: true,
  },
  {
    path: "/wish-list",
    isPrivateRoute: false,
    name: "WishList",
    Component: Wishlist,
    isNotCustomer: true,
  },
  {
    path: "/add-module",
    isPrivateRoute: true,
    name: "Register Module",
    Component: ModuleRegistration,
    isNotCustomer: true,
  },
  {
    path: routings.customer_details,
    isPrivateRoute: true,
    name: "user details",
    Component: UserDetails,
    isNotCustomer: true,
  },
  {
    path: routings.package_approvals_approvalsdetails,
    isPrivateRoute: true,
    name: "user details",
    Component: PackageDetails,
    isNotCustomer: true,
  },

  {
    path: routings.help_center,
    isPrivateRoute: false,
    name: "Notifications",
    Component: null,
    isNotCustomer: true,
  },
  {
    path: routings.mapping_reports,
    isPrivateRoute: false,
    name: "Mapping Reports",
    Component: MappingReports,
    isNotCustomer: true,
  },
  {
    path: routings.school_package_infor,
    isPrivateRoute: false,
    name: "Mapping Reports",
    Component: SchoolpackageInfor,
    isNotCustomer: true,
  },
  {
    path: routings.school_package_infor,
    isPrivateRoute: false,
    name: "Mapping Reports",
    Component: SchoolpackageInfor,
    isNotCustomer: true,
  },

  {
    path: routings.vendor_mapping_management,
    isPrivateRoute: true,
    name: "Vendor MappingManagement",
    Component: VendorMappingManagement,
    isNotCustomer: true,
  },
  {
    path: routings.school_mapping_request,
    isPrivateRoute: false,
    name: "School Mapping Requests",
    Component: SchoolMappingRequests,
    isNotCustomer: true,
  },
  {
    path: routings.vendor_mapping_report,
    isPrivateRoute: true,
    name: "Vendor Mapping Reports",
    Component: VendorMappingReports,
    isNotCustomer: true,
  },
  {
    path: routings.school_mapping_report,
    isPrivateRoute: false,
    name: "School MApping Report",
    Component: SchoolMappingReports,
    isNotCustomer: true,
  },
  {
    path: routings.school_mapping_management,
    isPrivateRoute: false,
    name: "School Mapping Management",
    Component: SchoolMappingManagement,
    isNotCustomer: true,
  },
  {
    path: routings.admin_mapping_management,
    isPrivateRoute: true,
    name: "Pangisa Mapping Management",
    Component: AdminMappingManagement,
    isNotCustomer: true,
  },
  {
    path: routings.schoolpay_mapping_management,
    name: "Dashboard Testing",
    Component: SchoolPayMappingManagement,
    isNotCustomer: true,
    isPrivateRoute: false,
  },
  {
    path: routings.school_package_mapping_management,
    isPrivateRoute: false,
    name: "School Package MappingManagement",
    Component: SchoolPackageMappingManagement,
    isNotCustomer: true,
  },
  {
    path: routings.vendor_mapping_requests,
    isPrivateRoute: true,
    name: "Vendor mapping Requests",
    Component: VendorMappingRequests,
    isNotCustomer: true,
  },
  {
    path: "/partner-registration",
    isPrivateRoute: true,
    name: "Register Partner",
    Component: PartnerRegistrationForm,
    isNotCustomer: true,
  },
  {
    path: "/edit-partner-details/:partnerId",
    isPrivateRoute: true,
    name: "Edit Partner",
    Component: PartnerRegistrationForm,
    isNotCustomer: true,
  },
  {
    path: "/partners",
    isPrivateRoute: true,
    name: "View All Partners",
    Component: Partners,
    isNotCustomer: true,
  },
  {
    path: "/partner-profile/:partnerId",
    isPrivateRoute: true,
    name: "Partner Profile",
    Component: PartnerProfile,
    isNotCustomer: true,
  },

];

export default appRoutes;
