import { LOGGED_IN, LOGGED_OUT } from "../types/types";

export const signInUser = (user: any) => {
  return {
    type: LOGGED_IN,
    payload: user,
  };
};

export const SignOut = () => {
  return {
    type: LOGGED_OUT,
  };
};
