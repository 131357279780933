import React, { useEffect, useState } from "react";
import {
  Search,
  Package,
  Clock,
  Calendar,
  CheckCircle,
  ArrowLeft,
  DollarSign,
  Timer,
  Tag,
  AlertCircle,
} from "lucide-react";
import { CommonComponent } from "../../service";
import { AppContext } from "../../context/AppContext";

// eslint-disable-next-line @typescript-eslint/no-redeclare
type Package = {
  created_on: string;
  package_name: string;
  package_end_date: string;
  id: number;
  date_before_end_date: string;
  package_start_date: string;
  package_type: string;
  grand_package_price: string;
  package_price: string;
  status: string;
};

type schoolCode = {
  schoolCode?: any;
};

const req = new CommonComponent();

function SchoolToPackage({ schoolCode }: schoolCode) {
  const [searchTerm, setSearchTerm] = useState("");
  const [packages, setPackages] = useState<any[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<Package | any>(null);
  const { setLoading, setSnackAlert }: any = React.useContext(AppContext);
  const [school, setSchool] = useState<any>({});

  const filteredPackages = packages.filter(
    (pkg) =>
      pkg.package_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      pkg.package_type.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    let sub = true;

    if (sub) {
      getPackages();
      getAllEnrolledSchools();
    }

    return () => {
      sub = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllEnrolledSchools = async () => {
    setLoading(true);

    const postObject = {
      search: { schoolCode },
    };
    try {
      const response: Promise<any> = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "getEnrolledSchools",
        JSON.stringify(postObject)
      );

      const { returnCode, returnData }: any = response;

      if (returnCode === 200) {
        setLoading(false);

        setSchool(returnData[0]);
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: "We're Having Trouble Retrieving Enrolled Schools",
        });

        // change route
        setTimeout(() => {
          // history.push("/enroll_school");
          setSnackAlert({ open: false, severity: "error", message: "" });
        }, 5000);
      }
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handlePackageSelect = (pkg: Package) => {
    setSelectedPackage(pkg);
  };

  const handleConfirmMapping = async () => {
    if (!selectedPackage) return;

    const postObject = {
      requestData: {
        packageIds: [selectedPackage.id],
        schoolId: school.id,
        initiator: "school",
      },
    };

    try {
      const response = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "addPackageSchoolMapping",
        JSON.stringify(postObject)
      );

      if (response.returnCode === 200) {
        setSnackAlert({
          open: true,
          severity: "success",
          message: "Package Mapping Successful",
        });
        setTimeout(() => {
          setSnackAlert({ open: false, severity: "success", message: "" });
          setSelectedPackage(null);
        }, 5000);
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: response.returnMessage,
        });
      }
    } catch (error) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "An error occurred during mapping",
      });
    }
  };

  const getPackages = () => {
    const postObject = {
      search: { all: true },
    };
    try {
      req
        .sendRequestToServer(
          "ProductManagement",
          "getAllSchoolPayPackageWithoutSession",
          JSON.stringify(postObject)
        )
        .then((data) => {
          if (data.returnCode === 200) {
            setPackages(data.returnData.rows);
          } else {
            console.log(
              "Unexpected return code",
              data.returnCode,
              data.returnMessage
            );
          }
        })
        .catch((error) => {
          console.error(
            "Error occurred while retrieving packages:",
            error.message
          );
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const renderPackageCard = (pkg: Package) => (
    <div
      key={pkg.id}
      className={`p-6 rounded-xl transition-all cursor-pointer ${
        selectedPackage?.id === pkg.id
          ? "bg-blue-50 border-2 border-[--primary-color]"
          : "bg-white hover:bg-gray-50 border-2 border-transparent"
      }`}
      onClick={() => handlePackageSelect(pkg)}
    >
      <div className="flex items-start justify-between">
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <Package className="text-[--primary-color]" size={20} />
            <span className="font-mono text-sm bg-blue-100 text-[--school-pay-primary-color] px-2 py-1 rounded">
              {pkg.package_type}
            </span>
          </div>
          <h3 className="text-lg font-semibold text-gray-800">
            {pkg.package_name}
          </h3>
          <div className="flex items-center gap-4 text-sm text-gray-600">
            <div className="flex items-center gap-1">
              <span className="font-semibold">UGX</span>
              Price: {pkg.package_price}
            </div>
            <div className="flex items-center gap-1">
              <Clock size={16} />
              Valid until: {pkg.package_end_date}
            </div>
          </div>
        </div>
        <span
          className={`px-3 py-1 rounded-full text-sm ${
            pkg.status === "Approved"
              ? "bg-green-100 text-green-800"
              : "bg-yellow-100 text-yellow-800"
          }`}
        >
          {pkg.status}
        </span>
      </div>
    </div>
  );

  const renderPackageDetails = () => {
    if (!selectedPackage) return null;

    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between mb-8">
          <button
            onClick={() => setSelectedPackage(null)}
            className="flex items-center gap-2 text-gray-600 hover:text-gray-800 transition-colors px-4 py-2 rounded-lg hover:bg-gray-100"
          >
            <ArrowLeft size={20} />
            <span className="font-medium">Back to Packages</span>
          </button>
          <div className="flex items-center gap-2">
            <span
              className={`px-4 py-2 rounded-full text-sm font-medium ${
                selectedPackage.status === "Approved"
                  ? "bg-green-100 text-green-800"
                  : "bg-yellow-100 text-yellow-800"
              }`}
            >
              {selectedPackage.status}
            </span>
          </div>
        </div>

        <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
          <div className="bg-gradient-to-r from-[--primary-color] to-blue-600 p-8 text-white">
            <div className="flex items-start justify-between">
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <Package size={24} />
                  <h2 className="text-2xl font-bold">
                    {selectedPackage.package_name}
                  </h2>
                </div>
                <div className="flex items-center gap-2 text-blue-100">
                  <Tag size={16} />
                  <span className="font-medium">
                    {selectedPackage.package_type}
                  </span>
                </div>
              </div>
              <div className="text-right">
                <div className="text-blue-100 text-sm mb-1">Package Price</div>
                <div className="text-3xl font-bold flex items-center gap-1">
                  <span className="text-lg">UGX</span>
                  {selectedPackage.package_price}
                </div>
              </div>
            </div>
          </div>

          <div className="p-8">
            <div className="grid grid-cols-2 gap-8">
              <div className="space-y-6">
                <div className="bg-blue-50 rounded-xl p-6">
                  <div className="flex items-center gap-3 text-blue-700 mb-4">
                    <Timer size={24} />
                    <h3 className="text-lg font-semibold">Duration Details</h3>
                  </div>
                  <div className="space-y-4">
                    <div>
                      <div className="text-sm text-gray-500 mb-1">
                        Start Date
                      </div>
                      <div className="flex items-center gap-2 text-gray-800">
                        <Calendar size={18} />
                        <span className="font-medium">
                          {selectedPackage.package_start_date}
                        </span>
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-gray-500 mb-1">End Date</div>
                      <div className="flex items-center gap-2 text-gray-800">
                        <Calendar size={18} />
                        <span className="font-medium">
                          {selectedPackage.package_end_date}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-6">
                <div className="bg-green-50 rounded-xl p-6">
                  <div className="flex items-center gap-3 text-green-700 mb-4">
                    <DollarSign size={24} />
                    <h3 className="text-lg font-semibold">Pricing Details</h3>
                  </div>
                  <div className="space-y-4">
                    <div>
                      <div className="text-sm text-gray-500 mb-1">
                        Package Price
                      </div>
                      <div className="text-gray-800 font-medium">
                        UGX {selectedPackage.package_price}
                      </div>
                    </div>
                    <div>
                      <div className="text-sm text-gray-500 mb-1">
                        Grand Package Price
                      </div>
                      <div className="text-gray-800 font-medium">
                        UGX {selectedPackage.grand_package_price}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-8 bg-orange-50 rounded-xl p-6">
              <div className="flex items-center gap-2 text-orange-700 mb-4">
                <AlertCircle size={24} />
                <span className="font-medium">Important Notice</span>
              </div>
              <p className="text-gray-700">
                By confirming this mapping, you agree to associate this package
                with the selected entities. This action will enable the package
                for use within the system.
              </p>
            </div>

            <button
              onClick={handleConfirmMapping}
              className="w-full mt-8 py-4 bg-[--primary-color] text-white rounded-xl hover:bg-[--secondary_color] transition-colors flex items-center justify-center gap-2 text-lg font-medium shadow-lg hover:shadow-xl"
            >
              <CheckCircle size={20} />
              Confirm Package Mapping
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 p-8">
      <div className="max-w-7xl mx-auto">
        {selectedPackage ? (
          renderPackageDetails()
        ) : (
          <>
            <div className="bg-white rounded-xl shadow-lg p-6 mb-6">
              <div className="relative">
                <Search
                  className="absolute left-4 top-3.5 text-gray-400"
                  size={20}
                />
                <input
                  type="text"
                  className="w-full pl-12 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-[--primary-color] focus:border-transparent text-lg"
                  placeholder="Search by package name or type..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className="space-y-4 max-h-96 overflow-y-auto">
              {filteredPackages.map((pkg) => renderPackageCard(pkg))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SchoolToPackage;
