import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomBreadCrumbs from "../../components/CustomComponents/Tables/CustomBreadCrumb.tsx";
import CustomTable from "../../components/CustomComponents/Tables/CustomTable.tsx";
import { routings } from "../../routes/routings.ts";
import { getCookie, userDashboard } from "../../service/get-user.js";
import { CommonComponent } from "../../service/index.js";
import { AppContext } from "../../context/AppContext";

interface Package {
  id: number;
  package_name: string;
  created_on: number;
  package_start_date: number;
  package_end_date: number;
  grand_package_price: number;
  package_price: number;
  created_by: number;
  status: boolean;
}
const columns = [
  { header: "Package No.", accessor: "package_no" },
  { header: "Package Name", accessor: "package_name" },
  { header: "Start Date", accessor: "package_start_date" },
  { header: "End Date", accessor: "package_end_date" },
  { header: "Package Price", accessor: "package_price" },
  { header: "Package type", accessor: "package_type" },
  { header: "Package Status", accessor: "status" },
];

export default function PackageProducts() {
  const [packages, setPackages] = useState<Package[]>([]);
  const [searchQuery] = useState<string>("");

  const auth = getCookie("loggedInUser");
  const commonComponent = new CommonComponent();
  const navigation = useNavigate();

  const { setSnackAlert, setLoading }: any = useContext(AppContext);

  useEffect(() => {
    getPackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getPackages = () => {
    const postObject = {
      auth,
      search: {},
    };
    try {
      setLoading(true);

      commonComponent
        .sendRequestToServer(
          "ProductManagement",
          "getSchoolpayPackages",
          JSON.stringify(postObject)
        )
        .then((data) => {
          setLoading(false);
          if (data.returnCode === 200) {
            if (data.returnData && data.returnData.rows) {
              setPackages(data.returnData.rows);
            } else {
              console.error("Invalid data structure", data);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(
            "Error occurred while retrieving packages:",
            error.message
          );
        });
    } catch (error) {
      setLoading(false);
      console.error("Error occurred while retrieving packages:", error.message);
    }
  };

  const filteredPackages = useMemo(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();

    return packages.filter((pkg) => {
      const packageName = pkg.package_name
        ? pkg.package_name.toLowerCase()
        : "";
      const status = pkg.status !== undefined ? pkg.status.toString() : "";
      const grandPrice =
        pkg.grand_package_price !== undefined
          ? pkg.grand_package_price.toString()
          : "";
      const packagePrice =
        pkg.package_price !== undefined ? pkg.package_price.toString() : "";
      return (
        packageName.includes(lowerCaseQuery) ||
        status.includes(lowerCaseQuery) ||
        grandPrice.includes(lowerCaseQuery) ||
        packagePrice.includes(lowerCaseQuery)
      );
    });
  }, [searchQuery, packages]);

  const Edit = (row: Package) => {
    navigation("/package/edit/" + row.id);
  };
  const Add = (row: Package) => {
    navigation("/package-product-mapping/" + row.id);
  };

  const PackageDetail = (row: Package) => {
    navigation("/package-detail-school/" + row.id);
  };

  const create = () => {
    navigation(routings.add_packages);
  };

  const filter = [
    { label: "All", value: "All" },
    { label: "Awaiting Approval", value: "Awaiting Approval" },
    { label: "Approved", value: "Approved" },
  ];

  const breadcrumbs = [
    { label: "Dashboard", route: () => navigation(userDashboard) },
    {
      label: "Product Management",
    },
    { label: "Packages", route: () => navigation("/details") }, // Route ignored since it's the last
  ];

  return (
    <div className="my-16">
      <CustomBreadCrumbs breadcrumbs={breadcrumbs} />
      {columns && (
        <CustomTable
          data={filteredPackages}
          columns={columns}
          editAction={{ label: "Edit Package", onclick: Edit }}
          addAction={{ label: "Map products", onclick: Add }}
          detial={{ label: "Package Details", onclick: PackageDetail }}
          createAction={{ label: "Add Package", onclick: create }}
          filters={filter}
          excel
          pdf
          onRowClick={PackageDetail}
        />
      )}
    </div>
  );
}
