import React from "react";
import { COLORS } from "../../../assets/styles/global";

interface CircularProgressProps {
  percentage: number;
  size?: number;
  color?: string;
  strokeWidth?: number;
}

const CircularProgress: React.FC<CircularProgressProps> = ({
  percentage,
  size = 120,
  strokeWidth = 10,
  color = COLORS.primary,
}: CircularProgressProps) => {
  // Calculate circle's circumference
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = circumference - (percentage / 100) * circumference;

  return (
    <div className="flex items-center justify-center">
      <svg
        width={size}
        height={size}
        className="relative"
        style={{ transform: "rotate(-90deg)" }} // Rotates the circle so progress starts at the top
      >
        {/* Background Circle */}
        <circle
          className="bg-gray-200"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke="#e5e7eb" // Light gray
          strokeWidth={strokeWidth}
          fill="none"
        />
        {/* Progress Circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={color} // Blue
          strokeWidth={strokeWidth}
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={progressOffset}
          strokeLinecap="round" // Smooth edges
          className="transition-all duration-300 ease-in-out"
        />
        {/* Center Text */}
        <span
          className="absolute text-center text-black font-bold"
          style={{
            fontSize: size / 5, // Dynamically scale font size
          }}
        >
          {Math.round(percentage)}%
        </span>
      </svg>
    </div>
  );
};

export default CircularProgress;
