// @ts-ignore
import React, { useContext, useEffect, useState } from "react";
import {
  CommonComponent,
  formatAndParseNumber,
} from "../../service/CommonComponent";
import { useNavigate, useParams } from "react-router-dom";
import { getCookie } from "../../service/get-user";
import { routings } from "../../routes/routings.ts";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import defaultImage from "../../../src/assets/img/packageimage.jpg";
import { AppContext } from "../../context/AppContext";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Dangerous, More, MoreVert } from "@mui/icons-material";
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import { Button } from "@mui/material";
// @ts-ignore
import CustomTable from "../../components/CustomComponents/Tables/CustomTable.tsx";
import { Plus, Trash2 } from "lucide-react";
// @ts-ignore
import FormDialog from "../../components/CustomComponents/Dialogs/FormDialog.tsx";

const PackageApprovalDetails = () => {
  const { packageId } = useParams();
  const [fulldata, setFulldata] = useState<any>({});
  const { setSnackAlert, setLoading }: any = useContext(AppContext);
  const [mappedProducts, setMappedProducts] = useState<any[]>([]);
  const [mappingData, setMappingData] = useState();

  const [open, setOpen] = useState(false);
  const [removeOpen, setremoveOpen] = useState(false);
  const [statusPackage, setStatus] = useState("");
  const [packageDetails, setpackagedetails] = useState({
    packageName: "",
    packagePrice: "",
    grandPrice: "",
    startDate: "",
    ExpireDate: "",
    packageImage: "",
    status: "",
    comment: "",
    creationDate: "",
    packageNo: "",
    id: packageId,
    packageType: "",
  });
  const [commisssionPrice, setCommisssionPrice] = useState<any>({
    totalPrice: "",
    totalwithCommission: "",
  });

  const auth = getCookie("loggedInUser");

  const handleClickOpen = (status) => {
    console.log("Opening dialog with status:", status);
    setStatus(status); // Update the state
    setOpen(true); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false);
  };

  const commonComponent = new CommonComponent();
  const navigation = useNavigate();

  useEffect(() => {
    getPackageInfo();
    getMappedProducts();
    getPackagewithCommissionInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPackagewithCommissionInfo = () => {
    let postObject = {
      auth,
      requestData: {
        packageId: packageId,
      },
      grouped: { NoSort: "NoSort" },
    };

    commonComponent
      .sendRequestToServer(
        "ProductManagement",
        "getPackageCommissionDetail",
        JSON.stringify(postObject)
      )
      .then((data) => {
        const { returnCode, returnData } = data;
        if (returnCode === 200 && returnData.length > 0) {
          setCommisssionPrice({
            totalPrice: returnData[0].totalcost,
            totalwithCommission: returnData[0].grandprice,
          });
        }
      })
      .catch((error) => {
        console.log(
          "Oops.. we are having trouble fetching your profile",
          error
        );
      });
  };

  const getPackageInfo = () => {
    let postObject = {
      packageId: packageId,
    };
    commonComponent
      .sendRequestToServer(
        "ProductManagement",
        "getShoolPackageDetails",
        JSON.stringify(postObject)
      )
      .then((data) => {
        if (data.returnCode === 200) {
          setpackagedetails({
            ...packageDetails,
            packageName: data.returnData.packageName || "",
            packagePrice:
              formatAndParseNumber(data.returnData.packagePrice) || "",
            grandPrice:
              formatAndParseNumber(data.returnData.grandPackagePrice) || "",
            startDate:
              new Date(data.returnData.packageStartDate).toLocaleDateString() ||
              "",
            ExpireDate:
              new Date(data.returnData.packageEndDate).toLocaleDateString() ||
              "",
            packageImage: data.returnData.packageImage || "",
            status: data.returnData.status || "",
            packageNo: data.returnData.packageNo || "Not Available",
            creationDate:
              new Date(data.returnData.createdOn).toLocaleDateString() ||
              "Unknown",
            comment: data.returnData.comment || "",
            packageType: data.returnData.packageType || "",
          });

          setFulldata(data.returnData);
          getPackagewithCommissionInfo();
        } else {
          console.log("Oops.. we are having trouble fetching your profile");
        }
      });
  };

  const getMappedProducts = async () => {
    setLoading(true);
    const postObject = {
      auth,
      requestData: {
        packageId: packageId,
      },
    };

    try {
      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "getPackageProductsMappingwithoutsession",
        JSON.stringify(postObject)
      );
      const { returnCode, returnData } = response;
      if (returnCode === 200) {
        setMappedProducts(returnData.rows); // Display the returned data
        setLoading(false); // Close the loading spinner when data is retrieved
        if (response.returnData.rows.length === 0) {
          console.log("No data was retrieved");

          setLoading(false); //
          getPackagewithCommissionInfo();
        }
      } else {
        setLoading(false); // Close the loading spinner when an error occurs
        console.error(
          "Unexpected return code",
          response.returnCode,
          "message",
          response.message
        );
      }
    } catch (error) {
      setLoading(false); // Close the loading spinner when an error occurs
      console.error("Error: ", error.message);
    }
  };

  const handleApproval = () => {
    let rows = [
      {
        ...fulldata,
        comment: packageDetails.comment,
        statusPackage: statusPackage,
      },
    ];

    setLoading(true);
    let statusReturnMessage =
      statusPackage === "Rejected"
        ? `The package ${packageDetails.packageName} has been declined successfully.`
        : `The package ${packageDetails.packageName} has been approved successfully.`;
    const postObject = {
      auth: getCookie("loggedInUser"),
      search: {},
      requestData: {
        packages: rows,
      },
    };

    commonComponent
      .sendRequestToServer(
        "ProductManagement",
        "ApproveSchoolPayPackage",
        JSON.stringify(postObject)
      )
      .then((data) => {
        setLoading(false);
        if (data.returnCode === 200) {
          setOpen(false);
          setSnackAlert({
            open: true,
            severity: "success",
            message: statusReturnMessage,
          });
          setTimeout(() => {
            navigation(routings.packages);
          }, 3000);
        } else {
          setOpen(false);
          setSnackAlert({
            show: true,
            severity: "error",
            message: "Something went wrong. Please try again.",
          });
        }
      });
  };

  const columns = [
    { header: "Product Name", accessor: "product_name" },
    { header: "Quantity", accessor: "quantity" },
    { header: "Total Cost", accessor: "totalcost" },
    { header: "Action", accessor: "action" }, // Add an action column
  ];

  const handleRemoveProduct = async () => {
    try {
      const { product_id, package_id }: any = mappingData;

      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "removeProductFromSpecifiedMapping",
        JSON.stringify({
          auth: getCookie("loggedInUser"),
          requestData: {
            packageId: package_id,
            productId: product_id,
          },
        })
      );
      const { returnCode, returnMessage } = response;
      console.log(response);

      if (returnCode === 0) {
        setremoveOpen(false);
        setSnackAlert({
          open: true,
          severity: "success",
          message: returnMessage,
        });

        setTimeout(() => {
          getMappedProducts();
        }, 500);
        getPackagewithCommissionInfo();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          Confirm Package{" "}
          {statusPackage === "Approved" ? "Approval " : "Rejection"}
        </DialogTitle>
        <DialogContent>
          <p
            className={`${
              statusPackage === "Rejected" ? "text-red-500" : "text-gray-700"
            } text-sm`}
          >
            {statusPackage === "Rejected"
              ? `Are you sure you want to reject the ${packageDetails.packageName}?`
              : `Are you sure you want to approve the package ${packageDetails.packageName}?`}
          </p>
          <label
            htmlFor="comment"
            className="block text-sm font-medium text-gray-700 my-4"
          >
            Comment or Reason
          </label>
          <textarea
            id="comment"
            className="w-full min-h-3.5 p-2 border rounded"
            placeholder="Type your comment or reason here..."
            value={packageDetails.comment}
            onChange={(e: any) =>
              setpackagedetails({ ...packageDetails, comment: e.target.value })
            }
          />
        </DialogContent>

        <DialogActions>
          <button
            onClick={handleClose}
            className="btn bg-gray-200 text-gray-700"
          >
            Cancel
          </button>
          <button
            className={`px-4 py-2 rounded-lg text-white ${
              statusPackage === "Approved"
                ? "bg-[--primary-color] hover:bg-[--secondary_color]"
                : "bg-red-500 hover:bg-red-600"
            }`}
            onClick={() => handleApproval()}
          >
            Confirm
          </button>
        </DialogActions>
      </Dialog>

      <section className="profile-section mt-8">
        <div className="container mx-auto p-6 bg-gradient-to-b from-gray-50 to-white rounded-xl shadow-lg">
          {/* Header */}
          <div className="relative mb-8">
            <h1 className="text-center font-semibold uppercase py-3 text-[--primary-color] text-xl">
              SchoolPay Package Approval
            </h1>
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-[--primary-color] rounded-full"></div>
          </div>

          {/* Two Equal Sections */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-6">
            {/* Left Section */}
            <Card
              elevation={0}
              sx={{
                overflow: "initial",
                maxWidth: "auto",
                backgroundColor: "transparent",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "translateY(-4px)",
                },
              }}
            >
              <CardMedia
                image={packageDetails.packageImage || defaultImage}
                sx={{
                  width: "100%",
                  height: 0,
                  paddingBottom: "56.25%",
                  backgroundColor: "rgba(0 0 0 / 0.08)",
                  borderRadius: "12px",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                }}
              />
              <CardContent
                sx={{
                  boxShadow: "0 4px 24px -2px rgba(0, 0, 0, 0.1)",
                  position: "relative",
                  padding: "2rem",
                  margin: "-15% 16px 0",
                  backgroundColor: "#fff",
                  borderRadius: "16px",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 6px 32px -4px rgba(0, 0, 0, 0.15)",
                  },
                }}
              >
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 16,
                    right: 16,
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 1)",
                    },
                  }}
                >
                  <More />
                </IconButton>

                <Box
                  component="h3"
                  sx={{
                    mb: 3,
                    fontWeight: "bold",
                    fontSize: "1.8rem",
                    lineHeight: 1.45,
                    color: "#2D3748",
                    borderBottom: "2px solid #EDF2F7",
                    paddingBottom: "0.5rem",
                  }}
                >
                  {packageDetails.packageName}
                </Box>

                {/* Pricing Grid */}
                <div className="grid grid-cols-3 gap-4 mb-6">
                  <div className="p-4 bg-orange-50 rounded-lg transition-all hover:shadow-md">
                    <p className="text-sm text-gray-600 mb-1">Package Price</p>
                    <p className="text-lg font-bold text-orange-600">
                      UGX {packageDetails.packagePrice || "N/A"}
                    </p>
                  </div>
                  <div className="p-4 bg-blue-50 rounded-lg transition-all hover:shadow-md">
                    <p className="text-sm text-gray-600 mb-1">Total Price</p>
                    <p className="text-lg font-bold text-blue-600">
                      UGX {commisssionPrice.totalPrice || "N/A"}
                    </p>
                  </div>
                  <div className="p-4 bg-green-50 rounded-lg transition-all hover:shadow-md">
                    <p className="text-sm text-gray-600 mb-1">
                      Grand Price(commisson Inclusive)
                    </p>
                    <p className="text-lg font-bold text-green-600">
                      UGX {commisssionPrice.totalwithCommission || "N/A"}
                    </p>
                  </div>
                </div>

                {/* Date and Type Info */}

                {/* Status Section */}
                <Box
                  mt={4}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.02)",
                    padding: "1rem",
                    borderRadius: "12px",
                    border: "1px solid rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <Box display="flex" alignItems="center" gap={2}>
                    {packageDetails.status === "Approved" ? (
                      <Avatar
                        sx={{
                          bgcolor: "#22C55E",
                          width: 40,
                          height: 40,
                          boxShadow: "0 2px 8px rgba(34, 197, 94, 0.2)",
                        }}
                      >
                        <GridCheckCircleIcon fontSize="small" />
                      </Avatar>
                    ) : packageDetails.status === "Rejected" ? (
                      <Avatar
                        sx={{
                          bgcolor: "#EF4444",
                          width: 40,
                          height: 40,
                          boxShadow: "0 2px 8px rgba(239, 68, 68, 0.2)",
                        }}
                      >
                        <Dangerous fontSize="small" />
                      </Avatar>
                    ) : (
                      <Avatar
                        sx={{
                          bgcolor: "#6B7280",
                          width: 40,
                          height: 40,
                          boxShadow: "0 2px 8px rgba(107, 114, 128, 0.2)",
                        }}
                      >
                        <MoreHoriz fontSize="small" />
                      </Avatar>
                    )}
                    <div>
                      <p className="text-sm text-gray-500">Status</p>
                      <p
                        className={`font-semibold ${
                          packageDetails.status === "Approved"
                            ? "text-green-600"
                            : packageDetails.status === "Rejected"
                            ? "text-red-600"
                            : "text-gray-600"
                        }`}
                      >
                        {packageDetails.status}
                      </p>
                    </div>
                  </Box>
                  <IconButton
                    size="medium"
                    sx={{
                      backgroundColor: "white",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
                      "&:hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                      },
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>

            {/* Right Section */}
            <Card
              elevation={0}
              sx={{
                backgroundColor: "white",
                padding: 2,
                borderRadius: "16px",
                boxShadow: "0 4px 24px -2px rgba(0, 0, 0, 0.1)",
                transition:
                  "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                "&:hover": {
                  transform: "translateY(-4px)",
                  boxShadow: "0 6px 32px -4px rgba(0, 0, 0, 0.15)",
                },
              }}
            >
              <div className="grid grid-cols-3 gap-4 mt-6 bg-gray-50 rounded-xl p-4">
                {[
                  { label: "Start Date", value: packageDetails.startDate },
                  { label: "Expiry Date", value: packageDetails.ExpireDate },
                  { label: "Package Type", value: packageDetails.packageType },
                ].map((field, index) => (
                  <div key={index} className="text-center">
                    <p className="text-sm text-gray-600 mb-1 bg-gray-200 p-2 rounded-full font-light">
                      {field.label}
                    </p>
                    <p className="font-semibold text-gray-800">{field.value}</p>
                  </div>
                ))}
              </div>
              <Typography
                variant="h6"
                component="h3"
                sx={{
                  fontWeight: "bold",
                  color: "#2D3748",
                  marginBottom: 3,
                  borderBottom: "2px solid #EDF2F7",
                  paddingBottom: "0.75rem",
                }}
              >
                Package Mapped Products
              </Typography>

              <Box className="bg-gray-50 rounded-xl p-4">
                <div className="w-full overflow-x-auto">
                  <table className="w-full border border-gray-200 shadow-md rounded-lg">
                    <thead className="bg-gray-100">
                      <tr>
                        {columns.map((col) => (
                          <th
                            key={col.accessor}
                            className="px-4 py-2 text-left text-gray-700"
                          >
                            {col.header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {mappedProducts.length > 0 ? (
                        mappedProducts.map((row, rowIndex) => (
                          <tr
                            key={rowIndex}
                            className={`${
                              rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"
                            } border-b border-gray-200`}
                          >
                            {columns.map((col) => (
                              <td
                                key={col.accessor}
                                className="px-4 py-2 text-gray-800"
                              >
                                {col.accessor === "action" ? (
                                  <button
                                    onClick={() => {
                                      setMappingData(row);
                                      setremoveOpen(true);
                                    }}
                                    className="text-red-500 hover:text-red-700 transition duration-200"
                                  >
                                    <Trash2 size={18} />
                                  </button>
                                ) : (
                                  row[col.accessor as keyof typeof row]
                                )}
                              </td>
                            ))}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={columns.length}
                            className="px-4 py-2 text-center text-gray-500"
                          >
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </Box>
              <Typography
                color="textSecondary"
                variant="body1"
                sx={{
                  mt: 2,
                  fontStyle: packageDetails.comment ? "normal" : "italic",
                  padding: "1rem",
                  backgroundColor: "rgba(0, 0, 0, 0.02)",
                  borderRadius: "8px",
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
              >
                {packageDetails.comment
                  ? packageDetails.comment
                  : "No comments have been provided for this package."}
              </Typography>
              {/* Action Buttons */}
              <Box
                display="flex"
                justifyContent="flex-end"
                gap={2}
                marginTop={2}
              >
                {packageDetails.status === "Approved" && (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#EF4444",
                      textTransform: "capitalize",
                      fontSize: "0.875rem",
                      padding: "8px 24px",
                      borderRadius: "8px",
                      boxShadow: "0 2px 8px rgba(239, 68, 68, 0.2)",
                      "&:hover": {
                        backgroundColor: "#DC2626",
                      },
                    }}
                    onClick={() => handleClickOpen("Rejected")}
                  >
                    Reject
                  </Button>
                )}
                <Button
                  variant="outlined"
                  sx={{
                    border: "2px solid #F97316",
                    color: "#F97316",
                    textTransform: "capitalize",
                    fontSize: "0.875rem",
                    padding: "8px 24px",
                    borderRadius: "8px",
                    boxShadow: "0 2px 8px rgba(249, 115, 22, 0.2)",
                    "&:hover": {
                      backgroundColor: "#F97316",
                      border: "2px solid #F97316",
                      color: "white",
                    },
                  }}
                  onClick={() =>
                    navigation("/package-product-mapping/" + packageId)
                  }
                >
                  <Plus /> Add Product
                </Button>

                {(packageDetails.status === "Rejected" ||
                  packageDetails.status === "Awaiting Approval") && (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#F97316",
                      textTransform: "capitalize",
                      fontSize: "0.875rem",
                      padding: "8px 24px",
                      borderRadius: "8px",
                      boxShadow: "0 2px 8px rgba(249, 115, 22, 0.2)",
                      "&:hover": {
                        backgroundColor: "#EA580C",
                      },
                    }}
                    onClick={() => handleClickOpen("Approved")}
                  >
                    Approve
                  </Button>
                )}
              </Box>
            </Card>
          </div>
        </div>
      </section>
      <FormDialog
        open={removeOpen}
        type="confirm"
        title="Remove Product?"
        content={
          <p className="text-black px-2">
            This can't be undone. Proceed with caution.
          </p>
        }
        onClose={() => setremoveOpen(false)}
        onConfirm={() => handleRemoveProduct()}
      />
    </>
  );
};

export default PackageApprovalDetails;
