import React, { useEffect, useState } from "react";
import {
  Search,
  CheckCircle,
  Calendar,
  GraduationCap,
  Building2,
  MapPin,
  ArrowRight,
  School,
} from "lucide-react";
import { CommonComponent } from "../../../service";
import { getCookie } from "../../../service/get-user";
import { AppContext } from "../../../context/AppContext";

type School = {
  school_code: number;
  physical_addr: string;
  enrolled_on: string;
  school_type: string;
  school_name: string;
  id: number;
  status: string;
};

const req = new CommonComponent();
const auth = getCookie("loggedInUser");

function VendorShool() {
  const [searchTerm, setSearchTerm] = useState("");
  const [school, setSchool] = useState<any[]>([]);

  const [selectedSchool, setSelectedSchool] = useState<School | any>(null);
  const [viewStep, setViewStep] = useState<"list" | "details" | "success">(
    "list"
  );
  const [vendors, setVendors] = useState<any[]>([]);

  const { setLoading, setSnackAlert }: any = React.useContext(AppContext);

  // Mock data for demonstration

  const filteredSchools = school.filter(
    (school) =>
      school.school_code.toString().includes(searchTerm.toLowerCase()) ||
      school.school_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      school.school_type.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    let sub = true;

    if (sub) {
      getAllEnrolledSchools();
      getAllActiveVendors();
    }

    return () => {
      sub = false;
    };
  }, []);

  const getAllActiveVendors = async () => {
    setLoading(true);
    const postObject = {
      search: { clientId: "working well" },
      auth,
    };
    try {
      const response: Promise<any> = await req.sendRequestToServer(
        "ClientManagement",
        "getClientWithoutSession",
        JSON.stringify(postObject)
      );

      const { returnCode, returnData }: any = response;

      if (returnCode === 200) {
        setLoading(false);

        setVendors(returnData.rows[0]);
      } else {
        setLoading(false);

        setSnackAlert({
          open: true,
          severity: "error",
          message: "We're Having Trouble Retrieving Enrolled Schools",
        });

        // change route
        setTimeout(() => {
          // history.push("/enroll_school");
          setSnackAlert({ open: false, severity: "error", message: "" });
        }, 5000);
      }
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getAllEnrolledSchools = async () => {
    setLoading(true);

    const postObject = {
      search: {},
    };
    try {
      const response: Promise<any> = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "getEnrolledSchools",
        JSON.stringify(postObject)
      );

      const { returnCode, returnData }: any = response;

      if (returnCode === 200) {
        setLoading(false);

        setSchool(returnData);
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: "We're Having Trouble Retrieving Enrolled Schools",
        });

        // change route
        setTimeout(() => {
          // history.push("/enroll_school");
          setSnackAlert({ open: false, severity: "error", message: "" });
        }, 5000);
      }
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  const handleSchoolSelect = (school: School) => {
    setSelectedSchool(school);
    setViewStep("details");
  };

  const handleConfirmMapping = async () => {
    if (!selectedSchool) return;

    const postObject = {
      vendorId: vendors.id,
      schoolIdArray: [selectedSchool.id],
      auth,
      initiator: "vendor",
    };

    try {
      setLoading(true);

      const { returnCode, returnMessage } = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "mapVendorToSchoolWithoutSession",
        JSON.stringify(postObject)
      );

      if (returnCode === 200) {
        setSnackAlert({
          open: true,
          severity: "success",
          message: "Vendor Mapping Successful",
        });

        setViewStep("success");

        setTimeout(() => {
          setSnackAlert({ open: false, severity: "success", message: "" });
        }, 5000);
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: returnMessage,
        });

        setTimeout(() => {
          setSnackAlert({ open: false, severity: "error", message: "" });
          setViewStep("list");
        }, 5000);
      }
    } catch (error) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "An error occurred while mapping the vendor: " + error.message,
      });

      setTimeout(() => {
        setSnackAlert({ open: false, severity: "error", message: "" });
        setViewStep("list");
      }, 5000);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setSelectedSchool(null);
    setViewStep("list");
    setSearchTerm("");
  };

  const renderSchoolCard = (school: School) => (
    <div
      key={school.id}
      className={`p-6 rounded-xl transition-all cursor-pointer ${
        selectedSchool?.id === school.id
          ? "bg-blue-50 border-2 border-[--primary-color]"
          : "bg-white hover:bg-gray-50 border-2 border-transparent"
      }`}
      onClick={() => handleSchoolSelect(school)}
    >
      <div className="flex items-start justify-between">
        <div className="space-y-2">
          <div className="flex items-center gap-2">
            <School className="text-[--primary-color]" size={20} />
            <span className="font-mono text-sm bg-blue-100 text-[--primary-color] px-2 py-1 rounded">
              {school.school_code}
            </span>
            <span className="font-mono text-sm bg-purple-100 text-purple-800 px-2 py-1 rounded">
              {school.school_type}
            </span>
          </div>
          <h3 className="text-lg font-semibold text-gray-800">
            {school.school_name}
          </h3>
          <div className="flex items-center gap-4 text-sm text-gray-600">
            <div className="flex items-center gap-1">
              <MapPin size={16} />
              {school.physical_addr}
            </div>
            <div className="flex items-center gap-1">
              <Calendar size={16} />
              Enrolled: {school.enrolled_on}
            </div>
          </div>
        </div>
        <span
          className={`px-3 py-1 rounded-full text-sm ${
            school.status === "Active"
              ? "bg-green-100 text-green-800"
              : "bg-yellow-100 text-yellow-800"
          }`}
        >
          {school.status}
        </span>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 p-8">
      <div className="max-w-7xl mx-auto">
        {/* Progress Steps */}
        <div className="mb-8">
          <div className="flex items-center justify-center gap-4">
            {["list", "details", "success"].map((step, index) => (
              <React.Fragment key={step}>
                <div
                  className={`flex items-center gap-2 ${
                    viewStep === step
                      ? "text-[--primary-color]"
                      : "text-gray-400"
                  }`}
                >
                  <div
                    className={`w-8 h-8 rounded-full flex items-center justify-center ${
                      viewStep === step
                        ? "bg-[--primary-color] text-white"
                        : "bg-gray-200 text-gray-600"
                    }`}
                  >
                    {index + 1}
                  </div>
                  <span className="font-medium capitalize">{step}</span>
                </div>
                {index < 2 && (
                  <ArrowRight className="text-gray-400" size={20} />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>

        {viewStep === "list" && (
          <>
            {/* Search Section */}
            <div className="bg-white rounded-xl shadow-lg p-6 mb-6">
              <div className="relative">
                <Search
                  className="absolute left-4 top-3.5 text-gray-400"
                  size={20}
                />
                <input
                  type="text"
                  className="w-full pl-12 pr-4 py-3 border border-gray-200 rounded-xl focus:ring-2 focus:ring-[--primary-color] focus:border-transparent text-lg"
                  placeholder="Search by school code, name, or type..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>

            {/* School List */}
            <div className="space-y-4 max-h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
              {filteredSchools.map((school) => renderSchoolCard(school))}
            </div>
          </>
        )}

        {viewStep === "details" && selectedSchool && (
          <div className="bg-white rounded-xl shadow-lg p-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-6">
              School Details
            </h2>
            <div className="grid grid-cols-2 gap-8 mb-8">
              <div className="space-y-4">
                <div className="space-y-2">
                  <label className="text-sm text-gray-500">School Code</label>
                  <div className="flex items-center gap-2">
                    <School className="text-[--primary-color]" size={20} />
                    <span className="font-mono text-lg">
                      {selectedSchool.school_code}
                    </span>
                  </div>
                </div>
                <div className="space-y-2">
                  <label className="text-sm text-gray-500">School Name</label>
                  <div className="flex items-center gap-2">
                    <Building2 className="text-[--primary-color]" size={20} />
                    <span className="text-lg">
                      {selectedSchool.school_name}
                    </span>
                  </div>
                </div>
                <div className="space-y-2">
                  <label className="text-sm text-gray-500">School Type</label>
                  <div className="flex items-center gap-2">
                    <GraduationCap
                      className="text-[--primary-color]"
                      size={20}
                    />
                    <span className="text-lg">
                      {selectedSchool.school_type}
                    </span>
                  </div>
                </div>
              </div>
              <div className="space-y-4">
                <div className="space-y-2">
                  <label className="text-sm text-gray-500">
                    Physical Address
                  </label>
                  <div className="flex items-center gap-2">
                    <MapPin className="text-[--primary-color]" size={20} />
                    <span className="text-lg">
                      {selectedSchool.physical_addr}
                    </span>
                  </div>
                </div>
                <div className="space-y-2">
                  <label className="text-sm text-gray-500">Status</label>
                  <div className="flex items-center gap-2">
                    <span
                      className={`px-3 py-1 rounded-full text-sm ${
                        selectedSchool.status === "Active"
                          ? "bg-green-100 text-green-800"
                          : "bg-yellow-100 text-yellow-800"
                      }`}
                    >
                      {selectedSchool.status}
                    </span>
                  </div>
                </div>
                <div className="space-y-2">
                  <label className="text-sm text-gray-500">Enrolled On</label>
                  <div className="flex items-center gap-2">
                    <Calendar className="text-[--primary-color]" size={20} />
                    <span className="text-lg">
                      {selectedSchool.enrolled_on}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <button
              onClick={handleConfirmMapping}
              className="w-full py-3 bg-[--primary-color] text-white rounded-xl hover:bg-[--primary-color] flex items-center justify-center gap-2 text-lg font-medium"
            >
              <CheckCircle size={20} />
              Confirm Mapping
            </button>
          </div>
        )}

        {viewStep === "success" && selectedSchool && (
          <div className="bg-white rounded-xl shadow-lg p-8 text-center">
            <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-6">
              <CheckCircle className="text-green-600" size={32} />
            </div>
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              School Details Viewed!
            </h2>
            <p className="text-gray-600 mb-6">
              You have successfully viewed the details for{" "}
              {selectedSchool.school_name}.
            </p>
            <button
              onClick={handleReset}
              className="px-6 py-3 bg-[--primary-color] text-white rounded-xl hover:bg-[--primary-color]"
            >
              Mapping Another School
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default VendorShool;
