/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Check,
  AlertCircle,
  ChevronDown,
  Package,
  School,
  Save,
  ArrowRight,
  Filter,
  List,
} from "lucide-react";
import { AppContext } from "../../context/AppContext";
import { CommonComponent } from "../../service";
import { useParams } from "react-router-dom";
import { getCookie } from "../../service/get-user";

const req = new CommonComponent();
const auth = getCookie("loggedInUser");

function SchoolClassToPackage() {
  // Sample data

  // State management
  const [selectedClass, setSelectedClass] = useState<any>(null);
  const [selectedPackage, setSelectedPackage] = useState<any>(null);
  const [isClassDropdownOpen, setIsClassDropdownOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [mappingComplete, setMappingComplete] = useState(false);
  const [packages, setpackages] = useState<any[]>([]);
  const [classes, setClasses] = useState<any[]>([]);
  const [schoolData, setSchoolData] = useState<any>({});

  // Package list and filtering
  const [showPackageList, setShowPackageList] = useState(true);
  const [packageNameFilter, setPackageNameFilter] = useState("");
  const [minPriceFilter, setMinPriceFilter] = useState("");
  const [maxPriceFilter, setMaxPriceFilter] = useState("");
  const [filteredPackages, setFilteredPackages] = useState(packages);

  const { setLoading, setSnackAlert }: any = React.useContext(AppContext);
  const { schoolCode } = useParams();

  useEffect(() => {
    let filtered = [...packages];
    // Filter by package name
    if (packageNameFilter) {
      filtered = filtered.filter((pkg) =>
        pkg.package_name.toLowerCase().includes(packageNameFilter.toLowerCase())
      );
    }

    // Filter by min price
    if (minPriceFilter) {
      const minPrice = parseInt(minPriceFilter.replace(/,/g, ""));
      if (!isNaN(minPrice)) {
        filtered = filtered.filter((pkg) => {
          const packagePrice = parseInt(pkg.package_price.replace(/,/g, ""));
          return packagePrice >= minPrice;
        });
      }
    }

    // Filter by max price
    if (maxPriceFilter) {
      const maxPrice = parseInt(maxPriceFilter.replace(/,/g, ""));
      if (!isNaN(maxPrice)) {
        filtered = filtered.filter((pkg) => {
          const packagePrice = parseInt(pkg.package_price.replace(/,/g, ""));
          return packagePrice <= maxPrice;
        });
      }
    }

    setFilteredPackages(filtered);
  }, [packageNameFilter, minPriceFilter, maxPriceFilter]);

  // Clear messages after 5 seconds
  useEffect(() => {
    if (successMessage || errorMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
        setErrorMessage("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [successMessage, errorMessage]);

  useEffect(() => {
    let sub = true;

    if (sub) {
      // Fetch packages and classes
      getAllEnrolledSchools();
      getSchoolInfor();
      getPackages();
    }

    return () => {
      sub = false;
    };
  }, []);

  // Handle class selection
  const handleClassSelect = (classItem) => {
    setSelectedClass(classItem);
    setIsClassDropdownOpen(false);
    setCurrentStep(2);
    // Reset other states when class changes
    setSelectedPackage(null);
    setMappingComplete(false);
  };

  console.log("school data ", schoolData);
  const getAllEnrolledSchools = async () => {
    setLoading(true);

    const postObject = {
      search: { schoolCode },
    };
    try {
      const response: Promise<any> = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "getEnrolledSchools",
        JSON.stringify(postObject)
      );

      const { returnCode, returnData }: any = response;

      if (returnCode === 200) {
        setLoading(false);

        setSchoolData(returnData[0]);
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: "We're Having Trouble Retrieving Enrolled Schools",
        });

        // change route
        setTimeout(() => {
          // history.push("/enroll_school");
          setSnackAlert({ open: false, severity: "error", message: "" });
        }, 5000);
      }
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const getSchoolInfor = () => {
    const postObject = {
      schoolCode: schoolCode, //schoolId,
    };
    try {
      setLoading(true);
      req
        .sendRequestToServer(
          "SchoolpayShoppingHelper",
          "getSchoolInfo",
          JSON.stringify(postObject)
        )
        .then((data) => {
          const { returnCode, returnData, returnMessage }: any = data;
          if (returnCode === 200) {
            setLoading(false);
            setClasses(returnData.classList);
            if (returnData.classList.length > 0) {
            } else {
              setLoading(false);

              setSnackAlert({
                show: true,
                severity: "error",
                message:
                  "No classes found for this school or school does not exist on the platform",
              });
            }
          } else {
            setLoading(false);

            setSnackAlert({
              show: true,
              severity: "error",
              message: `${returnMessage} and can not retrieve the school classes`,
            });
          }
        });
    } catch (error) {
      setSnackAlert({
        show: true,
        severity: "error",
        message: `${error} `,
      });
    }
  };

  // Handle package school class mapping
  const handleSaveAndMapPackage = () => {
    if (!selectedClass || !selectedPackage) {
      setErrorMessage("Please select both a class and a package before saving");
      return;
    }

    setIsLoading(true);

    // Prepare API request data
    const postObject = {
      requestData: {
        packageId: selectedPackage.id,
        schoolId: schoolData.id,
        classIds: [selectedClass], // Ensure class IDs are in an array
      },
    };

    if (postObject.requestData.schoolId.length < 1) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "School Id is null or not found",
      });
    }

    try {
      req
        .sendRequestToServer(
          "SchoolpayShoppingHelper",
          "AddmapPackageToSchoolClasses",
          JSON.stringify(postObject)
        )
        .then((data) => {
          setIsLoading(false);
          if (data.returnCode === 200) {
            setSnackAlert({
              open: true,
              severity: "success",
              message: data.returnMessage,
            });

            setTimeout(() => {
              setSuccessMessage(
                `Successfully mapped ${selectedClass.className} to package: ${selectedPackage.package_name}`
              );
              setMappingComplete(true);
              setCurrentStep(4);
            }, 1000);
          } else {
            setSnackAlert({
              open: true,
              severity: "error",
              message: data.returnMessage,
            });

            setErrorMessage(data.returnMessage);
          }
        });
    } catch (error: any) {
      setIsLoading(false);
      setSnackAlert({
        open: true,
        severity: "error",
        message:
          "Error occurred while mapping package to classes: " + error.message,
      });

      setErrorMessage(
        "Error occurred while mapping package to classes: " + error.message
      );
    }
  };

  const getPackages = () => {
    const postObject = {
      auth,
      search: { schoolCode, status: "Approved" },
    };
    try {
      req
        .sendRequestToServer(
          "SchoolpayShoppingHelper",
          "getSchoolMappedPackages",
          JSON.stringify(postObject)
        )
        .then((data) => {
          if (data.returnCode === 200) {
            setpackages(data.returnData);
          } else {
            console.log(
              "Unexpected return code",
              data.returnCode,
              data.returnMessage
            );
          }
        })
        .catch((error) => {
          console.error(
            "Error occurred while retrieving packages:",
            error.message
          );
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  // Handle package selection from list
  const handlePackageSelect = (pkg) => {
    setSelectedPackage(pkg);
    setCurrentStep(3);
    setShowPackageList(false);
  };

  // Handle reset
  const handleReset = () => {
    setSelectedClass(null);
    setSelectedPackage(null);
    setSuccessMessage("");
    setErrorMessage("");
    setMappingComplete(false);
    setCurrentStep(1);
    setShowPackageList(false);
    setPackageNameFilter("");
    setMinPriceFilter("");
    setMaxPriceFilter("");
  };

  // Toggle package list view
  const togglePackageList = () => {
    setShowPackageList(!showPackageList);
    if (!showPackageList) {
      // Reset filters when opening
      setPackageNameFilter("");
      setMinPriceFilter("");
      setMaxPriceFilter("");
      setFilteredPackages(packages);
    }
  };

  // Format price for display
  const formatPrice = (price) => {
    return `UGX ${price}`;
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-50 p-4 md:p-8">
      {/* Header */}
      <div className="max-w-7xl mx-auto bg-white rounded-xl shadow-md overflow-hidden mb-8">
        <div className="bg-[--school-pay-primary-color] p-6 text-white">
          <h1 className="text-2xl font-bold">Class Package Mapping</h1>
          <p className="text-indigo-100">
            Map school classes to available packages
          </p>
        </div>

        {/* Progress Steps */}
        <div className="px-6 pt-6">
          <div className="flex items-center justify-between mb-8">
            {[1, 2, 3, 4].map((step) => (
              <div key={step} className="flex flex-col items-center relative">
                <div
                  className={`w-10 h-10 rounded-full flex items-center justify-center ${
                    currentStep >= step
                      ? "bg-[--school-pay-primary-color] text-white"
                      : "bg-gray-200 text-gray-500"
                  }`}
                >
                  {step === 1 && <School size={18} />}
                  {step === 2 && <Package size={18} />}
                  {step === 3 && <Check size={18} />}
                  {step === 4 && <Save size={18} />}
                </div>
                <div className="text-xs mt-2 text-center">
                  {step === 1 && "Select Class"}
                  {step === 2 && "Find Package"}
                  {step === 3 && "Verify Details"}
                  {step === 4 && "Complete"}
                </div>
                {step < 4 && (
                  <div
                    className={`absolute top-5 left-full w-full h-0.5 -ml-2 ${
                      currentStep > step
                        ? "bg-[--school-pay-primary-color]"
                        : "bg-gray-200"
                    }`}
                    style={{ width: "calc(100% - 2.5rem)" }}
                  ></div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Notification Messages */}
        {successMessage && (
          <div className="mx-6 mb-4 p-3 bg-green-100 border border-green-200 text-green-700 rounded-md flex items-center">
            <Check size={18} className="mr-2" />
            <span>{successMessage}</span>
          </div>
        )}

        {errorMessage && (
          <div className="mx-6 mb-4 p-3 bg-red-100 border border-red-200 text-red-700 rounded-md flex items-center">
            <AlertCircle size={18} className="mr-2" />
            <span>{errorMessage}</span>
          </div>
        )}

        {/* Main Content */}
        <div className="p-6">
          {/* Step 1: Class Selection */}
          <div
            className={`mb-8 ${
              currentStep !== 1 && selectedClass ? "opacity-70" : ""
            }`}
          >
            <h2 className="text-lg font-semibold mb-3 flex items-center">
              <School
                size={18}
                className="mr-2 text-[--school-pay-primary-color]"
              />
              Step 1: Select School Class
            </h2>

            <div className="relative">
              <div
                className={`border ${
                  isClassDropdownOpen
                    ? "border-[--school-pay-primary-color] ring-2 ring-indigo-200"
                    : "border-gray-300"
                } 
                  rounded-md p-3 flex justify-between items-center cursor-pointer`}
                onClick={() =>
                  !mappingComplete &&
                  setIsClassDropdownOpen(!isClassDropdownOpen)
                }
              >
                {selectedClass ? (
                  <div>
                    <span className="font-medium">
                      {selectedClass.className}
                    </span>
                    <span className="text-gray-500 text-sm ml-2">
                      ({selectedClass.classCode})
                    </span>
                  </div>
                ) : (
                  <span className="text-gray-500">Select a class...</span>
                )}
                <ChevronDown
                  size={18}
                  className={`text-gray-400 transition-transform ${
                    isClassDropdownOpen ? "transform rotate-180" : ""
                  }`}
                />
              </div>

              {isClassDropdownOpen && (
                <div className="absolute z-10 mt-1 w-full bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
                  {classes.map((classItem) => (
                    <div
                      key={classItem.classId}
                      className="p-3 hover:bg-indigo-50 cursor-pointer flex justify-between items-center border-b border-gray-100 last:border-0"
                      onClick={() => handleClassSelect(classItem)}
                    >
                      <div>
                        <div className="font-medium">{classItem.className}</div>
                        <div className="text-sm text-gray-500">
                          Code: {classItem.classCode}
                        </div>
                      </div>
                      <div className="text-xs bg-gray-100 px-2 py-1 rounded-full">
                        ID: {classItem.classId}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Step 2: Package Search */}
          <div
            className={`mb-8 ${
              currentStep < 2
                ? "opacity-50 pointer-events-none"
                : currentStep > 2 && selectedPackage
                ? "opacity-70"
                : ""
            }`}
          >
            <h2 className="text-lg font-semibold mb-3 flex items-center">
              <Package
                size={18}
                className="mr-2 text-[--school-pay-primary-color]"
              />
              Step 2: Find Package
            </h2>

            {/* Browse Packages Button */}
            <button
              onClick={togglePackageList}
              disabled={!selectedClass || mappingComplete}
              className={`w-full p-2 rounded-md font-medium flex items-center justify-center ${
                !selectedClass || mappingComplete
                  ? "bg-gray-100 text-gray-500 cursor-not-allowed"
                  : "bg-indigo-100 text-[--school-pay-primary-color] hover:bg-indigo-200"
              }`}
            >
              <List size={18} className="mr-2" />
              {showPackageList ? "Hide Package List" : "Browse All Packages"}
            </button>

            {/* Package List with Filters */}
            {showPackageList && (
              <div className="mt-4 border border-indigo-100 rounded-lg overflow-hidden">
                {/* Filters */}
                <div className="bg-indigo-50 p-4 border-b border-indigo-100">
                  <h3 className="text-sm font-semibold text-[--school-pay-primary-color] mb-3 flex items-center">
                    <Filter size={16} className="mr-2" />
                    Filter Packages
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                    <div>
                      <label className="block text-xs text-[--school-pay-primary-color] mb-1">
                        Package Name
                      </label>
                      <input
                        type="text"
                        value={packageNameFilter}
                        onChange={(e) => setPackageNameFilter(e.target.value)}
                        placeholder="Search by name"
                        className="w-full p-2 text-sm border border-indigo-200 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-300"
                      />
                    </div>
                    <div>
                      <label className="block text-xs text-[--school-pay-primary-color] mb-1">
                        Min Price (UGX)
                      </label>
                      <input
                        type="text"
                        value={minPriceFilter}
                        onChange={(e) => setMinPriceFilter(e.target.value)}
                        placeholder="Min price"
                        className="w-full p-2 text-sm border border-indigo-200 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-300"
                      />
                    </div>
                    <div>
                      <label className="block text-xs text-[--school-pay-primary-color] mb-1">
                        Max Price (ugx)
                      </label>
                      <input
                        type="text"
                        value={maxPriceFilter}
                        onChange={(e) => setMaxPriceFilter(e.target.value)}
                        placeholder="Max price"
                        className="w-full p-2 text-sm border border-indigo-200 rounded-md focus:outline-none focus:ring-1 focus:ring-indigo-300"
                      />
                    </div>
                  </div>
                </div>

                {/* Package List */}
                <div className="max-h-80 overflow-y-auto">
                  {filteredPackages.length === 0 ? (
                    <div className="p-6 text-center text-gray-500">
                      No packages match your filters
                    </div>
                  ) : (
                    filteredPackages.map((pkg) => (
                      <div
                        key={pkg.id}
                        className="p-4 border-b border-gray-100 hover:bg-indigo-50 cursor-pointer transition-colors"
                        onClick={() => handlePackageSelect(pkg)}
                      >
                        <div className="flex justify-between items-start">
                          <div>
                            <h4 className="font-medium text-[--school-pay-primary-color]">
                              {pkg.package_name}
                            </h4>
                            <div className="text-sm text-gray-600 mt-1">
                              ID: {pkg.id}
                            </div>
                            <div className="flex items-center mt-2 space-x-4 text-sm">
                              <div className="text-gray-600">
                                <span className="font-medium text-[--school-pay-primary-color]">
                                  {formatPrice(pkg.package_price)}
                                </span>
                              </div>
                              <div className="text-gray-600">
                                Valid: {pkg.package_start_date} -{" "}
                                {pkg.package_end_date}
                              </div>
                            </div>
                          </div>
                          <div>
                            <span
                              className={`px-2 py-1 rounded-full text-xs font-medium ${
                                pkg.status === "Approved"
                                  ? "bg-green-100 text-green-800"
                                  : "bg-yellow-100 text-yellow-800"
                              }`}
                            >
                              {pkg.status}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Step 3: Verification */}
          {selectedPackage && (
            <div
              className={`mb-8 ${
                currentStep < 3 ? "opacity-50 pointer-events-none" : ""
              }`}
            >
              <h2 className="text-lg font-semibold mb-3 flex items-center">
                <Check
                  size={18}
                  className="mr-2 text-[--school-pay-primary-color]"
                />
                Step 3: Verify Details
              </h2>

              <div className="bg-indigo-50 rounded-lg p-4 mb-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <h3 className="font-medium text-[--school-pay-primary-color] mb-2">
                      Class Details
                    </h3>
                    <div className="bg-white p-3 rounded-md shadow-sm">
                      <div className="mb-2">
                        <span className="text-gray-500 text-sm">
                          Class Name:
                        </span>
                        <span className="font-medium ml-2">
                          {selectedClass.className}
                        </span>
                      </div>
                      <div className="mb-2">
                        <span className="text-gray-500 text-sm">
                          Class Code:
                        </span>
                        <span className="font-medium ml-2">
                          {selectedClass.classCode}
                        </span>
                      </div>
                      <div>
                        <span className="text-gray-500 text-sm">Class ID:</span>
                        <span className="font-medium ml-2">
                          {selectedClass.classId}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h3 className="font-medium text-[--school-pay-primary-color] mb-2">
                      Package Details
                    </h3>
                    <div className="bg-white p-3 rounded-md shadow-sm">
                      <div className="mb-2">
                        <span className="text-gray-500 text-sm">
                          Package Name:
                        </span>
                        <span className="font-medium ml-2">
                          {selectedPackage.package_name}
                        </span>
                      </div>
                      <div className="mb-2">
                        <span className="text-gray-500 text-sm">
                          Package ID:
                        </span>
                        <span className="font-medium ml-2">
                          {selectedPackage.id}
                        </span>
                      </div>
                      <div className="mb-2">
                        <span className="text-gray-500 text-sm">Price:</span>
                        <span className="font-medium ml-2">
                          UGX {selectedPackage.package_price}
                        </span>
                      </div>
                      <div className="mb-2">
                        <span className="text-gray-500 text-sm">
                          Valid Period:
                        </span>
                        <span className="font-medium ml-2">
                          {selectedPackage.package_start_date} to{" "}
                          {selectedPackage.package_end_date}
                        </span>
                      </div>
                      <div>
                        <span className="text-gray-500 text-sm">Status:</span>
                        <span
                          className={`ml-2 px-2 py-0.5 rounded-full text-xs font-medium ${
                            selectedPackage.status === "Approved"
                              ? "bg-green-100 text-green-800"
                              : "bg-yellow-100 text-yellow-800"
                          }`}
                        >
                          {selectedPackage.status}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  onClick={handleReset}
                  disabled={isLoading || mappingComplete}
                  className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 font-medium"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSaveAndMapPackage}
                  disabled={isLoading || mappingComplete}
                  className={`px-4 py-2 rounded-md font-medium flex items-center ${
                    isLoading || mappingComplete
                      ? "bg-gray-200 text-gray-500 cursor-not-allowed"
                      : "bg-[--school-pay-primary-color] text-white hover:bg-[--school-pay-primary-color]"
                  }`}
                >
                  {isLoading ? (
                    <svg
                      className="animate-spin h-5 w-5 mr-2"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                        fill="none"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    <Save size={18} className="mr-2" />
                  )}
                  Save Mapping
                </button>
              </div>
            </div>
          )}

          {/* Step 4: Completion */}
          {mappingComplete && (
            <div className="bg-green-50 border border-green-100 rounded-lg p-6 text-center">
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <Check size={24} className="text-green-600" />
              </div>
              <h3 className="text-xl font-bold text-green-800 mb-2">
                Mapping Complete!
              </h3>
              <p className="text-green-700 mb-4">
                You have successfully mapped{" "}
                <span className="font-semibold">{selectedClass.className}</span>{" "}
                to
                <span className="font-semibold">
                  {" "}
                  {selectedPackage.package_name}
                </span>
                .
              </p>
              <button
                onClick={handleReset}
                className="px-6 py-2 bg-white border border-green-300 rounded-md text-green-700 hover:bg-green-50 font-medium inline-flex items-center"
              >
                <ArrowRight size={18} className="mr-2" />
                Map Another Class
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SchoolClassToPackage;
