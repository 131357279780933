import { spinnerService } from "@chevtek/react-spinners";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import MessagesComponent from "../../components/GenericComponents/MessagesComponent";
import { CommonComponent } from "../../service/CommonComponent";
import { getCookie } from "../../service/get-user";

interface MatchParams {
  brandId?: string;
  brandName?: string;
}

const AddProductBrand: React.FC = () => {
  const { brandId, brandName } = useParams<MatchParams | any>(); // Destructure brandId and brandName from route params
  const [brandNameInput, setBrandNameInput] = useState<string>(brandName || "");
  const [file] = useState<File | null>(null);
  const [id, setId] = useState<number | null>(
    brandId ? parseInt(brandId) : null
  );
  const [status] = useState<string>("Active");
  const [processing, setProcessing] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);

  const validator = new SimpleReactValidator();
  const commonComponent = new CommonComponent();
  const auth = getCookie("loggedInUser");
  const navigate = useNavigate();

  useEffect(() => {
    if (brandId) {
      setId(parseInt(brandId));
    }
    if (brandName) {
      setBrandNameInput(brandName);
    }
  }, [brandId, brandName]);

  const saveBrand = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validator.allValid()) {
      const postObject = {
        auth: auth,
        search: {},
        requestData: {
          id,
          brandName: brandNameInput,
          brandLogo: file,
          status: status,
        },
      };
      setProcessing(true); // Start processing spinner
      commonComponent
        .sendRequestToServer(
          "ProductManagement",
          "addProductBrand",
          JSON.stringify(postObject)
        )
        .then((response) => {
          const { returnCode, returnMessage } = response;
          if (returnCode === 200) {
            setSuccess(true);
            setTimeout(() => {
              navigate("/product-brands");
            }, 1000);
          } else {
            setSuccess(false);
            console.error(returnMessage);
          }
          setProcessing(false); // Stop processing spinner
        });
    } else {
      validator.showMessages();
    }
  };

  let alertMessage;
  switch (success) {
    case true:
      alertMessage = (
        <MessagesComponent
          messageType={"Success"}
          message="Brand saved successfully!"
        />
      );
      break;
    case false:
      alertMessage = (
        <MessagesComponent
          messageType={"Error"}
          message="An error occurred while saving the brand."
        />
      );
      break;
    default:
      alertMessage = null;
      break;
  }

  return (
    <div className="p-grid p-fluid">
      <div className="p-col-12 p-lg-12">
        <div className="card card-w-title">
          {alertMessage}
          <h1>Add Brand</h1>
          <form onSubmit={saveBrand} encType="multipart/form-data">
            <div className="p-grid">
              <div className="p-col-12 p-md-6">
                <InputText
                  id="brandName"
                  value={brandNameInput}
                  onChange={(e) => setBrandNameInput(e.target.value)}
                  tooltip="Enter Brand name"
                  tooltipOptions={{ position: "top" }}
                  placeholder="Brand Name"
                />
                <div className="text-danger">
                  {validator.message("brandName", brandNameInput, "required")}
                </div>
              </div>
            </div>
            <div className="p-grid">
              <div className="p-col-12 p-md-6">
                <Button
                  label="Save"
                  type="submit"
                  className="p-button-raised"
                  disabled={processing}
                  style={styles.button}
                />
                {processing
                  ? spinnerService.show("loader")
                  : spinnerService.hide("loader")}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const styles = {
  button: {
    height: "40px",
  },
};

export default AddProductBrand;
