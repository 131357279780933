// import React from "react";
// import { Link, Route } from "react-router-dom";
// import { routings } from "../../routes/routings.ts";
// import { getCookie } from "../../service/get-user.js";
// import MainView from "../../views/Dashboard/MainView.tsx";

// const loggedInUser = getCookie("loggedInUser");

// const RouteGuard = ({
//   component: Component,
//   isPrivateRoute,
//   isNotCustomer,
//   ...rest
// }) => {
//   return (
//     <>
//       <Route
//         // {...rest}
//         element={
//           isPrivateRoute && loggedInUser && loggedInUser.loggedIn
//             ? (props: any) => (
//                 <MainView>
//                   <Component {...props} />
//                 </MainView>
//               )
//             : !isPrivateRoute
//             ? Component
//             : () => <Link to={routings.login} />
//         }
//       />
//     </>
//   );
// };

// export const PrivateRoute = RouteGuard;

import React from "react";
import { Navigate } from "react-router-dom";
import { getCookie } from "../../service/get-user"; // Function to get cookies
import MainView from "../../views/Dashboard/MainView.tsx";

// PrivateRoute wrapper
const PrivateRoute = ({
  isPrivateRoute,
  isNotCustomer,
  component: Component,
}) => {
  const loggedInUser = getCookie("loggedInUser");

  // Check if the user is logged in
  if (!loggedInUser || !loggedInUser.loggedIn) {
    return <Navigate to="/login" />;
  }

  // Check if the user is a customer
  if (!isNotCustomer && loggedInUser) {
    return <Navigate to="/" />; // Redirect to home or any unauthorized page
  }

  return (
    <MainView>
      <Component /> {/* Renders the matching child route */}
    </MainView>
  );
};

export default PrivateRoute;
