import LinearProgress from "@material-ui/core/LinearProgress";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { BadgeInfo } from "lucide-react";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ConnectionAlert } from "../components/ConnectionAlert.tsx";
import SnackBarAlert from "../components/CustomComponents/Alerts/SnackBarAlert.tsx";
import { addToCart as updateCartContent } from "../redux/actions/cart-actions.ts";
import { CommonComponent } from "../service";
import { getCookie, loggedInUser } from "../service/get-user";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#ffa200",
    },
  })
);

// Define the AppContext
export const AppContext = React.createContext(null); // No need to pass Context as an argument

const ContextProvider = ({ children }) => {
  const [loading, setLoading] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [isProgressVisible, setIsProgressVisible] = React.useState(false);
  const [sideMenusLoaded, setSideMenusLoaded] = React.useState(false);

  const [snackAlert, setSnackAlert] = React.useState({
    open: false,
    severity: "",
    message: "",
  });
  //Cart state
  const [cartItems, setCartItems] = React.useState([]);
  //WishLIst state
  const [wishlistItems, setWishlistItems] = React.useState([]);
  const [unreadCount, setUnreadCount] = React.useState(0);

  const commonComponent = new CommonComponent();
  const classes = useStyles();

  const isLoggedIn = loggedInUser()?.loggedIn;
  useEffect(() => {
    let sub = true;

    if (sub && isLoggedIn) {
      fetchCartItems();
      fetchWishlistItems();
      fetchAlerts();
    }

    return () => {
      sub = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  // Cart Methods
  const addToCart = async (item) => {
    try {
      const payload = {
        auth: getCookie("loggedInUser"),
        product_id: item.id,
        quantity: 1,
      };

      // Check if the cart already has items from a different vendor
      if (cartItems.length > 0) {
        const firstItemVendor = cartItems[0].client_id;
        if (firstItemVendor !== item.client_id) {
          setSnackAlert({
            open: true,
            severity: "warning",
            message:
              "You can only add items from a single vendor to your cart.",
          });
          return;
        }
      }

      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "addToCart",
        JSON.stringify(payload)
      );

      if (response.returnCode === 200) {
        dispatch(updateCartContent(response.returnData.cart_item));

        setCartItems((prevItems) => {
          const existingItemIndex = prevItems.findIndex(
            (i) => i.id === item.id
          );
          if (existingItemIndex >= 0) {
            const updatedItems = [...prevItems];
            updatedItems[existingItemIndex].quantity += 1;
            return updatedItems;
          } else {
            setSnackAlert({
              open: true,
              severity: "success",
              message: `${item.product_name} added to cart`,
            });
            return [...prevItems, { ...item, quantity: 1 }];
          }
        });

        setSnackAlert({
          open: true,
          severity: "success",
          message: `${item.product_name} added to cart`,
        });
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: response.returnMessage,
        });
      }
    } catch (error) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to add item to cart",
      });
    }
  };

  const updateQuantity = async (productId, quantity) => {
    debugger;
    try {
      const payload = {
        auth: getCookie("loggedInUser"),
        product_id: productId,
        quantity: quantity,
      };

      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "updateCartQuantity",
        JSON.stringify(payload)
      );

      if (response.returnCode === 200) {
        setCartItems((prevItems) =>
          prevItems.map((item) =>
            item.id === productId
              ? { ...item, quantity: Math.max(quantity, 1) }
              : item
          )
        );

        setSnackAlert({
          open: true,
          severity: "success",
          message: response.returnMessage,
        });
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: response.returnMessage,
        });
      }
    } catch (error) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to update quantity",
      });
    }
  };

  const removeFromCart = async (productId) => {
    try {
      const payload = {
        auth: getCookie("loggedInUser"),
        product_id: productId,
      };

      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "removeFromCart",
        JSON.stringify(payload)
      );

      if (response.returnCode === 200) {
        setCartItems((prevItems) =>
          prevItems.filter((item) => item.product_id !== productId)
        );

        setSnackAlert({
          open: true,
          severity: "success",
          message: response.returnMessage,
        });
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: response.returnMessage,
        });
      }
    } catch (error) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to remove item from cart",
      });
    }
  };

  const clearCart = async () => {
    try {
      const payload = {
        auth: getCookie("loggedInUser"),
      };

      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "clearCart",
        JSON.stringify(payload)
      );

      if (response.returnCode === 200) {
        setCartItems([]);
        setSnackAlert({
          open: true,
          severity: "success",
          message: response.returnMessage,
        });
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: response.returnMessage,
        });
      }
    } catch (error) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to clear cart",
      });
    }
  };

  const fetchCartItems = async () => {
    try {
      const payload = {
        auth: getCookie("loggedInUser"),
      };

      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "getCartItems",
        JSON.stringify(payload)
      );

      if (response.returnCode === 200) {
        setCartItems(response.returnData.items || []);
      } else {
        throw new Error(response.data.returnMessage);
      }
    } catch (error) {
      throw new Error("Error");
    }
  };
  // Wishlist Methods
  const addToWishlist = async (item) => {
    try {
      const payload = {
        auth: getCookie("loggedInUser"),
        product_id: item.id,
      };

      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "addToWishlist",
        JSON.stringify(payload)
      );

      if (response.returnCode === 200) {
        setWishlistItems((prevItems) => {
          const existingItem = prevItems.find((i) => i.id === item.id);
          if (!existingItem) {
            setSnackAlert({
              open: true,
              severity: "success",
              message: `${item.product_name} added to wishlist`,
            });
            return [...prevItems, item];
          }
          return prevItems;
        });
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: response.returnMessage,
        });
      }
    } catch (error) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to add item to wishlist",
      });
    }
  };

  const removeFromWishlist = async (productId) => {
    try {
      const payload = {
        auth: getCookie("loggedInUser"),
        product_id: productId,
      };

      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "removeFromWishlist",
        JSON.stringify(payload)
      );

      if (response.returnCode === 200) {
        setWishlistItems((prevItems) =>
          prevItems.filter((item) => item.id !== productId)
        );

        setSnackAlert({
          open: true,
          severity: "success",
          message: response.returnMessage,
        });
      } else {
        setSnackAlert({
          open: true,
          severity: "error",
          message: response.returnMessage,
        });
      }
    } catch (error) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to remove item from wishlist",
      });
    }
  };

  const fetchWishlistItems = async () => {
    try {
      const payload = {
        auth: getCookie("loggedInUser"),
      };

      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "getWishlistItems",
        JSON.stringify(payload)
      );

      if (response.returnCode === 200) {
        setWishlistItems(response.returnData || []);
      } else {
        throw new Error(response.returnMessage);
      }
    } catch (error) {
      console.error("Error fetching wishlist items:", error);
      // setSnackAlert({
      //   open: true,
      //   severity: "error",
      //   message: "Failed to fetch wishlist items",
      // });
    }
  };

  const isInWishlist = (productId) => {
    return wishlistItems.some((item) => item.id === productId);
  };

  const fetchAlerts = async () => {
    try {
      const res = await commonComponent.sendRequestToServer(
        "AlertManagement",
        "fetchAllAlertsOnSpecifiedUser",
        JSON.stringify({
          auth: getCookie("loggedInUser"),
        })
      );
      const { returnCode, returnData } = res;

      if (returnCode === 200) {
        setUnreadCount(
          returnData.filter((alert) => alert.status === "ACTIVE").length
        );
      } else if (returnCode === 202) {
        setUnreadCount(0);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  React.useEffect(() => {
    let sub = true;

    if (sub) {
      const handleSnackBarAutoClose = () => {
        if (snackAlert.open) {
          setTimeout(() => {
            // history.push("/enroll_school");
            setSnackAlert({ ...snackAlert, open: false });
          }, 5000);
        }
      };
      handleSnackBarAutoClose();
    }

    return () => {
      sub = false;
    };
  }, [snackAlert]);

  return (
    <AppContext.Provider
      value={{
        loading,
        setLoading,
        modal,
        setModal,
        snackAlert,
        setSnackAlert,
        setIsProgressVisible,
        setProgress,
        cartItems,
        addToCart,
        updateQuantity,
        removeFromCart,
        clearCart,
        wishlistItems,
        addToWishlist,
        removeFromWishlist,
        isInWishlist,
        sideMenusLoaded,
        setSideMenusLoaded,
        unreadCount,
        setUnreadCount,
      }}
    >
      {loading && (
        <div className="bg-[--translucent-color] absolute top-0 w-screen h-screen z-[5000] overflow-y-hidden">
          <div className={classes.root}>
            <LinearProgress className={"bg-[--primary-color]"} />
          </div>
        </div>
      )}

      {isProgressVisible && (
        <div className="bg-[--translucent-black] absolute top-0 bottom-0 w-screen h-screen flex items-center justify-center z-20">
          <div className="w-[60%] h-[20%] bg-white rounded-lg">
            <h3 className="text-black text-lg font-light text-center py-6">
              Generating...
            </h3>

            <div className="w-[80%] mx-auto">
              <LinearProgress variant="determinate" value={progress} />
            </div>
          </div>
        </div>
      )}

      {modal && (
        <div className="bg-[--translucent-color] absolute top-0 w-screen h-screen z-20 flex items-center justify-center">
          <div className="bg-white w-[40%] shadow-md rounded-md p-3">
            <div className="w-full flex items-end justify-end">
              <button
                className="text black text-red-400"
                onClick={() => setModal(false)}
              >
                Close
              </button>
            </div>
            <div className="flex items-center justify-center">
              <BadgeInfo size={110} color="orange" />
            </div>
            <p className="text-[--secondary_color] text-base font-light text-center my-4">
              Since your account hasn't been approved yet by the system
              administrator. Your products shall not be available to customers
              for purchase.{" "}
            </p>
          </div>
        </div>
      )}

      {children}

      <SnackBarAlert
        open={snackAlert.open}
        severity={snackAlert.severity}
        message={snackAlert.message}
        onClose={() => setSnackAlert({ ...snackAlert, open: false })}
      />
      <ConnectionAlert />
    </AppContext.Provider>
  );
};

export default ContextProvider;
