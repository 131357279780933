import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import { Theme, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import React from "react";

type Props = {
  onClose: () => void;
  open: boolean;
  message: string | boolean;
  severity: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const CustomAlert = (props: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Collapse in={props.open}>
        <Alert
          severity={
            props.severity === "error"
              ? "error"
              : props.severity === "warning"
              ? "warning"
              : props.severity === "info"
              ? "info"
              : "success"
          }
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={props.onClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {props.message}
        </Alert>
      </Collapse>
    </div>
  );
};

export default CustomAlert;
