import { useState, useEffect } from "react";
import "./../custom_css/css/uploader.css";
import { MdCloudUpload, MdDelete } from "react-icons/md";

function ImageUpload({ imageData, onFileSelect, inputId }) {
  const [image, setImage] = useState(imageData || null);
  const [fileName, setFileName] = useState(
    imageData ? "Selected file" : "No selected file"
  );

  useEffect(() => {
    if (imageData) {
      setImage(imageData);
      setFileName("Selected file");
    }
  }, [imageData]);

  const handleFileChange = async ({ target: { files } }) => {
    if (files && files[0]) {
      const file = files[0];
      setFileName(file.name);

      // Check file size (250KB = 256000 bytes)
      if (file.size > 256000) {
        alert("File size exceeds 250KB. Please select a smaller file.");
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64String = reader.result;
        setImage(base64String);
        if (onFileSelect) {
          onFileSelect(base64String);
        }
      };
    }
  };

  const handleDeleteImage = (e) => {
    e.stopPropagation(); // Prevents triggering the file picker
    setFileName("No file selected");
    setImage(null);
    if (onFileSelect) {
      onFileSelect(null);
    }
  };

  return (
    <div className="uploader-container">
      <main>
        <form onClick={() => document.getElementById(inputId).click()}>
          <input
            id={inputId}
            type="file"
            accept="image/*"
            className="input-field"
            hidden
            onChange={handleFileChange}
          />

          {image ? (
            <div className="image-preview-container flex items-center justify-center w-64 h-64 border border-gray-300">
              <img
                src={image}
                alt={fileName}
                className="max-w-full max-h-full object-contain"
              />
              <MdDelete
                className="delete-icon absolute top-2 right-2 text-red-500 cursor-pointer"
                onClick={handleDeleteImage}
              />
            </div>
          ) : (
            <>
              <MdCloudUpload color="hsl(38, 100%, 50%)" size={60} />
              <p>Browse Files to upload</p>
            </>
          )}
        </form>
      </main>
    </div>
  );
}

export default ImageUpload;
