import React, { useContext, useEffect, useState } from "react";
import CustomSchoolPayTable from "../../../components/CustomComponents/Tables/CustomSchoolPayTable.tsx";
import { AppContext } from "../../../context/AppContext";
import { CommonComponent } from "../../../service";
import TabbedHeaderComponent from "../../../components/CustomComponents/Tabs/TabbedHeaderComponent.tsx";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../service/get-user.js";

const req = new CommonComponent();
const auth = getCookie("loggedInUser");

const packageColumns = [
  { header: "Package Name", accessor: "package_name" },
  { header: "School Name", accessor: "school_name" },
  { header: "Class Name", accessor: "class_name" },
  { header: "School Code", accessor: "school_code" },
  { header: "School Type", accessor: "school_type" },
  { header: "Grand Price", accessor: "grandprice" },
  { header: "Mapping Status", accessor: "mapping_status" },
];

const schoolColumns = [
  { header: "Vendor Name", accessor: "vendor_name" },
  { header: "Email Address", accessor: "email" },
  { header: "School Name", accessor: "school_name" },
  { header: "School Code", accessor: "school_code" },
  { header: "Initiated By", accessor: "initiated_by" },
  { header: "Approval Status", accessor: "status" },
  { header: "Requested On", accessor: "requested_on" },
];

const SchoolColumns = [
  { header: "School Code", accessor: "school_code" as const },
  { header: "School Name", accessor: "school_name" as const },
  { header: "Package Price", accessor: "package_price" as const },
  { header: "Package Name", accessor: "package_name" as const },
  { header: "Package Start Date", accessor: "package_start_date" as const },
  { header: "Package End Date", accessor: "package_end_date" as const },
  { header: "Initiated By", accessor: "initiated_by" as const },
];

const VendorMappingRequests = () => {
  const [packageData, setPackageData] = React.useState([]);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [schoolData, setSchoolData] = React.useState([]);
  const [SchoolPackageData, setSchoolPackageData] = useState<any[]>([]);

  const { setLoading, setSnackAlert }: any = useContext(AppContext);
  const navigation = useNavigate();

  useEffect(() => {
    let sub = true;
    if (sub) {
      fetchClassesToPackageMapping();
      fetchVendorsToBeApproved();
      fetchSchooPackageMapping();
    }

    return () => {
      sub = false;
    };
  }, []);

  const fetchClassesToPackageMapping = async () => {
    let postObject = {
      auth,
      search: {
        status: "Awaiting Review",
        vendor: "working on it",
      },
    };
    setLoading(true);

    try {
      const res = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "getmapPackageToSchoolClasses",
        JSON.stringify(postObject)
      );

      if (res.returnCode === 200) {
        setLoading(false);
        setPackageData(res.returnData);
        return;
      } else {
        setLoading(false);
        setSnackAlert({
          open: true,
          severity: "error",
          message: res.returnMessage,
        });
        return;
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to fetch schools to be approved",
      });
      return;
    }
  };

  const fetchVendorsToBeApproved = async () => {
    setLoading(true);

    try {
      const res = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "getAllVendorMappingRequests",
        JSON.stringify({
          auth,
          search: {
            status: "Not Approved",
            initiator: "school",
            isLoggined: "working",
          },
        })
      );

      if (res.returnCode === 200) {
        setLoading(false);
        setSchoolData(res.returnData.rows);
        return;
      } else {
        setLoading(false);
        setSnackAlert({
          open: true,
          severity: "error",
          message: "Failed to fetch vendors to be approved",
        });
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to fetch vendors to be approved",
      });
    }
  };

  const fetchSchooPackageMapping = async () => {
    let postObject = {
      auth,
      search: {
        // status: "Not Approved",
        initiator: "school",
        // vendor: "vendor",
      },
    };
    setLoading(true);

    try {
      const res = await req.sendRequestToServer(
        "SchoolpayShoppingHelper",
        "getPackageSchoolMapping",
        JSON.stringify(postObject)
      );

      if (res.returnCode === 200) {
        setLoading(false);
        setSchoolPackageData(res.returnData);
        return;
      } else {
        setLoading(false);
        setSnackAlert({
          open: true,
          severity: "error",
          message: res.returnMessage,
        });
        return;
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Failed to fetch schools to be approved",
      });
      return;
    }
  };

  const renderComponent = () => {
    if (activeIndex === 0) {
      return (
        <CustomSchoolPayTable
          selectable={false}
          columns={packageColumns}
          data={packageData}
          filters={[]}
          actionComponent={(item) => {
            return (
              <div className="flex space-x-2 justify-evenly py-2 z-10">
                {item.mapping_status === "Awaiting Review" ? (
                  <button
                    onClick={
                      () => navigation("/school-Packageinfor/" + item.id)

                      // navigation("/schoolpay-Packageinfor/" + item.id)
                    }
                    className="text-green-500 font-extrabold bg-green-100 px-2 py-1 rounded-lg"
                  >
                    Approve School
                  </button>
                ) : (
                  <button
                    onClick={() => alert("pending")}
                    className="text-red-500 font-semibold"
                  >
                    Decline
                  </button>
                )}
              </div>
            );
          }}
        />
      );
    } else if (activeIndex === 1) {
      return (
        <CustomSchoolPayTable
          selectable={false}
          columns={schoolColumns}
          data={schoolData}
          filters={[]}
          actionComponent={(item) => {
            return (
              <div className="flex space-x-2 justify-evenly py-2 z-10">
                {item.status === "Not Approved" ? (
                  <button
                    onClick={
                      () => navigation("/school-Packageinfor/" + item.id)

                      // navigation("/schoolpay-Packageinfor/" + item.id)
                    }
                    className="text-green-500 font-extrabold bg-green-100 px-2 py-1 rounded-lg"
                  >
                    Approve School
                  </button>
                ) : (
                  <button
                    onClick={() => alert("pending")}
                    className="text-red-500 font-semibold"
                  >
                    Decline
                  </button>
                )}
              </div>
            );
          }}
        />
      );
    } else if (activeIndex === 2) {
      return (
        <CustomSchoolPayTable
          selectable={false}
          columns={SchoolColumns}
          data={SchoolPackageData}
          filters={[]}
          actionComponent={(item) => {
            return (
              <div className="flex space-x-2 justify-evenly py-2 z-10">
                {item.mapping_status === "Awaiting Review" || "active" ? (
                  <button
                    onClick={
                      () => console.log(item, "School Package")

                      // navigation("/schoolpay-Packageinfor/" + item.id)
                    }
                    className="text-green-500 font-extrabold bg-green-100 px-2 py-1 rounded-lg"
                  >
                    Approve School
                  </button>
                ) : (
                  <button
                    onClick={() => alert("pending")}
                    className="text-red-500 font-semibold"
                  >
                    Decline
                  </button>
                )}
              </div>
            );
          }}
        />
      );
    } else {
      return (
        <>
          <div className="flex justify-center items-center">Not Yet found</div>
        </>
      );
    }
  };
  return (
    <div className="p-4">
      <div className="py-3 ">
        <h3 className="lg:text-3xl md:text-2xl sm:text-lg font-light transition ease duration-500">
          Mapping Requests
        </h3>
      </div>

      <div className="my-3">
        <TabbedHeaderComponent
          headers={[
            "Class Requests to Package",
            "School Request to Vendor",
            "School Request to Package",
          ]}
          activeIndex={activeIndex}
          onHeaderClick={(index) => () => setActiveIndex(index)}
        />
      </div>
      <div className="py-4">{renderComponent()}</div>
    </div>
  );
};

export default VendorMappingRequests;
