import { Trash2 } from "lucide-react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormDialog from "../../components/CustomComponents/Dialogs/FormDialog.tsx";
import CustomBreadCrumb from "../../components/CustomComponents/Tables/CustomBreadCrumb.tsx";
import CustomTable from "../../components/CustomComponents/Tables/CustomTable.tsx";
import { AppContext } from "../../context/AppContext.js";
import { routings } from "../../routes/routings.ts";
import { CommonComponent } from "../../service";
import { getCookie, userDashboard } from "../../service/get-user";

interface MappedProduct {
  id: string;
  product_name: string;
  price_per_unit: number;
  qty_available: number;
}

const columns = [
  { header: "Product Name", accessor: "product_name" },
  { header: "Quantity", accessor: "quantity" },
  { header: "Price Per Unit", accessor: "price_per_unit" },
  { header: "Total Cost", accessor: "totalcost" },
];

const PackageDetails: React.FC = () => {
  const navigation = useNavigate();
  const [mappedProducts, setMappedProducts] = useState<MappedProduct[]>([]);
  const auth = getCookie("loggedInUser");
  const commonComponent = useMemo(() => new CommonComponent(), []);
  const { packageId } = useParams();
  const [loading, setloading] = useState(false);
  const [selectedPackage, setSelectedpackage] = useState<any>();
  const [itemsTotal, setitemsTotal] = useState<any>();
  const [open, setOpen] = useState(false);
  const [mappingData, setMappingData] = useState();

  const req = new CommonComponent();
  const { setSnackAlert } = useContext(AppContext);

  useEffect(() => {
    getMappedProducts();
    getPackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMappedProducts = async () => {
    setloading(true);
    const postObject = {
      auth,
      requestData: {
        packageId: packageId,
      },
    };

    try {
      const response = await commonComponent.sendRequestToServer(
        "ProductManagement",
        "getPackageProductsMappingwithoutsession",
        JSON.stringify(postObject)
      );
      const { returnCode, returnData } = response;
      if (returnCode === 200) {
        const totalcost = returnData.rows.reduce((total, row) => {
          return total + (row.totalcost || 0); // Accumulate total cost, ensure totalcost is valid
        }, 0);
        setitemsTotal(totalcost);

        setMappedProducts(response.returnData.rows); // Display the returned data
        setloading(false); // Close the loading spinner when data is retrieved
        if (response.returnData.rows.length === 0) {
          console.log("No data was retrieved");

          setloading(false); //
        }
      } else {
        setloading(false); // Close the loading spinner when an error occurs
        console.error(
          "Unexpected return code",
          response.returnCode,
          "message",
          response.message
        );
      }
    } catch (error) {
      setloading(false); // Close the loading spinner when an error occurs
      console.error("Error: ", error.message);
    }
  };

  const getPackages = () => {
    const postObject = {
      auth,
      search: {},
      requestData: { packageId: packageId },
    };

    try {
      req
        .sendRequestToServer(
          "ProductManagement",
          "getAllSchoolPayPackageWithoutSession",
          JSON.stringify(postObject)
        )
        .then((data) => {
          let { returnCode } = data;
          if (returnCode === 200) {
            if (data.returnData && data.returnData.rows) {
              setSelectedpackage(data.returnData.rows[0]);
            } else {
              console.error("Invalid data structure", data);
            }
          } else {
            console.log(
              "Unexpected return code",
              data.returnCode,
              data.returnMessage
            );
          }
        })
        .catch((error) => {
          console.error(
            "Error occurred while retrieving packages:",
            error.message
          );
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleRemoveProduct = async () => {
    try {
      const { product_id, package_id }: any = mappingData;

      const response = await req.sendRequestToServer(
        "ProductManagement",
        "removeProductFromSpecifiedMapping",
        JSON.stringify({
          auth: getCookie("loggedInUser"),
          requestData: {
            packageId: package_id,
            productId: product_id,
          },
        })
      );
      const { returnCode, returnMessage } = response;
      console.log(response);

      if (returnCode === 0) {
        setOpen(false);
        setSnackAlert({
          open: true,
          severity: "success",
          message: returnMessage,
        });

        setTimeout(() => {
          getMappedProducts();
        }, 500);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleMapProduct = () => {
    navigation(routings.product_mapping + packageId);
  };

  const breadcrumbs = [
    { label: "Dashboard", route: () => navigation(userDashboard) },
    { label: "Package", route: () => navigation(routings.packages) },
    { label: "package information", route: () => navigation("/details") }, // Route ignored sin
  ];

  return (
    <>
      <div className="mt-16">
        <CustomBreadCrumb breadcrumbs={breadcrumbs} />
        <div className=" rounded-md">
          <div className="flex justify-between rounded-sm px-6 pt-3 mb-3">
            <div className="rounded-sm flex justify-center items-center border-b">
              <label className="text-gray-600 pr-1 text-sm capitalize">
                Package Name:{" "}
              </label>
              <label className=" text-[--primary-color] font-extrabold capitalize">
                {selectedPackage?.package_name && selectedPackage?.package_name}
              </label>
            </div>
            <div className="flex float-right mr-1 border-b ">
              {selectedPackage?.package_price && (
                <div className=" flex justify-between items-center rounded-sm px-2 pt-1">
                  <label className="text-gray-600 capitalize pr-1 text-xs">
                    Package Price:{" "}
                  </label>
                  <label className="text-[--secondary_color] font-bold px-1">
                    {selectedPackage?.package_price}
                  </label>
                </div>
              )}
              {selectedPackage?.package_price && (
                <div className=" flex justify-between items-center rounded-sm px-2 pt-1">
                  <label
                    htmlFor=""
                    className="text-gray-600 capitalize text-xs pr-1"
                  >
                    Grand Price:{" "}
                  </label>
                  <label className="text-[--secondary_color] font-bold px-1">
                    {selectedPackage?.grand_package_price}
                  </label>
                </div>
              )}
              {itemsTotal && (
                <div className=" flex justify-between items-center rounded-sm px-2 pt-1">
                  <label
                    htmlFor=""
                    className="text-gray-600 capitalize text-xs pr-1"
                  >
                    Items Price:{" "}
                  </label>
                  <label className="text-[--secondary_color] font-bold px-1">
                    {itemsTotal}
                  </label>
                </div>
              )}
            </div>
          </div>
          <div className="">
            <CustomTable
              columns={columns}
              data={mappedProducts}
              header={false}
              loading={loading}
              createAction={{
                label: "Map other products",
                onclick: handleMapProduct,
              }}
              actionComponent={(rowData) => (
                <Trash2
                  size={18}
                  color="tomato"
                  onClick={() => {
                    setMappingData(rowData);
                    setOpen(true);
                  }}
                  style={{ cursor: "pointer" }}
                />
              )}
            />
          </div>
        </div>
      </div>

      <FormDialog
        open={open}
        type="confirm"
        title="Remove Product?"
        content={
          <p className="text-black px-2">
            This can't be undone. Proceed with caution.
          </p>
        }
        onClose={() => setOpen(false)}
        onConfirm={() => handleRemoveProduct()}
      />
    </>
  );
};

export default PackageDetails;
