import React from "react";
import { Pie } from "react-chartjs-2";

type Props = {
  title: string;
  chartData: any;
};

const PieChart = ({ title, chartData }: Props) => {
  return (
    <div>
      <Pie
        data={chartData}
        options={{
          animation: {
            easing: "linear",
          },
          plugins: {
            title: {
              display: true,
              text: title,
            },
          },
        }}
      />
    </div>
  );
};

export default PieChart;
